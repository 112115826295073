
.routes-container-sub{
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  gap: 5px;
  overflow-y: scroll;
}
.route-stop{
  display: flex;
  flex-direction: row;
  padding: 15px 10px 10px 0px;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  margin-bottom: 5px;
}
.unassigned-stop{
  border-radius: 10px;
  border: 1px solid var(--Blue, #ed322f);
  background: rgba(237, 47, 47, 0.08);
}
.route-stop-content{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 90%;
}
.route-stop-collapse-button{
  align-self: center;
  margin-left: auto;
  cursor: pointer;
  margin-right: 5px;
  color: #276FCF;
}
.route-stop-drag-bar{
  align-self: center;
  margin-left: auto;
  cursor: pointer;
}
.route-stop-heading{
  color: #828282;
  margin-bottom: 5px;
}
.route-stop-probills-list{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.route-stop-probills-item{
  display: flex;
  flex-direction: row;
  background-color: #F2F2F2;
  padding: 5px;
  border-radius: 10px;
  gap: 5px;
  align-items: center;
} 
.route-stop-probills-item-consignee-name{
  font-size: small;
}
.route-stop-probills-item-remove{
  color: #828282;
  margin-right: 10px;
  align-self: center;
  margin-left: auto;
  cursor: pointer;
}
.route-stop-probills-item-add{
  align-self: center;
  align-content: center;
  margin: auto;
}
.route-stop-detail-box{
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.route-stop-est-arrival{
color: #14870A;
}
.route-stop-detail-times{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.route-stop-detail-heading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.route-stop-appointment{
  color: #276FCF;
}
.route-stop-appointment-details-tool{
  color: #828282;   
}
.route-stop-sequence-number{
  color: #276FCF;
}
.route-stop-probills-item-add-icon{
  margin-left: 2px;
  cursor: pointer;
}
.add-probill-to-stop{
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 5px;
  align-items: center;
  color: #276FCF;
  background-color: white;
  border: 1px solid #276FCF;
  border-radius: 10px;  
  cursor: pointer;

}
.route-stop-probills-item-probill-number{
  color: #276FCF;
}