@import "./../../../sass/variables.module.scss";

.form-address-selector {
  .xgs-form__address-block {
    padding: 16px;
    margin: 0;
    border: 1px solid $gray_24;
  }

  &__row {
    margin-bottom: 0;
    display: flex;
    gap: 16px;
    width: 100%;

    .xgs-labeled-input {
      margin-bottom: 0;
    }

    .xgs-labeled-input__validation-error--custom {
      margin-top: 2px;
    }
  }

  &__field {
    &--state {
      flex-grow: 1;
    }

    &--zip {
      width: 126px;
    }
  }

  &__info {
    margin-top: -8px;
    padding: 8px;
    border-radius: 4px;
    background-color: rgba(79, 95, 114, 0.08);
    color: rgba(79, 95, 114, 0.72);
    font-size: 12px;
    display: flex;
    gap: 8px;
  }

  &__info-icon {
    width: 20px;
    display: flex;
    align-items: center;
  }

  .xgs-gray-area__content {
    margin-bottom: 16px;
  }
  
  .xgs-textarea textarea {
    min-height: 56px;
  }
}