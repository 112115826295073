@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-request-rates__shipping-item-form {
  box-sizing: border-box;
  max-width: 418px;
  width: 100%;
  border: 1px solid $gray4;
  padding: 16px 29px 16px 16px;
  margin-top: 24px;

  &__freight-notes {
    font-size: 12px;
    margin: -24px 0 16px 8px;
    color: $gray3;
  }

  &__button {
    & + .xgs-request-rates__shipping-item-form__button {
      margin-left: $margin7;

      @include up-to(540px) {
        margin-left: 0;
        margin-top: $margin7;
      }
    }

    @include up-to(540px) {
      width: 100%;
    }
  }

  &__row {
    display: flex;

    &--spaced {
      justify-content: space-between;
    }

    .xgs-labeled-input {
      min-height: 88px;
    }

    @include up-to(460px) {
      flex-wrap: wrap;
    }
  }

  &__field {
    width: 112px;

    &--full-width {
      width: 100%;
    }

    @include up-to(460px) {
      width: 100%;
    }
  }

  &__checkbox {
    margin-bottom: 30px;    
  }
}
