@import "../../sass/variables.module.scss";
@import "../../sass/mixins.scss";

.xgs-customer-inventory {
  div[role="row"] {
    display: flex;
    align-items: center;
  }
  @include responsiveTable($table-breakpoint);
}
