@import "../../../../sass/variables.module.scss";
@import "../../../../sass/mixins.scss";

.xgs-unplannable-probills-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    @include mobile() {
        flex-direction: column;
        gap: 10px;
    }

    &__tile {
        display: flex;
        flex-direction: row;
        width: 19%;
        justify-content: space-between;
        align-items: flex-start;
        background: #4F5F720A;
        padding: 8px 12px 8px 12px;
        border-radius: 4px;
        gap: 10px;

        @include mobile() {
            width: 100%;
        }

        .icon-container {
            width: 15%;
            height: 34px;
            width: 34px;
            border-radius: 4px;
            align-content: center;
            text-align: center;

        }

        .--type-total {
            background-color: #9747FF29;
        }

        .--type-atRisk {
            background-color: #E8D15A29;
        }

        .--type-catchUp {
            background-color: #E16C0029;
        }

        .--type-late {
            background-color: #DD3F3F29;
        }

        .--type-missed {
            background-color: #DD3F3F29;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 2px;
            width: 83%;

            .values {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 5px;

                .info {
                    font-size: 18px;
                    font-weight: 600;
                    text-align: left;
                }
            }

            .title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 5px;
                font-size: 12px;
                color: #4F5F72B8;

                .apply-btn {
                    text-decoration: none;
                    color: $blue1;

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }

    }
}