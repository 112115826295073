@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-shipments-status {
  &__link {
    cursor: pointer;

    &:hover {
      background-color: $gray5;
    }
  }

  &__no-data {
    height: 110px;
    text-align: center;
    font-size: 14px;
    padding: 24px 16px;
    color: $gray3;
    line-height: 1.2;;
  }

  &--red {
    color: $red;
  }

  &__flex {
    display: flex;
    align-items: center;
  }

  &__header {
    font-size: 18px;
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: center;
  }

  &__total {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
  }

  &__bar-chart {
    width: 100%;
    text-align: center;

    &__labels {
      display: flex;
      justify-content: space-between;
      padding-left: 26px;
      width: 100%;

      @include up-to(400px) {
        padding-left: 26px;
      }

      &__value {
        flex-grow: 0;
        font-size: 14px;
        width: 50%;
        text-align: center;
        padding: 4px 0;

        @include up-to(430px) {
          font-size: 12px;
        }
      }
    }

    &__delivered {
      display: inline-block;
      margin-top: 16px;
      padding: 4px 16px;
      font-size: 14px;

      strong {
        color: white;
        background-color: #424D73; //$gray5;
        padding: 4px 6px;
        border-radius: $border-radius;
      }
    }
  }


  &__chart {
    position: relative;
    width: 136px;

    @include up-to(436px) {
      width: 110px;
    }

    @include up-to(380px) {
      width: 100px;
    }

    &__total {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% - 19px);
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      color: $gray2;
    }
  }

  &__legend {
    margin-left: 16px;

    @include up-to(500px) {
      margin-left: 24px;
    }

    @include up-to(420px) {
      margin-left: 12px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      padding: 5px 6px;

      &:not(:last-child) {
        border-bottom: 1px solid $gray5;
      }
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include up-to(420px) {
        font-size: 14px;
      }

      @include up-to(370px) {
        font-size: 12px;
      }

      &__circle {
        flex-shrink: 0;
        border-radius: 8px;
        width: 8px;
        height: 8px;
        margin-right: 8px;
      }
    }

    &__value {
      margin-left: 16px;
      color: $gray3;

      @include up-to(420px) {
        font-size: 14px;
      }

      @include up-to(370px) {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }

  &__groups {
    display: flex;
    justify-content: center;
  }

  &__group {
    border: 2px solid $gray5;
    border-radius: $border-radius;
    padding: 4px 10px;
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0 2px;
    width: 25%;

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      text-align: center;
      margin-bottom: 4px;
      text-transform: uppercase;
      height: 24px;
      color: $gray1 !important;
    }

    &__value {
      font-size: 18px;
      text-align: center;
      color: $gray1 !important;
    }
  }

  &__notes-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $gray3;
    cursor: pointer;

    &:hover {
      color: $blue1;
    }
  }

  &__modal-content {
    line-height: 1.5;
  }

  canvas#eta-chart {
    position: relative;
    z-index: 1;
  }
}