@import "./../../sass/variables.module.scss";

.feedback {  
  &__button {
    background: $blue1;
    color: white;
    padding: 4px 8px;
    border-radius: 0px 0px 4px 4px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background: $blue4;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    textarea {
      resize: none;
    }
  }

  &__submit-buttons {
    display: flex;
    gap: 12px;

    .xgs-btn {
      flex: 1 1 auto;
    }
  }
  
  &__success {
    text-align: center;

    .feedback__title {
      margin-bottom: 16px;
    }

    .xgs-btn {
      width: 100%;
    }
  }

}

.popup-content.xgs-feedback-modal-content {
  animation: slide-down 0.5s forwards;

  @media only screen and (min-width: 431px) {
    margin: 94px 24px auto auto !important;
  }

  .xgs-popup__content {
    width: 360px;
  }
}