@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-stage-banner {
  margin: 16px 0;
  padding: 14px 12px 12px 12px;
  border-radius: $border-radius;
  border: 1px solid rgba(79, 95, 114, 0.32);
  background: $red;
  color: #C43232;

  &__icon {
    margin-bottom: 8px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__header {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
    color: white;
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: white;
  }
}