@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-customers-users {
  .xgs-company__users__list {
    &__pending-email {
      color: $gray3;
    }
  }

  .xgs-list__controls__search {
    margin-right: 40px;
    display: flex;
    justify-content: flex-start;
    grid-gap: 15px;
    gap: 15px;
    width: 100%;
    height: 100%;

    .xgs-labeled-input {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: 768px) {
        min-width: 170px;
        max-width: 244px;
      }
    }
  }

  .xgs-list__table-wrapper {
    @include responsiveTable($table-breakpoint);
  }
}
