@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-emergency-alert {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  @include up-to(1278px) {
    flex-wrap: wrap;
  }

  & > div {
    max-width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__form {
    position: relative;
    border-radius: 4px;
    border: 1px solid $gray5;
    padding: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    min-width: 440px;
    max-width: 40vw;

    @include up-to(1278px) {
      width: 100%;
      max-width: none;
      min-width: 0;
    }
  }

  &__state {
    min-width: 380px;
    max-width: 40vw;
    display: flex;
    flex-direction: column;

    @include up-to(1278px) {
      min-width: 0;
      max-width: none;
      width: 100%;
    }
  }

  &__control {
    border-radius: $border-radius;
    border: 1px solid rgba(79, 95, 114, 0.32);
    margin-bottom: 24px;
    padding: 16px;

    &__text {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__buttons {
      display: flex;
      gap: 16px;

      @include up-to(1150px) {
        flex-wrap: wrap;
      }
    }
  }

  &__preview {
    position: relative;
    background-color: #1B2D5A;
    border-radius: $border-radius;
    border: 1px solid #1B2D5A;
    flex-grow: 1;


    &__area {
      max-height: 540px;
      overflow-y: auto;
      padding: 24px;
      word-wrap: anywhere;

      @include up-to(800px) {
        margin-top: 32px;
      }

      @include up-to(540px) {
        min-width: 0;
        max-width: 100%;
        max-height: 540px;
      }
    }

    &__header {
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
      color: #F4B11A;

      @include up-to(540px) {
        width: 100%;
        min-width: 0;
      }
    }

    &__body {
      width: 100%;
      font-size: 16px;
      margin: 24px 0;
      color: white;
      line-height: 1.7em;

      @include up-to(540px) {
        width: auto;
        min-width: 0;
      }

      a {
        color: #2F80ED;
        text-decoration: none;
      }

      a {
        font-weight: 700;
        color: #CB1F31 !important;
      }
    }
  }

  &__block {
    position: relative;
    border-radius: 4px;
    border: 1px solid $gray5;
    padding: 24px;

    @include up-to(800px) {
      width: 100%;
    }

    &__status {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  &__value-pair {
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 16px 0;

    &__label {
      color: $gray3;
    }

    &__value {
      color: black;
    }
  }

  &__field {
    margin-top: 0 !important;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__error {
    max-width: 100%;
    margin-top: 24px;

    @include up-to(600px) {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &--main {
      margin-top: 24px;
    }
  }

  &__button {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    &--save {
      width: 120px;
    }

    &--unpublish {
      width: 143px;
      flex-grow: 0;
    }

    &--publish {
      min-width: 100px;
    }

    &--push-to-announcements {
      min-width: 267px;
      flex-grow: 0;

      @include up-to(360px) {
        min-width: 0;
      }
    }

    @include up-to(600px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__announcement-groups {
    margin: 16px 0;

    &__checkboxes {
      margin: 8px 0 16px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  .xgs-visual-editor {
    height: auto;

    .ql-editor {
      min-height: 480px;
      //height: 370px;
    }
  }

  .ql-size-small {
    font-size: 12px;
  }

  .ql-size-large {
    font-size: 24px;
  }

  .ql-tooltip.ql-editing {
    z-index: 1;
  }
}
