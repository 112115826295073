@import "./../../sass/variables.module.scss";

.xgs-banner {
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px 40px;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;

  &__action-button {
    padding: 6px 24px;
    text-decoration: none;
    flex: 0 0 auto;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 32px;
  }

  &--color {
    &_purple {
      color: $purple;
      border-color: $purple;
      background-color: $purple_8;

      .xgs-banner__action-button {
        background-color: $purple;
        color: white;

        &:hover {
          background-color: $dark_purple;
        }
      }
    }
  }
}