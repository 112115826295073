@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-company {
  margin-bottom: 36px;

  &__form {
    width: 230px;

    @include up-to($form-full-width) {
      width: 100%;
    }

    .xgs-btn {
      width: auto;
      padding: 6px 24px;
      margin-bottom: 32px;

      @include up-to($form-full-width) {
        width: 100%;
      }
    }
  }

  .react-tabs__tab-list {
    display: flex;
    @include up-to(720px) {
      width: 100%;
      flex-direction: column;
    }
  }

  .react-tabs__tab {
    @include up-to(720px) {
      width: 100%;
      border: 1px solid #2F80ED !important;
      border-radius: 0;

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
      }
    }
  }

  &__link {
    cursor: pointer;
    color: $blue1;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__small-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    flex-shrink: 0;
  }  
}