.rating {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;

  &__options {
    display: flex;
    flex-direction: row-reverse;

    input {
      display: none;
    }    
  }

  &__label {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    color: transparent;
    cursor: pointer;

    &--active,
    &--active~label  {
      color: #B89311;
    }

    &:not(.rating__label--active):hover,
    &:not(.rating__label--active):hover~label {
      color: #B89311;
    }
  }   
}