@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-exclamation {
  max-width: 100%;
  padding: $margin6;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include up-to(500px) {
    width: calc(100% - 16px);
    margin-left: auto;
    margin-right: auto;
  }

  @include up-to(400px) {
    flex-wrap: wrap;
  }

  @include up-to(400px) {
    flex-wrap: wrap;
  }

  &.success {
    color: $green1;
    border: 1px solid $green1;
  }

  &.error {
    color: $red;
    border: 1px solid $red;
  }

  &.warning {
    color: $orange;
    border: 1px solid $orange;
  }

  &.info {
    color: $green1;
    border: 1px solid $gray5;
  }

  &__icon-container {
    width: 64px;
    text-align: center;
    margin-right: $margin6;
    flex-shrink: 0;

    @include up-to(400px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  &__icon {
    font-size: 48px;

    @include up-to(400px) {
      font-size: 64px;
    }
  }

  &__message {
    color: $gray1;
    flex-shrink: 0;
    max-width: calc(100% - 88px);
    line-height: 1.4;

    @include up-to(400px) {
      max-width: none;
      width: 100%;
    }
  }
}