@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

$blue: $blue1;
$red: #C43232;
$orange: #E16C00;
$yellow: #B89311;
$green: #14870A;

.xgs-progress-bar {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 16px;
  }

  &__text {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    color: $gray_72;
  }

  &__line {
    width: 100%;
    height: 4px;
    margin-top: 4px;
    border-radius: $border-radius;
    background-color: $gray_8;

    .xgs-progress-bar__line__filler {
      height: 4px;
      border-radius: 4px;

      &--1st-3rd {
        background-color: $red;
      }

      &--2nd-3rd {
        background-color: $orange;
      }

      &--3rd-3rd {
        background-color: $yellow;
      }

      &--not-full {
        background-color: $blue;
      }

      &--full {
        background-color: $green;
      }
    }
  }
}
