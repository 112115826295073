@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

$mobile-breakpoint: 450px;

.xgs-feature-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  background-color: $blue1;
  border-radius: $border-radius;
  padding: 16px;
  max-width: 786px;
  width: 100%;

  &--employee {
    max-width: 320px;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    border: 1px solid rgba(79, 95, 114, 0.32);
    padding: 14px 12px 12px 12px;
    margin-bottom: 0;

    @media only screen and (max-height: 780px) and (min-width: 769px) {
      display: none;
    }

    @include mobile {
      margin: -8px auto 24px auto;
    }

    .xgs-feature-banner__info-icon {
      margin-bottom: 18px;
    }

    .xgs-feature-banner__text {
      color: black;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      width: 100%;
      margin: 0 0 16px 0;

      @media only screen and (max-height: 840px) {
        max-height: 120px;
        overflow-y: auto;
      }
    }

    .xgs-feature-banner__close {
      position: absolute;
      top: 6px;
      right: 8px;
    }

    .xgs-feature-banner__button {
      font-size: 14px;
      padding: 4px 8px;

      @include up-to(769px) {
        width: 100%;
      }
    }
  }

  @include up-to($mobile-breakpoint) {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__text {
    color: white;
    margin-right: 24px;
    width: calc(100% - 180px);
    flex-shrink: 0;
    word-wrap: break-word;

    @include up-to($mobile-breakpoint) {
      margin: 0 0 16px 0;
      width: calc(100% - 28px);
    }
  }

  &__button {
    min-height: 24px;
    height: auto;
    flex-shrink: 0;
    width: 120px;
    word-break: break-word;

    @include up-to($mobile-breakpoint) {
      width: 100%;
      order: 2;
    }
  }

  &__close {
    color: white;
    font-size: 18px;
    margin-left: 24px;
    cursor: pointer;

    @include up-to($mobile-breakpoint) {
      order: 1;
      margin-left: 8px;
    }
  }

  &__management {
    max-width: 440px;
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgba(79, 95, 114, 0.32);
    margin-bottom: 24px;;

    &__message {
      height: 72px;
    }

    .xgs-visual-editor .ql-editor {
      height: 320px;
      resize: vertical;
    }

    &__buttons {
      display: flex;
      gap: 16px;
      margin-top: 16px;

      @include up-to(600px) {
        flex-wrap: wrap;
      }

      .xgs-btn {
        width: 50%;

        @include up-to(600px) {
          width: 100%;
        }
      }
    }
  }
}

.xgs-textarea textarea.xgs-feature-banner__management__message {
  min-height: 72px !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 12px !important;
}

.ql-editor {
  p, h1, h2, li {
    color: #4F4F4F !important;
  }

  p:not(:last-child) {
    margin-bottom: 16px !important;
  }

  h1, h2 {
    font-family: "Roboto-Medium";
    margin-bottom: 16px !important;
  }

  h1 {
    font-size: 24px !important;
    line-height: 32px;
  }

  h2 {
    font-size: 18px !important;
    line-height: 24px;
  }

  img {
    max-width: 100%;
  }

  ul, ol {
    padding-left: 0 !important;
    margin-bottom: 16px !important;

    li {
      padding-left: 24px !important;
    }

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
