@import "../../../../sass/variables.module.scss";
@import "../../../../sass/mixins.scss";

.xgs-picklists-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    @include mobile() {
        flex-direction: column;
        gap: 20px;
    }

    &__tile {
        display: flex;
        flex-direction: row;
        width: 24%;
        justify-content: space-between;
        align-items: flex-start;
        background: #4F5F720A;
        padding: 8px 12px 8px 12px;
        border-radius: 4px;
        gap: 10px;

        @include mobile() {
            width: 100%;
        }

        .icon-container {
            width: 15%;
            height: 34px;
            width: 34px;
            border-radius: 4px;
            align-content: center;
            text-align: center;

        }

        .--type-totalItems {
            background-color: #2F80ED29;
        }

        .--type-totalWeight {
            background-color: #9747FF29;
        }

        .--type-lateLoadings {
            background-color: #DD3F3F29;
        }

        .--type-missingItems {
            background-color: #DD3F3F29;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 2px;
            width: 83%;

            .value {
                font-size: 18px;
                font-weight: 600;
                text-align: left;
            }

            .title {
                font-size: 12px;
                color: #4F5F72B8;
            }
        }

    }
}