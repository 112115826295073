@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-summary-block {
  position: relative;
  display: flex;
  width: 380px;
  max-width: 100vw;
  min-height: 140px;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  border-radius: $border-radius;
  background-color: $gray6;
  margin-bottom: 24px;
  margin-right: 24px;

  @include up-to(500px) {
    width: 100%;
    max-width: calc(100vw - 48px);
    margin-right: 0;
  }

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: $gray3;
  }

  &.success {
    color: $green1;
  }

  &.error {
    color: $red;
  }

  &.warning {
    color: $orange;
  }

  &.info {
    color: $green1;
  }

  &__icon-container {
    width: 64px;
    margin-top: 22px;
    text-align: center;
    margin-right: $margin6;
    flex-shrink: 0;
  }

  &__icon {
    font-size: 48px;
  }

  &__content {
    width: 100%;
    color: $gray1;
  }
}