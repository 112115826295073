@import "./../../../../sass/variables.module.scss";
@import "./../../../../sass/mixins.scss";

.xgs-tracking-filter__instructions {
  font-size: 14px;

  &__close {
    position: absolute;
    right: 8px;
  }

  &__header {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: bold;
    color: $gray1;
  }

  &__item {
    margin-bottom: 24px;

    &__label {
      font-weight: bold;
      margin-bottom: 4px;

      span {
        font-style: oblique;
        color: $gray2;
      }
    }

    &__description {
      margin-bottom: 4px;
      text-align: justify;
    }

    &__values {
      color: $gray2;

      &__item {
        margin-bottom: 2px;
      }
    }
  }

  &__button {
    margin: 24px 0;
    text-align: center;
  }
}