@import "./../../sass/variables.module.scss";

.xgs-inline-switch {
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    word-break: break-word;

    &__control {
      display: flex;
      margin-left: 8px;

      &__edit-icon {
        cursor: pointer;
        color: $gray3;
      }

      &__small-button {
        height: 34px;
        width: 34px;

        & + .xgs-inline-switch__content__control__small-button {
          margin-left: 8px;
        }
      }
    }
  }
}
