@import "./../../sass//mixins.scss";

.xgs-summary {
  width: 100%;
  overflow-x: scroll;

  &__content {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(6, 1fr);

    @include up-to(1512px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mobile() {
      display: flex;
    }
  }
}