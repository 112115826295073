@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-error-message {
  color: #fff;
  background-color: $red;
  font-size: 16px;
  line-height: 16px;
  padding: 16px;
  width: 100%;
  max-width: 540px;
  text-align: center;
  word-break: break-word;

  @include up-to(540px) {
  	max-width: 100vw;
  }
}
