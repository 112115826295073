@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-request-rates {
  display: flex;
  margin-bottom: $margin3;

  @include up-to(620px) {
    flex-wrap: wrap;

    .xgs-steps-milestones {
      margin-top: 24px;
    }
  }

  &__step {
    &__header {
      font-size: 18px;
      line-height: 21px;
    }

    &__subheader {
      font-size: 14px;
      line-height: 16px;
      color: $gray3;
      margin-top: 9px;
    }

    &__nav-button {
      margin-bottom: $margin7;
      margin-right: $margin7;

      @include up-to(540px) {
        margin-right: 0;
        width: 100%;
      }
    }
  }

  &__field-label {
    font-size: 14px;
    line-height: 24px;
  }

  &__location-field {
    width: 293px;
    max-width: 100%;

    @include up-to(540px) {
      width: 100%;
    }
  }

  &__pickup-location {
    &__date-field {
      width: 173px;

      @include up-to(540px) {
        width: 100%;
      }
    }

    &__type {
      max-width: 150px;
      margin-top: 50px;

      .xgs-request-rates__field-label {
        margin-bottom: 20px;
      }

      label {
        display: flex;
        align-items: center;
      }

      label + label {
        margin-top: 16px;
      }

      &__name {
        margin-top: 4px;
        margin-left: 11px;
      }
    }
  }

  &__services-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 26px;

    @include up-to(620px) {
      display: block;
    }

    &__row {
      width: 50%;
      max-width: 290px;
      margin-bottom: 40px;

      @include up-to(620px) {
        max-width: 100%;
        width: 100%;
      }
    }

    label {
      display: flex;
      align-items: flex-start;
    }

    input {
      margin-top: 4px;
    }

    &__name {
      font-size: 14px;
      line-height: 16px;
      max-width: 254px;
    }

    &__description {
      margin-top: 5px;
      font-size: 14px;
      line-height: 16px;
      color: $gray3;
      max-width: 254px;

      @include up-to(620px) {
        max-width: 100%;
      }
    }

    input:disabled ~ div &__name,
    input:disabled ~ div &__description {
      color: $gray4;
    }
  }

  &__shipping-items {
    $itemWidth: 418px;

    .xgs-request-rates__step__header {
      padding-bottom: 5px;
    }

    &__freight-classes-note {
      color: $red;
    }

    &__item {
      width: $itemWidth;
      padding: 14px 18px 14px 14px;
      margin-top: 24px;
      background-color: $gray6;
      font-size: 14px;
      line-height: 16px;

      @include up-to(540px) {
        width: 100%;
      }

      &__columns {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__description {
        max-width: 304px;
        margin-bottom: 7px;
      }

      &__property {
        color: $gray3;
        word-break: break-all;
        padding-right: 8px;

        & + .xgs-request-rates__shipping-items__item__property {
          margin-top: 7px;
        }
      }

      &__action {
        line-height: 20px;
      }
    }

    &__add {
      margin-top: 24px;
      font-size: 14px;
      line-height: 16px;
    }

    &__link {
      color: $blue1;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    &__notes {
      margin: 16px 0;
      font-size: 14px;
      line-height: 20px;
      color: $gray2;

      &--guided {
        max-width: $itemWidth;
      }
    }
  }

  &__result-preview {
    max-width: 100%;
    height: 100%;

    .xgs-request-rates__step__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    &__error {
      margin-bottom: 16px;

      &__message {
        max-width: 100%;
        width: 480px;
        line-height: 1.4;
        margin-bottom: 24px;

        &--gray {
          color: $gray2;
        }        
      }

      &__buttons {
        margin-top: 16px;
      }

      &__button {
        width: 220px;

        &--submit {
          width: 250px;
        }

        &:not(:last-child) {
          margin-right: 16px;

          @include up-to(540px) {
            margin-right: 0;
            margin-bottom: 8px;
          }
        }

        @include up-to(540px) {
          width: 100%;
        }        
      }
    }

    &__top-buttons {
      @include mobile {
        margin-right: 8px;
      }
    }

    &__details {
      display: flex;
      font-size: 14px;
      line-height: 16px;

      @include up-to(500px) {
        flex-wrap: wrap;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &__name {
        width: 134px;

        @include up-to(500px) {
          width: 100%;
          margin: 8px 0;
        }
      }

      &__phone {
        margin-top: 4px;
        color: $gray3;

        &__icon {
          margin-right: 4px;
        }
      }
    }

    &__summary {
      &__header {
        margin-top: 40px;
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 16px;
      }
    }

    &__explanations {
      font-size: 14px;
      line-height: 16px;
      color: $gray2;
      max-width: 629px;
      margin-top: 32px;
    }

    &__request-button {
      width: 220px;

      @include up-to(540px) {
        width: 100%;
      }
    }

    &__create-bol {
      width: 180px;
      
      @include up-to(540px) {
        width: 100%;
      }      
    }

    &__bottom-block {
      margin: 32px 0;
    }

    &__success-block {
      display: flex;
      align-items: center;
      box-sizing: content-box;
      width: 350px;
      max-width: calc(100% - 32px);
      padding: 16px;
      margin-bottom: 24px;
      background-color: $gray6;

      &__icon {
        color: $green1;
      }

      &__text {
        max-width: calc(100% - 48px);
        box-sizing: border-box;
        overflow-wrap: break-word;
        color: $gray2;
        line-height: 1.5;
        margin-left: 16px;
      }
    }

    &__notes {
      font-size: 14px;
      line-height: 20px;
      color: $gray2;

      &--in-details {
        margin-top: -16px;
        margin-bottom: 36px;
      }
    }
  }

  &__pickup-location,
  &__destination-location,
  &__services-options,
  &__shipping-items {
    width: 600px;

    @include up-to(620px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__destination-location__row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    & > div:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__quick {
    max-width: 580px;

    &__section-header {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 16px;
    }

    &__section {
      position: relative;
      margin: 0 -16px 24px -16px;
      padding: 16px;
      background-color: $gray6;

      &__help {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $gray3;
        cursor: pointer;

        &:hover {
          color: $blue1;
        }
      }      
    }

    &__row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & > div {
        width: 48%;

        @include up-to(540px) {
          width: 100%;
        }

        &:not(:last-child) {
          @include up-to(540px) {
            margin-bottom: 16px;
          }          
        }
      }
    }

    .xgs-request-rates__services-options {
      margin-top: 8px;
    }

    .xgs-request-rates__services-options__row {
      margin-bottom: 12px;
    }

    .xgs-request-rates__shipping-items__item {
      width: 100%;
      padding: 8px 18px 8px 14px;
      margin-top: 0;
      margin-bottom: 8px;
      background-color: $gray7;
      font-size: 14px;
      line-height: 16px;
    }

    .xgs-request-rates__shipping-item-form {
      margin-top: 0;
      background-color: $gray7;
    }

    &__modal-help {
      &__description {
        margin-bottom: 16px;
      }

      &__item {
        margin-bottom: 8px;
      }
    }    
  }

  &__indirect {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: $gray2;
    background-color: $yellow;
    padding: 4px 6px;
    border-radius: $border-radius;

    &--guided {
      margin-top: 52px;

      @include up-to(539px) {
        margin-top: 8px;
      }
    }

    &--quick {
      margin-top: 28px;

      @include up-to(539px) {
        margin-top: 8px;
      }      
    }

    &--results {
      margin-top: 8px;
    }
  }
}
