@import "./../../sass//variables.module.scss";

.xgs-growing-textarea {
  display: grid;
  min-height: 32px;
  box-sizing: border-box;

  &::after {
    content: attr(data-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
    max-height: 60px;
    word-break: break-all;
  }

  &>textarea {
    resize: none;
    overflow: hidden;
    box-sizing: border-box;
    outline: none;

    &:focus {
      box-shadow: none;      
    }

    &:disabled {
      background-color: rgba($gray8, 0.04);
      color: rgba($gray8, 0.32);
    }
  }

  &>textarea,
  &::after {
    /* Identical styling required!! */
    border: 1px solid rgba($gray8, 0.32);
    border-radius: 4px;
    font-size: 14px;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    overflow-wrap: break-word;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }
}
