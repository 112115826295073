@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.wizard {
  &__heading {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: $margin8;
  }

  &__check {
    margin: auto;
    width: 32px;
    height: 32px;
    background-color: #5DC75433;
    border-radius: 100%;
    display: flex;
    margin-bottom: $margin7;
    align-items: center;
    justify-content: center;

    &__tick {
      align-items: center;
      color: #14870A;
    }
  }
  
  &__desc {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: $margin7;
    margin-left: 40px;
    margin-right: 40px;
  }

  &__step-indicator {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    margin: auto;
    margin-bottom: $margin7;
    &__dot {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #BDBDBD;

      &--current {        
        width: 8px;
        height: 8px;
      }

      &--completed {
        background-color: $blue1;        
      }
    }
  }

  &__navigation {
    margin-top: 16px;
    display: flex;
    gap: 12px;

    @include up-to(395px) {
      flex-direction: column;      
    } 

    .xgs-btn {
      @include up-to(395px) {
        width: 100%;      
      } 
    }
    
    &__next-button {
      flex: 1 1 auto;
    }
  }
}
