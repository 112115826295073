@import "./../../sass/variables.module.scss";

.xgs-three-dots-menu {
  &__activator {
    cursor: pointer;
    width: 14px;
    height: 20px;
  }

  &-content {
    padding: $margin8 0 !important;
    width: fit-content !important;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15) !important;
    border: 1px solid #F2F2F2 !important;
    margin-top: -8px;
    z-index: 1001 !important;

    a {
      color: $gray0 !important;
    }
  }

  &__item {
    padding: 6px 16px 6px 12px;
    height: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: $gray6;
    }

    &--inactive {
      cursor: default;
      color: $gray3;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 4px;
    margin-right: 4px;
    color: $gray_72;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    min-width: 80px;
    flex-shrink: 0;
  }
}
