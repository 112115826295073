@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-company__users {
  &__controls.xgs-list__controls {
    margin-top: -8px;
  }

  .xgs-list__controls__search {
    @include up-to(875px) {
      order: 2;
      margin-right: 24px;
    }

    @include up-to(670px) {
      margin-right: 0;
    }

    @include up-to(450px) {
      width: 100%;
    }
  }

  &__invite-button {
    width: 159px;

    @include up-to(875px) {
      order: 1;
      margin-bottom: 16px;
    }

    @include up-to(450px) {
      width: 100%;
    }
  }
}
