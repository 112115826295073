@import "../../../sass/variables.module.scss";

.xgs-important-message {
  padding: 12px;
  display: flex;
  gap: 8px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;

  &--theme {
    &_blue {
      color: $blue1;
      border-color: $blue1;
      background-color: $supporting_blue_8;
    }

    &_red {
      color: $red;
      border-color: $red;
      background-color: $supporting_red_8;
    }
  }

  svg {
    flex-shrink: 0;
  }
}