@import "./../../../sass/mixins.scss";

.terminal-selction {
  width: 320px;
  margin-bottom: 20px;
}

.active-routes-header {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.active-routes-refresh-button {
  margin-bottom: 20px;
}

.routes-container {
  display: flex;
  gap: 24px;
  padding-bottom: 0px;
  margin-bottom: 20px;
}

.routes-container-sub {
  display: flex;
  flex-direction: column;
  background-color: white;
  // width: 100vh;
  height: 100vh;
  border-radius: 10px;
  gap: 5px;
  overflow-y: scroll;

}

.routes-list-container {
  width: 20%;
  padding: 2px;
  transition: margin 0.5s ease-in-out;
}

.route-stops-container {
  width: 40%;
}

.route-map-container {
  width: 40%;
}

.route-stops-container-expanded {
  width: 40%;

}

.route-map-container-expanded {
  width: 60%;

}

.route-box {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  // gap: 5px;
}

.route-box-header {
  display: flex;
  justify-content: space-between;
  // gap: 40px;
}

.route-box-id {
  color: #2F80ED;
  text-decoration: underline;
}

.route-box-status {
  padding: 6px;
  border-radius: 8px;
  font-weight: 900;
  color: #C43232;
  background-color: #F8D9D9;
}

.route-status-done {
  color: #34A853;
  background-color: #D1ECDC;
}

.route-status-edited {
  color: #B89311;
  background-color: #FAF6DE;
}

.route-status-submitted {
  color: #B89311;
  background-color: #FAF6DE;
}

.route-status-processing {
  color: #2F80ED;
  background-color: #CDDEF3;
}

.route-status-error {
  color: #C43232;
  background-color: #F8D9D9;
}

.route-box-route-window {
  display: flex;
  flex-direction: row;
}

.route-box-ts {
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: relative;
  width: 100%;
  margin: 10px 0;

  &::before{
    content: " ";
    position: absolute;
    left: 0;
    /* visual adjustment, because of the line-size of 1px */
    bottom: 1px;
    transform: translate(-130%, -30%);
    width: 10px;
    height: 10px;
    background-color: #2F80ED;
    border-radius: 50%;
  }
}

.route-box-time {
  color: #125BBE;
}

.route-box-route-window {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-left: 10px;
  &::before{
    content: " ";
    position: absolute;
    left: 0;
    /* visual adjustment, because of the line-size of 1px */
    bottom: 1px;
    transform: translate(-250%, -30%);
    width: 4px;
    height: calc(100% - 30px);
    background-color: #2F80ED;
    opacity: 0.4;
  }
}

.route-box-icon {
  color: #828282;
  margin-right: 2px;
}

.selected-route {
  border-radius: 10px;
  border: 1px solid var(--Blue, #2F80ED);
  background: rgba(47, 128, 237, 0.08);
}

.toogle-routes-list {
  margin-bottom: 5px;
  color: #2F80ED;
}

.active-route-map {
  height: 100vh;
}

.active-route-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
  margin-bottom: 5px;
  gap: 10px;
}

.routes-collapse-all-button {
  color: #276FCF;
  padding: 8px;
}

.route-info-msg {
  border-radius: 10px;
  border: 1px solid var(--Blue, #2F80ED);
  color: #276FCF;
  background: rgba(47, 128, 237, 0.08);
  width: 100%;
  padding: 20px 5px;
}

.route-info-msg-icon {
  padding: 5px;
}

.route-box-name-icon {
  color: #276FCF;
}

.route-action {
  cursor: pointer;
}

.route-box-weight-unit {
  font-weight: lighter;
  font-size: x-small;
  margin-left: 2px;
}

.active-route-info-box{
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 10px;
  border: 1px solid var(--Blue, #2F80ED);
  color: #276FCF;
  background: rgba(47, 128, 237, 0.08);
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 5px;

  &__title{
    font-size: 18px;
    font-weight: 600;
  }

  &__content{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    &__row{
      display: flex;
      flex-direction: row;
      gap: 5px;
      width: 100%;

      &__column{
        width: 30%;

        &__title{
          font-weight: 600;
        }
      }
    }
  }
}