@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

$red: #E82A2B;
$bgRed: #E82A2B33;
$breakpoint: 1400px;

.xgs-manifests-view {
  padding-bottom: 24px;

  .xgs-header {
    font-size: 1.4rem;
    line-height: 0.9;
    margin: 0;
    padding: 0;
  }

  .xgs-list__controls {
    align-items: center;
    gap: 16px;

    @include mobile() {
      flex-direction: column;
    }
  }

  .xgs-list__controls__search {
    & > form {      
      @include up-to($breakpoint) {
        flex-wrap: wrap;
      }      
    }

    .xgs-labeled-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 200px;
      max-width: none;       

      @media (max-width: $breakpoint) {
        max-width: 220px;
      }

      @media (max-width: 768px) {
        max-width: none;
      }
    }

    .xgs-btn {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;

      @media (min-width: 768px) {
        max-width: 220px;
        margin-top: 24px;
      }

      @media (min-width: $breakpoint) {
        width: auto;        
      }
    }    
  }  

  .xgs-list__table-wrapper {
    @include responsiveTable($table-breakpoint);

    .no-data-msg {
      color: #777777;
      padding: 4px 8px;
    }
   
    .xgs-table {
      min-height: 420px;
    }    

    .xgs-table__headers {
      overflow: visible;
    }

    .th {
      padding-left: 8px;
      padding-right: 8px;
    }

    .tr:nth-child(2n) {
      background-color: #f8f8f8;
    }

    .tr {
      padding: 0 8px;
    }

    .td {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 65px;
      padding: 8px;
    }   

    .cell__driver span {      
      margin-left: 4px;
    }

    .cell__exc {
      padding: 2px 4px;
      border-radius: 4px;
      background-color: #ececec;

      &.--alert {
        background-color: $bgRed;
        color: $red;
      }
    }
  }
}

.xgs-manifest__details-view {
  padding-bottom: 50px;

  .xgs-manifest-map__container {
    position: relative;
    display: flex;
    aspect-ratio: 16/9;
    width: 100%;
    height: 400px;

    > .xgs-map { flex: 1 }   
  }

  .xgs-manifest-map__state {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    pointer-events: none;
  }

  &__driver-info {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 24px;
    margin: 16px 0;
    padding: 16px;
    text-decoration: none;
    border-radius: 8px;
    border: 1px solid #E0E0E0;

    &--active {
      cursor: pointer;

      &:hover {
        background-color: $gray5;
      }
    }

    @include mobile() {
      flex-direction: column;
      align-items: start;
      gap: 8px;
      margin: 0;
    }

    .driver-info-item {
      display: flex;
      align-items: center;
      padding: 8px 0;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #333333;

      &:nth-child(2) { flex: 1; }

      &.--route {
        text-transform: uppercase;
      }

      > svg { margin: 4px; }

      @include mobile() {
        padding: 0;
      }
    }
  }    

  .probill {
    &-number {      
      height: 20px;
      line-height: 20px;
      cursor: pointer;
      color: $blue1;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &-status {
      cursor: pointer;

      &__details {
        visibility: hidden;
        position: absolute;   /* relatively to probill item */
        z-index: 1;
        top: 45px;
        left: 25%;
        display: flex;
        justify-content: center;
        padding: 8px;
        border-radius: 4px;
        background-color: rgba(234, 232, 236, 0.98);
        font-size: 14px;
        text-decoration: none;
        color: #171717;

        &-list {
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 0;
          margin: 0;

          .probill-status__details-item {
            flex: 1;
            width: 100%;
            padding: 4px 8px;

            &__label {
              color: #828282;
            }
          }
        }
      }

      &:hover .probill-status__details {
        visibility: visible;
      }
    }

    &-exceptions, &-exceptions b {
      color: #E82A2B;
    }

    &-exceptions {
      padding: 0 4px;
      height: 20px;
      line-height: 20px;
      width: fit-content;
      cursor: pointer;
      user-select: none;   
      transition: background-color .2s linear;

      &:hover {
        color: #C43232;
      }

      .xgs-icon {
        margin-left: 3px;
      }
    }
  }
}

.xgs-manifest__exception {
  &s-header {
    padding: 16px 0;
    margin: 0;
    font-size: 18px;
    color: #333333;
  }

  &-popup-content {
    max-height: 90vh;
    max-width: 90vh;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &-details {
    display: flex;
    padding: 16px;
    border: 1px solid #bdbdbd;
    border-radius: 8px;
    grid-gap: 12px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &__col {
      &:first-child {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 12px;
      }
    }

    .exc__item {
      display: flex;
      gap: 12px;
      color: #777777;
      line-height: 1.4;

      &:first-child {
        width: 100%;
      }

      b {
        color: #333333;
        font-weight: 600;
      }
    }  

    .exc__note {
      width: 100%;
      margin: 0;
      //padding: 16px 8px;
      //border-left: 4px solid #bdbdbd;
      //background-color: #ececec;
    }
  }
}

.xgs-manifest__compliance-percentage {
  &__chart {
    width: 28px;
    height: 28px;
    position: relative;
  }

  &__chart-label {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 8px);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
}

.compliance-percentage-legend {
  width: 185px;
}