@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-info-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-basis: auto;
  justify-content: flex-start;
  gap: 24px 16px;

  & > div:not(.xgs-shipment-details__grid__double-column) {
    flex-grow: 1;
    max-width: calc(100% / 3 - 16px);

    @include up-to(1140px) {
      max-width: calc(100% / 2 - 8px);
    }

    @include up-to(692px) {
      margin-right: 0;
      max-width: none;
    }
  }
}