@import "../../sass/variables.module.scss";
@import "../../sass/mixins.scss";

.xgs-holiday-calendar {
   margin-bottom: 200px;
   
  .react-datepicker {
    font-size: 1.1em;  
  }
  .react-datepicker__arrow-left {
    height: 2em;
  }
  .react-datepicker__day, .react-datepicker__day-name {
    width: 2em !important;
    height: 2em !important;
    line-height: 2em !important;
    border-radius: $border-radius;
  }
  .react-datepicker__day--selected {
    color: white;
  }

  &__log-button {
    width: 90px;
  }
  
  &__holiday-name {
    display: block;
    margin-left: $margin8;
    svg {
      margin-right: 5px;
    }
  }
  
  &__calendar {
    display: block;
    margin-top: $margin8;
  }
  &__suggestion {
    color: $xgs-red;
    font-weight: bold;
  }
  &__holiday {
    font-weight: bold;
    color: $xgs-blue;
    border: 1px solid $xgs-blue;
    box-sizing: border-box;
  }
  
  &__flex-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  &__help {
    @include mobile {
      margin-top: -8px;
    }

    &__legend {
      font-size: 13px;
      margin-bottom: $margin7;
      margin-top: $margin8;
      &__red {
        margin-top: $margin8;
        span {
          font-weight: bold;
        }
      }
      &__yellow {
        span {
          font-weight: bold;
        }
      }
    }

    &__warning {
      color: $red;
      font-size: 14px;
      margin-bottom: $margin8;
    }
  }

  &__form {
    &__name-input {
      // background-color: red;
      width: 299px;
      margin-bottom: $margin8;
    }

    &__buttons {
      display: flex;
      align-items: center;
      margin-top: $margin7;
      margin-bottom: $margin7;
      gap: 10px;

      @include mobile {
        flex-wrap: wrap;
        button {
          width: 100%;
        }
      }
    }
  }

  &__logs {
    &__heading {
      margin-top: $margin7;
      margin-bottom: $margin7;
    }
    
  }

  &__customer {
    .xgs-site__content-container__component {
      margin: 4px 0px;
    }
    .xgs-site__content-container__toolbar {
      margin: 0px;
      margin-top: -10px;
      font-size: 10px;
    }

    .xgs-site__content-container__title {
      font-size: 16px;
      margin-top: -4px;
    }
  }
}
