
.xgs-edit-route-details{
  &__delete-route{
    cursor: pointer;
    text-align: right;
    color: #C43232;
    &__icon{
      margin-right: 5px;
    }
  }
  &__form{
    &__save{
      margin-top: 10px;
    }
  }
}