@import "./../../../../../sass/variables.module.scss";
@import "./../../../../../sass/mixins.scss";

.xgs-appointment-filters {
  &__top-row {
    gap: 24px;
    display: flex;
    flex-shrink: 1;
    width: 100%;
    margin-bottom: $margin6;

    @include up-to(959px) {
      flex-wrap: wrap;
    }

    @include up-to(767px) {
      margin-bottom: 16px;
    }
  }

  &__item {
    &__switch {
      display: flex;
      align-items: center;

      @include up-to(767px) {
        width: 50%;
        word-break: break-word;
      }

      @include up-to(359px) {
        width: 100%;
      }

      span {
        margin-left: $margin8;
        font-size: 14px;
        margin-right: $margin7;
      }
    }
  }

  &__controls {
    padding: 14px 16px 16px;
    display: flex;
    gap: 16px;

    @include up-to(767px) {
      flex-wrap: wrap;
      padding: 0;
    }

    @include up-to(767px) {
      width: 100%;
    }

    &__search-button {
      align-self: flex-end;
      min-width: none;
      margin-bottom: 4px;

      @include up-to(1279px) {
        margin-bottom: 0;
      }

      @include up-to(767px) {
        width: 100%;
      }
    }

    &__tracking-number {
      width: 168px;

      @include up-to(1279px) {
        width: 300px;
      }

      @include up-to(767px) {
        width: 100%;
      }
    }

    .xgs-labeled-date-range-input {
      width: 210px;

      @include up-to(767px) {
        width: 100%;
      }
    }

    .xgs-appointment-stats {
      @include up-to(959px) {
        width: 100% !important;
        background-color: red;
      }
    }
  }

  &__bottom-row {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: $margin6;
  
    &__filters {
      display: flex;

      @include up-to(767px) {
        width: 100%;
        justify-content: center;
      }

      @include up-to(359px) {
        flex-wrap: wrap;
        gap: 8px;
      }
    }

    &__buttons {
      display: flex;

      @include up-to(767px) {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 8px;
      }

      &--desktop {
        @include up-to(767px) {
          display: none;
        }

        .xgs-appointment-filters__small-button {
          color: $blue1;
          font-size: 16px;
          margin-right: $margin6;

          img, svg {
            margin-right: $margin8;
          }

          &--grey {
            color: $gray4;
          }
        }
      }

      &--mobile {
        display: none;
        gap: 16px;

        @include up-to(767px) {
          display: flex;
        }

        @include up-to(359px) {
          flex-wrap: wrap;
        }

        .xgs-appointment-filters__small-button {
          width: 50%;

          @include up-to(359px) {
            width: 100%;
          }

          img, svg {
            margin-right: $margin8;
          }
        }
      }
    }
  }
}
