@import "./../../../sass/variables.module.scss";

.xgs-labeled-value {
  display: flex;
  flex-direction: row;

  &.vertical {
    flex-direction: column;

    label {
      margin-bottom: 6px;
      color: $gray3;
    }
  }

  label {
    flex-shrink: 0;
    font-size: 14px;
    color: $gray2;
  }

  span {
    color: $gray0;
  }
}