@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

$breakpoint: 900px;

.xgs-documents__recognition-logs {
  @include responsiveTable($table-breakpoint);

  .tr {
    align-items: center;
  }

  .blue-link:hover {
    text-decoration: underline;
  }
}
