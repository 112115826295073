@import "./../../sass/variables.module.scss";

.help { 
  color: $gray3;
  cursor: pointer;

  &:hover {
    color: $blue1;
  }

  &__description {
    margin-bottom: 16px;
  }

  &__item {
    margin-bottom: 8px;
  }
}