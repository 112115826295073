@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-trailer-locations {
  &__open {
    &__container {
      text-align: center;

      &--text-mode {
        text-align: inherit;
      }
    }

    &__text-link {
      &__icon {
        margin-right: 4px;
      }
    }

    &__button {
      margin-top: 8px;
    }
  }

  &__popup {
    &-content {
      /* popup built-in class */
      width: 900px !important;
      max-height: 90vh;
      max-width: 90vw;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      z-index: 1005;
      //background-color: black;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 270px;
      min-height: 160px;

      .xgs-map__container {
        width: 800px;
        max-width: calc(100vw - 48px);
        max-height: calc(100vh - 108px);
      }
    }
  }
}