@import "./../../../sass/mixins.scss";

.xgs-service-disruption {

  &__table-wrapper {
    @include responsiveTable($table-breakpoint);

    .no-data-msg {
      color: #777777;
      padding: 4px 8px;
    }

    .xgs-table {
      min-height: 420px;
    }

    .xgs-table__headers {
      overflow: visible;
      height: 50
    }

    .th {
      padding-left: 8px;
      padding-right: 8px;
    }

    .tr {
      padding: 0 8px;
    }

    .td {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: auto;
      padding: 8px;
    }

    .cell-probill-status {
      display: flex;
      height: auto;
      padding: 4px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #7936D0;
      background-color: #9747FF33;
      border-radius: 8px;
    }

    .cell-late-reason{
      display: flex;
      flex-direction: column;
      height: fit-content;
      padding: 6px;
      align-items: flex-start;
      gap: 5px;
      color: #E82A2B;
      background-color: #DD3F3F29;
      border-radius: 8px;

      .reason-details{
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
    }
  }

  
}