@import "../../../sass/mixins.scss";
@import "../../../sass/variables.module.scss";

.xgs-portal-registration {
  &__address-info {
    background-color: $gray6;
    border-radius: $border-radius;
    color: $gray1;
    margin: -8px 0 0 190px;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 1.2;
    word-break: break-word;

    & > div {
      margin-bottom: 4px;
    }

    @media (max-width: 510px) {
      margin-left: 0;
      width: 100%;
    }    
  }

  .xgs-form__field__notes {
    margin-top: 8px;
    padding-left: 190px;

    @include up-to(500px) {
      padding-left: 0;
    }
  }
}
