@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-notifications {
  &__timezone {
    display: flex;
    align-items: center;

    @include up-to(420px) {
      flex-wrap: wrap;
      align-items: flex-end;
    }

    &__select {
      flex-shrink: 0;
      width: 100%;
      max-width: 420px;

      &.xgs-labeled-input.row label {
        width: 120px;

        @include up-to(420px) {
          width: 100%;
          margin-bottom: 8px;
        }
      }

      & > div {
        width: calc(100% - 120px) !important;

        @include up-to(420px) {
          width: 100% !important;
        }
      }
    }

    &__loading {
      width: 48px;
      margin-left: -48px;

      @include up-to(420px) {
        margin-bottom: 8px;
      }
    }
  }

  &__loading {
    margin-top: 16px;
    margin-bottom: -8px;
    height: 28px;
  }

  &__toggles {
    &__headers {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $gray3;
      margin-bottom: 16px;
      border-bottom: 1px solid $gray5;
      width: 670px;
      padding-bottom: 6px;

      @include up-to(700px) {
        width: 610px;
      }

      @include up-to(640px) {
        width: 100%;
      }      
    }

    &__header {
      &__name {
        width: 180px;
        text-align: center;
        flex-shrink: 0;

        @include up-to(640px) {
          width: calc(100% - 200px);
        }

        @include up-to(400px) {
          width: calc(100% - 140px);
        }
      }

      &__control {
        width: 100px;
        text-align: center;

        &__enable {
          @include up-to(400px) {
            width: 60px;
          }
        }
      }

      &__contact-data {
        width: 260px;
        margin-left: 16px;

        @include up-to(700px) {
          width: 200px;
        }

        @include up-to(640px) {
          display: none;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      @include up-to(640px) {
        flex-wrap: wrap;
        margin-bottom: 32px;
      }

      &__name {
        font-size: 14px;
        font-weight: 400;
        word-wrap: break-word;
        width: 180px;
        padding-right: 16px;
        flex-shrink: 0;

        @include up-to(640px) {
          width: calc(100% - 200px);
        }

        @include up-to(400px) {
          width: calc(100% - 140px);
        }

        span {
          color: $red;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: center;
        justify-content: center;
        width: 100px;
        flex-shrink: 0;

        &__enable {
          @include up-to(400px) {
            width: 50px;
          }
        }

        &__method {
          @include up-to(400px) {
            width: 90px;
            justify-content: flex-end;
          }
        }
      }

      &__no-contact-data {
        @include up-to(640px) {
          display: none;
        }
      }

      &__contact-data {
        font-size: 14px;
        color: $gray3;
        margin-left: 16px;
        width: 260px;
        word-wrap: break-word;

        @include up-to(640px) {
          width: 100%;
          margin-top: 10px;
          margin-left: 0;
        }

        &__to {
          color: #000000;
          display: none;

          @include up-to(640px) {
            display: inline;
          }
        }
      }
    }
  }
}
