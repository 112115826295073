@import "./../../../../sass/variables.module.scss";
@import "./../../../../sass/mixins.scss";

.xgs-invoice-details-grid {
  max-width: 928px;
  margin: 24px 0;
  
  .xgs-info-block {
    width: 288px;
    min-width: 254px;

    @include up-to(692px) {
      width: 100%;
      flex-shrink: 0;
      margin-right: 0;
    }

    &__phone {
      color: $gray2;
      font-size: 14px;
    }
  }

  .xgs-labeled-value span {
    @include up-to(350px) {
      width: 90px !important;
    }
  }
}