@import "../../../sass/variables.module.scss";
@import "../../../sass/mixins.scss";

.xgs-company__accounts {
  &__details {
    &__name-block {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: $margin6;
    }

    &__name {
      font-weight: 500;
      color: $gray0;
      margin-bottom: $margin8;
    }

    &__address {
      color: $gray2;
      margin-bottom: $margin8;
    }

    &__number {
      color: $gray4;
    }

    &__permissions {
      position: relative;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      margin-bottom: 52px;

      &__header {
        font-weight: bold;
        margin-right: 48px;
        margin-bottom: 24px;
      }

      &__notes {
        margin-top: 8px;
        color: $gray3;
      }

      &__spinner {
        position: absolute;
        right: 16px;
        top: 0;
      }
    }

    &__confirm-text {
      line-height: 1.5;
    }

    &__add-user {
      &__permissions {
        margin-top: 30px;
        
        &__add {
          margin-top: $margin6;
          margin-bottom: $margin8;
          display: flex;
          align-items: center;

          @include up-to(420px) {
            margin-bottom: 24px;

            .xgs-select__placeholder {
              font-size: 12px;
            }
          }

          &__dropdown {
            width: 100%;
            margin-right: $margin8;
          }
        }

        &__row {
          display: flex;
          align-items: center;
          margin-bottom: $margin8;

          @include up-to(420px) {
            flex-wrap: wrap;
            margin-bottom: 20px;

            .xgs-company__accounts__details__add-user__permissions__name {
              width: 100%;
              margin-bottom: 4px;
            }

            .xgs-company__accounts__details__add-user__permissions__select {
              width: calc(100% - 44px);
            }

            .xgs-company__accounts__details__add-user__permissions__name {
              width: 100%;
            }
          }
        }

        &__name {
          width: 182px;
          padding-right: $margin8;
          font-size: 14px;
          line-height: 16px;
          word-break: break-word;
        }

        &__select {
          width: 100%;
        }

        &__remove {
          margin-left: 8px;
        }

        &__row--user-details {
          .xgs-company__accounts__details__add-user__permissions__name {
            width: 230px;
          }
        }
      }
    }

    &__no-permissions {
      color: $gray3;
    }

    &__metrics {
      display: flex;
      flex-wrap: wrap;

      .xgs-summary-block {
        width: 370px;
        margin-right: 16px;

        @include up-to(500px) {
          width: 100%;
          margin-right: 0;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
