.legend {
  &__trigger {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

  &__item {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

  }

  &__description {
    color: #000;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}