@import "./../../../../sass/variables.module.scss";
@import "./../../../../sass/mixins.scss";

.xgs-reports__scheduled__unsubscribe {
  margin: 54px 0 0 -24px;
  text-align: center;
  padding: 0 16px;

  &__error {
    margin: 0 auto;
  }

  &__success {
    font-size: 18px;
    color: $gray2;
  }

  &__home {
    margin: 48px 0;
  }
}
