@import "../../../../../sass/mixins.scss";

.add-probills-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  z-index: 1000;
  overflow-y: auto;
}

.add-probills-modal {
  position: absolute;
  background-color: #FFF;
  padding: 15px;
  z-index: 1000;
  width: 90%;
  border-radius: .5em;
}

.add-probills-modal-header{
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.add-probills-modal-header-close{
  align-content: center;
  position: absolute;
  right: 0;
  margin-right: 20px;
  cursor: pointer;
}

.add-probills-modal-header-titile{
  margin-bottom: 5px;
}

.add-probills-summary-container{
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  gap: 24px;
  border-radius: 4px;
  background: var(--White, #FFF);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
  margin-bottom: 10px;
  transition: margin 0.5s ease-in-out;
  margin-top: 10px;

}
.add-probills-summary-titile{
  color: var(--Gray, #828282);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
