@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-claims {
  &__public-form-button {
    font-size: 14px;
    width: 180px;
    height: 48px;

    @include up-to(540px) {
      width: 100%;
    }
  }

  &__description {
    color: $gray3;
    margin-bottom: $margin6;
  }

  &__po-number {
    font-size: 14px;

    @include up-to(540px) {
      margin-bottom: 24px;
    }

    .xgs-form__label {
      margin-bottom: 8px;
    }
  }

  &__form {
    width: 540px;

    @include up-to(600px) {
      width: 100%;
    }

    .xgs-textarea {
      width: 100%;
    }

    &__textarea {
      width: 100%;
      height: 104px !important;
      resize: none;
    }

    .xgs-checkbox {
      margin-bottom: 36px;      
    }

    &__field__zip {
      max-width: 126px;

      @include up-to(700px) {
        max-width: none;
        width: 100%;
      }
    }

    .xgs-form__field__notes {
      margin: 8px 0 0 0;
    }
  }

  &__section {
    margin: 0 -16px 24px -16px;
    padding: 16px;
  }

  &__row {
    display: flex;

    @include up-to(540px) {
      flex-wrap: wrap;
    }

    &__column {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &__column-50 {
      width: calc(50% - 12px);

      @include up-to(540px) {
        width: 100%;
      }

      &:first-child {
        margin-right: 24px;

        @include up-to(540px) {
          margin-right: 0;
        }
      }

      &--mobile-hidden {
        @include up-to(540px) {
          display: none;
        }
      }
    }
  }

  &__freight-charges {
    margin-bottom: 48px;

    &--narrow {
      .xgs-checkbox {
        margin-bottom: 16px;
      }
    }

    &__description {
      color: $gray2;
    }

    &__no {
      display: inline-block;
      font-size: 14px;
      color: $red;
      background-color: $gray7;
      border-radius: $border-radius;
      padding: 8px 16px;
    }
  }

  &__item-form {
    .xgs-labeled-input {
      min-height: 64px;
    }

    &__field {
      font-size: 14px;
      margin-bottom: 16px;
    }    

    &__buttons {
      .xgs-btn {
        margin-right: 16px;

        @include up-to(650px) {
          width: 120px;
        }

        @include up-to(620px) {
          width: 150px;
        }

        @include up-to(540px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
    }
  }

  &__probill-no-items {
    margin-bottom: 24px;
    line-height: 1.4;
    font-size: 14px;
    color: $gray2;

    &__error {
      color: $red;
      margin-bottom: 8px;
    }
  }

  &__items {
    background-color: $gray7;
    margin: 16px 0 50px 0;
    padding: 16px;

    &__no-items {
      margin: 16px 0;
      font-size: 14px;
      color: $gray2;
    }

    &__item {
      font-size: 14px;
      background-color: $gray6;
      padding: 16px;
      border-radius: $border-radius;
      margin-bottom: 16px;

      &__columns {
        display: flex;
        align-items: center;
      }

      &__properties {
        width: calc(100% - 64px);
        padding-right: 16px;
      }

      &__action {
        line-height: 1.8;

        .blue-link:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__item {
    &__case {
      width: 74px;

      @include up-to(540px) {
        width: 100%;
      }
    }

    &__number {
      width: 74px;

      @include up-to(540px) {
        width: 100%;
      }
    }

    &__description {
      width: 212px;

      @include up-to(600px) {
        width: 100%;
      }
    }

    &__item-price {
      width: 74px;

      @include up-to(540px) {
        width: 100%;
      }
    }

    &__total-price {
      width: 74px;

      @include up-to(540px) {
        width: 100%;
      }      
    }
  }

  &__files {
    .xgs-claims__row {
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__instructions {
      font-size: 14px;
      margin-bottom: 16px;
      line-height: 24px;
    }

    &__label {
      font-size: 14px;
      margin-right: 8px;

      &--error {
        color: $red;
      }
    }
  }

  &__remove-file {
    color: $gray3;
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      color: $gray4;
    }
  }

  &__documents {
    &__item {
      margin-bottom: 32px;
    }
  }

  &__submit-button {
    margin: $margin7 0 $margin5 0;
    width: 159px;

    @include up-to(540px) {
      width: 100%;
    }
  }

  &__success {
    line-height: 24px;
  }

  &__submit-another-button {
    width: 180px !important;
    
    @include up-to(540px) {
      width: 100%;
    }
  }

  &__details {
    &__documents {
      display: flex;
      flex-wrap: wrap;
      margin-left: -6px;

      &__item {
        position: relative;
        text-align: center;
        margin-right: 24px;
        color: $gray3;
        text-decoration: none;
        width: 58px;
        line-height: 1.3;
        margin-bottom: 24px;

        &:not(.xgs-claims__details__documents__item--loading) {
          cursor: pointer;
        }

        &:hover,
        &:hover .xgs-claims__details__documents__item__icon {
          color: $blue1;
        }

        &__icon {
          font-size: 60px;
          color: $gray4;
          margin-bottom: 2px;
        }

        &__spinner {
          position: absolute;
          top: 18px;
          left: 2px;
          color: white;
          font-size: 18px;
        }
      }
    }
  }

  &__filters {
    &.xgs-list__controls {
      margin-top: 16px;

      @include up-to(540px) {
        margin-top: 0;
      }
    }    

    &__buttons {
      margin-top: 8px;      

      @include up-to(520px) {
        margin-top: 16px;
        width: 100%;
      }

      @include up-to(510px) {
        margin-top: 8px;
      }

      .xgs-list__controls__button {
        @include up-to(510px) {
          width: 100%;
        }
      }
    }
  }

  &__list {
    &__highlighted-row {
      background-color: $gray5;
    }
  }  

  &__edit-popup {
    &-overlay {
      z-index: 1001 !important;
    }

    &-content {
      @include up-to(394px) {
        width: 100% !important;
      }      
    }
    
    &__buttons {
      margin-top: 16px;

      .xgs-btn {
        width: 150px;
        &:not(:first-child) {
          margin-left: 16px;
        }

        @include up-to(394px) {
          width: 100%;

          &:not(:first-child) {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }
    }

    &__field {
      margin-top: 16px;
    }    
  }

  &__phone-checkbox {
    margin-top: 3px;
  }
}
