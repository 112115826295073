@import "../../../sass/variables.module.scss";
@import "../../../sass/mixins.scss";

$breakpoint: 1400px;

.xgs-unplannabe-probills {
  padding-bottom: 24px;
  
  &__summary {
    align-items: center;
    gap: 16px;
    margin-bottom: 25px;
    padding-right: 40px;

    @include mobile() {
      flex-direction: column;
    }
  }
  .xgs-list__controls {
    align-items: center;
    gap: 16px;

    @include mobile() {
      flex-direction: column;
    }
  }

  .xgs-list__controls__search {
    & > form {      
      @include up-to($breakpoint) {
        flex-wrap: wrap;
      }      
    }

    .xgs-labeled-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 200px;
      max-width: none;       

      @media (max-width: $breakpoint) {
        max-width: 220px;
      }

      @media (max-width: 768px) {
        max-width: none;
      }
    }

    .xgs-btn {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;

      @media (min-width: 768px) {
        max-width: 220px;
        margin-top: 24px;
      }

      @media (min-width: $breakpoint) {
        width: auto;        
      }
    }    
  }  

  &__table-wrapper {
    @include responsiveTable($table-breakpoint);

    .no-data-msg {
      color: #777777;
      padding: 4px 8px;
    }
   
    .xgs-table {
      min-height: 420px;
    }    

    .xgs-table__headers {
      overflow: visible;
      height: 50
    }

    .th {
      padding-left: 8px;
      padding-right: 8px;
    }

    .tr {
      padding: 0 8px;
    }

    .td {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 65px;
      padding: 8px;
    }   
  }
}