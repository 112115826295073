@import "../../sass/variables.module.scss";

.xgs-site__download-pdf {
  &__button {
    cursor: pointer;

    &__icon {
      font-size: 18px;
    }

    &__label {
      font-size: 11px;
      line-height: 12px;
      margin-top: 7px;
    }

    &__single {
      display: inline-block;
      text-align: center;
    }

    .xgs-loading {
      margin: 0;
    }
  }
}
