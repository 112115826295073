@import "./../../../sass/variables.module.scss";

.xgs-aging-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  .xgs-aging-item {
    margin-bottom: 24px;
    margin-right: 32px;

    &:hover {
      background-color: hsla(0, 0%, 95%, 1);
      box-shadow: 0px 0px 0px 16px hsla(0, 0%, 95%, 1);
      transition: background-color 0.2s,
      box-shadow 0.2s;
    }
  }
}