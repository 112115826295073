@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-teams {
  @include up-to(1070px) {
    .xgs-list__controls__search {
      width: 100%;
      order: 2;
    }
    
    .xgs-list__controls__actions {
      width: 100%;
      order: 1;
    }
  }

  &__create-team-button {
    width: 180px;

    @include up-to(1070px) {
      margin: $margin8 0 $margin7 0;
    }

    @include up-to(450px) {
      width: 100%;
    }
  }
}
