@import "./../../sass/variables.module.scss";

.xgs-btn {
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  height: 32px;
  width: 140px;
  font-size: 16px;
  transition: background-color 0.2s;

  &:focus,:active {
    outline: none;
    box-shadow: 0 0 0 2pt $supporting_blue_40;
  }

  &:disabled {
    background-color: rgba($gray8, 0.24);
    color: rgba($gray8, 0.32);
    cursor: default;
  }

  &__spinner {
    margin-right: 8px;
  }

  &__icon {
    font-size: 14px;
  }

  &__icon-container {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  &--size {
    &_small {
      padding: 4px 7px;
      width: auto;
      font-size: 14px;
    }

    &_medium {
      height: $margin5;
    }

    &_auto {
      width: auto;
    }

    &_auto-medium {
      width: auto;
      padding-left: 24px;
      padding-right: 24px;
    }

    &_full-width {
      width: 100%;
    }
  }

  &--theme {
    &_blue {
      background: $blue1;
      color: white;

      &:hover:enabled {
        background: $blue4;
      }
    }

    &_gray {
      background-color: rgba($gray8, 0.08);
      color: #000000;

      &:hover:enabled {
        background-color: rgba($gray8, 0.32);
      }
    }

    &_white {
      background-color: white;
      color: $gray3;

      &:hover:enabled {
        background-color: #e9e9e9;
      }
    }

    &_white-blue {
      background-color: white;
      color: $blue1;

      &:hover:enabled {
        color: $blue4;
      }
    }

    &_green {
      background-color: $green-button;
      color: white;

      &:hover:enabled {
        background-color: $green1;
      }
    }

    &_red {
      background-color: $red-button;
      color: white;

      &:hover:enabled {
        background-color: $red;
      }
    }

    &_red-inverted {
      background-color: white;
      border: 1px solid $red-button;
      color: $red-button;

      &:hover:enabled {
        border: 1px solid #C43232;
        color: #C43232;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &_purple {
      background-color: rgba(151, 71, 255, 0.16);
      color: #7936D0;
    }

    &_transparent {
      padding: 0;
      background: none;
      color: $blue1;
      height: auto;
      line-height: 20px;

      &:hover:enabled {
        color: $blue4;
      }

      &:focus,:active {
        box-shadow: none;
      }

      &:disabled {
        background: none;
        color: rgba($gray8, 0.32);
        cursor: default;
      }
    }

    &_blue-inverted {
      background-color: white;
      color: $blue1;
      border: 1px solid $blue1;

      &:hover:enabled {
        border-color: $blue4;
        color: $blue4;
        background-color: $supporting_blue_8;
      }

      &.xgs-btn--spinner {
        border-color: $blue4;
        color: $blue4;
        background-color: $supporting_blue_8;
        opacity: 0.64;
      }
    }
  }

  &--spinner {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:disabled.xgs-btn-blue {
      background: $blue1;
      color: white;
    }
  }

  &--only-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--flex {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  
    .xgs-btn__icon-container {
      margin-left: 0;
  
      &:first-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}