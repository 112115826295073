@import "./../../sass/mixins.scss";

.shipper-create-popup-content {
  .xgs-popup {
    overflow-y: visible;
  } 

  .xgs-warning {
    margin-top: 16px;
  }
}

.shipper-create-popup-overlay {
  overflow-y: auto;
}