@import "../../sass/variables.module.scss";
@import "../../sass/mixins.scss";

.xgs-warehouse-summary {
  &__info {
    margin-bottom: 12px;
    color: #4F5F72B8;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  &__summary-blocks {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: $margin6;
    width: 100%;

    button {
      color: $blue1;
      width: fit-content;
      height: fit-content;
      padding: 4px 4px;
      margin: 0;
      margin-top: -3px;
      float: right;
    }
    
    @include up-to(1080px) {
      width: 93%;
      flex-wrap: nowrap;
      overflow: scroll;
      & > * {
        min-width: 220px;
      }
    }
  }

  &__summary-block {
    padding: 16px;
    width: 220px;
    border: 1px solid #4F5F7252;
    border-radius: 4px;
    cursor: pointer;

    &__title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
      line-height: 20px;
    }

    &__values {
      color: #4F5F72B8;
      font-size: 14px;
      line-height: 22px;
      span {
        float: right;
        color: black;
      }
    }
  }

  &__sections {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    
  }

  &__section {
    @include up-to(700px) {
      width: 93%;
    }

    &__title {
      display: flex;
      align-items: center;
      padding: 24px;
      height: 72px;
      border-radius: 4px 4px 0px 0px;
      border: 1px solid rgba(79, 95, 114, 0.32);
      font-weight: 600;

      @include up-to(450px) {
        border: none;
        padding: 0px;
        height: auto;
        margin-bottom: $margin7;
      }

      img {
        @include up-to(450px) {
          display: none;
        }
      }
    }

    &__body {
      padding: 24px;
      border-radius: 0px 0px 4px 4px;
      border: 1px solid rgba(79, 95, 114, 0.32);
      border-top: none;
      // border-right: 1px solid rgba(79, 95, 114, 0.32);
      // border-bottom: 1px solid rgba(79, 95, 114, 0.32);
      // border-left: 1px solid rgba(79, 95, 114, 0.32);
      max-height: 600px;
      overflow: auto;

      @include up-to(450px) {
        display: none;
        padding: 0px;
      }

      div[role="rowgroup"] {

        > * {
          align-items: center;
        }
        
        > :nth-child(even) {
          background-color: $gray7;
        }
      }
    }

    &__body--small {
      display: none;

      @include up-to(450px) {
        display: block;
      }
    }
  }

  &__item-cell {
    font-size: 14px;
    span {
      color: #4F5F72B8;
    }
  }

  &__card {
    width: 100%;
    padding: 16px;
    margin-bottom: $margin8;
    border-radius: 4px;
    border: 1px solid #4F5F7252;

    &__title {
      display: flex;
      gap: 8px;
      font-size: 14px;
      margin-bottom: $margin7;
      span {
        color: #4F5F72B8;
      }
    }

    &__body {
      display: flex;
      justify-content: space-between;
      &__item {
          &__label {
            font-size: 12px;
            color: #4F5F72B8;
          }
          &__value {
            font-size: 14px;
            span {
              color: #4F5F72B8;
            }
          }
      }
    }
  }
}
