@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.collapsible {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;

  @include mobile() {
    padding: 8px 12px
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-content {
    flex: 1;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 24px;
    min-width: 24px;
    cursor: pointer;
    border-radius: 4px;
    color: #828282;

    &:hover {
      background-color: #f4f4f4
    }    
  }

  &__button-icon {
    transition: transform 100ms ease-in-out;
  }

  &__button-label {
    @include up-to(768px) {
      display: none;
    }
  }

  &__content {
    display: flex;
    max-height: 0px;
    margin-top: 0;
    overflow: hidden;
    transition: max-height, margin-top 125ms ease-in-out;   
  }    
}

.collapsible--open {
  & > .collapsible__header {
    .collapsible__button-icon {
      transform: rotate(180deg)
    }
  }

  & > .collapsible__content {
    overflow: visible;
    max-height: 100%;
    margin-top: 16px;
  }
}

.collapsible--full-size {
  width: 100%;
  padding: 0;

  .collapsible__header {
    padding: 16px 24px;
  }

  &.collapsible--open {
    & > .collapsible__content {
      border-top: 1px solid #E0E0E0;
      margin-top: 0;
    }
  }
}