@import "../../../sass/variables.module.scss";
@import "../../../sass//mixins.scss";


$icon-opacity: 0.16;

.xgs-summary__tile {
  background-color: $gray_4;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 8px 12px;
  display: flex;
  gap: 8px;
  align-items: center;

  @include mobile() {
    flex: 0 0 192px;
  }

  &__icon {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: 14px;
      height: auto;
    }
  }

  &__value {
    font-family: "Roboto-Bold";
    font-size: 18px;
    line-height: 24px;

    svg {
      width: 16px !important;
      height: auto !important;
    }
  }

  &__description {
    color: $gray_72;
    font-size: 12px;
    line-height: 16px;
  }

  &--clickable:hover {
    cursor: pointer;
    border: 1px solid rgba(47, 128, 237, 0.32);
    background: rgba(47, 128, 237, 0.08);
  }

  &--color {
    &_red {
      .xgs-summary__tile__icon {
        color: #C43232;
        background-color: rgba(221, 63, 63, $icon-opacity);
      }
    }

    &_green {
      .xgs-summary__tile__icon {
        color: #14870A;
        background-color: rgba(93, 199, 84, $icon-opacity);
      }
    }

    &_blue {
      .xgs-summary__tile__icon {
        color: #2F80ED;
        background-color: rgba(47, 128, 237, $icon-opacity);
      }
    }

    &_orange {
      .xgs-summary__tile__icon {
        color: #E16C00;
        background-color: rgba(225, 108, 0, $icon-opacity);
      }
    }
  }
}