@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-steps-milestones {
  margin-left: 24px;
  background-color: $gray_4;
  padding: 16px;
  width: 300px;
  height: 100%;
  border: 1px solid $gray_24;
  border-radius: $border-radius;

  @include up-to(620px) {
    margin-left: 0;
    width: 100%;
    padding: 24px 16px;
  }

  &__item {
    color: $gray_72;

    &--current {
      color: $gray0;

      .xgs-steps-milestones__item__circle {
        background-color: $gray_72;
      }
      
      .xgs-steps-milestones__item__data {
        color: $gray2;
      }
    }

    &--previous {
      .xgs-steps-milestones__item__circle {
        background-color: $gray_72;
      }

      .xgs-steps-milestones__item__line {
        background-color: $gray_72;
      }

      .xgs-steps-milestones__item__data {
        color: $gray2;
      }
    }

    &__name {
      padding-left: 8px;

      &--link {
        color: $gray0;
        cursor: pointer;

        &:hover {
          color: $blue1;
        }
      }
    }

    &__columns {
      display: flex;
    }

    &__circle {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: $gray_24;
      margin-top: 5px;
    }

    &__line {
      margin-left: 2px;
      width: 2px;
      min-height: 16px;
      background-color: $gray_24;
      flex-shrink: 0;

      &--invisible {
        background-color: inherit;
        min-height: auto;
      }
    }

    &__data {
      padding: 8px 8px 16px 10px;
      font-size: 12px;
      line-height: 1.3;
      word-break: break-word;

      &--last {
        padding-bottom: 8px;
      }

      div + div {
        margin-top: 2px;
      }
    }
  }
}
