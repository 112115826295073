@import "./../../../sass/variables.module.scss";

.probill-preview-content {
  display: flex;
}

.probill-preview {
  &__loading {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }

  &__content {
    flex: 1 1 auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    gap: 12px;
    align-items: center;

    &__icon {
      width: 32px;
      height: 32px;
      color: $blue1;
      background-color: $supporting_blue_16;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }

    &__title {
      line-height: 24px;
      font-family: "Roboto-Bold";
      font-size: 18px;
    }

    &__status {
      line-height: 16px;
      font-size: 12px;
      color: $gray_72;
    }
  }

  &__section {
    padding: 24px 0;

    &:not(:first-child) {
      border-top: 1px solid $gray_32;
    }

    &__heading {
      font-family: "Roboto-Bold";
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &__item {
      display: flex;
      overflow: hidden;

      &__label {
        margin-right: 8px;
        font-size: 14px;
        line-height: 20px;
        color: $gray_72;
        display: flex;
        align-items: center;
      }

      &__value {
        max-width: 181px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}