@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-shipment-details__claim-numbers {
  display: inline-block;
  max-width: 900px;
  background: $gray7;
  color: $gray1;
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: $border-radius;
  font-size: 14px;
  line-height: 1.4;

  &__icon {
    color: $orange;
    font-size: 16px;
    margin-right: 8px;
  }
}