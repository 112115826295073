@import "./../../../sass/variables.module.scss";

.react-datepicker__day {
  font-size: 14px;

  &:not(.react-datepicker__day--keyboard-selected):not(.react-datepicker__day--selected):not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--selecting-range-end):not(.react-datepicker__day--disabled) {
    &:hover {
      border-radius: 4px;
      outline: 1px solid rgba(79, 95, 114, 0.32);
      background: rgba(79, 95, 114, 0.08);
    }
  }

  &-name {
    font-size: 14px;
  }
}
