@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-pickup-details {
  font-size: 14px;
  margin-bottom: 52px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__pickup-number {
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
  }

  &__probill-number {
    display: inline-flex;
    margin-left: 12px;
    color: #828282;
    font-size: 18px;
    line-height: 24px;

    &__text {
      margin-right: 4px;
    }

    &__link {
      cursor: pointer;
    }
  }

  &__status {
    color: $gray3;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
  }

  &__subheader {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__action {
    display: flex;
    align-items: center;
    color: #2F80ED;
    cursor: pointer;

    &:hover {
      color: #125BBE;
    }

    &__icon {
      display: flex;
      margin-right: 8px;
    }

    &__text {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__pickup-date {
    display: flex;
    align-items: center;

    &__label {
      margin-right: 11px;
    }

    &__icon {
      display: block;
      margin: 2px 7px 0 0;
    }
  }

  &__rescheduled {
    border-radius: $border-radius;
    border: 1px solid #C43232;
    background: rgba(221, 63, 63, 0.20);
    padding: 16px;
    margin-bottom: 24px;
    color: #C43232;

    &__header {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 12px;

      img {
        margin-right: 16px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 20px;
    }

    &__button {
      margin-top: 10px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 16px;

    &__item {
      width: calc(50% - 8px);

      @include up-to(601px) {
        width: 100%;
      }
    }
  }

  &__section {
    margin-bottom: 24px;

    &__line {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    &__icon {
      border-radius: 24px;
      background: rgba(47, 128, 237, 0.20);
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__header {
      color: black;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin-left: 16px;
    }

    &__content {
      margin-left: 48px;

      &__line {
        margin-bottom: 16px;
      }
    }
  }

  &__value-icon {
    margin-right: 4px;

    img {
      display: block;
    }
  }

  &__value-spinner {
    display: inline-block;
    margin: 0 8px;

    .xgs-loading {
      margin-left: 0;
    }
  }

  &__document {
    cursor: pointer;
    text-align: center;

    img {
      display: block;
      margin-bottom: 4px;
      border: 0;
      text-decoration: none;
    }

    a {
      color: #2F80ED;
    }
  }

  &__items {
    &__header {
      color: $gray3;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    &__row {
      border-radius: $border-radius;
      border: 1px solid $gray5;
      padding: 16px;
      margin-bottom: 8px;

      &__line {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        span {
          color: #828282;
        }

        &--flex {
          display: flex;
          gap: 12px;
          flex-wrap: wrap;
        }
      }
    }
  }

  &__block {
    /* reusable block - should be moved to a separate stylesheet if it will be used elsewhere */
    height: 100%;

    &__header {
      color: $gray3;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 4px;
    }

    &__content {
      padding: 16px;
      border-radius: $border-radius;
      border: 1px solid $gray5;
      height: calc(100% - 24px);

      &--one-line {
        display: flex;
        flex-wrap: wrap;
        gap: 8px 16px;
      }

      &__pair {
        display: flex;
        align-items: center;

        @include up-to(601px) {
          flex-wrap: wrap;
        }
      }

      &:not(.xgs-pickup-details__block__content--one-line) {
        .xgs-pickup-details__block__content__pair:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      &__label {
        color: $gray3;
        margin-right: 4px;
      }

      &__value {
        color: black;
        word-break: break-word;

        span {
          color: #828282;
        }

        &--missing {
          color: #C43232;
        }
      }
    }
  }

  &__log {
    &__date {
      display: flex;
      flex-wrap: wrap;

      .xgs-pickup-details__log__gray-value {
        margin-left: 8px;
      }
    }

    &__gray-value {
      color: $gray3;
    }
  }


  &__reschedule-form {
    margin-top: 24px;

    .xgs-pickup__date {
      width: 100%;
      margin-bottom: 16px;
    }

    .xgs-pickup__time {
      width: 100%;
      margin-bottom: 24px;
    }

    .xgs-bol__return__availability__controls .xgs-labeled-input {
      width: 50%;
    }

    .xgs-modal__button {
      width: calc(50% - 8px);
    }
  }

  &__slideout {
    .xgs-slide-out-sidebar__content {
      padding: 24px 40px;
    }

    &__inner-header {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    &__text {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__buttons {
      display: flex;
      gap: 16px;
      margin-top: 24px;
      flex-wrap: wrap;

      .xgs-btn {
        width: calc(50% - 8px);

        @include up-to(500px) {
          width: 100%;
        }
      }
    }

    .xgs-form__checkbox {
      margin-top: 16px;
      font-size: 14px;

      label div {
        margin-left: 8px;
      }
    }
  }

  &__cancel {
    &__shipper-contacts {
      margin: 24px 0;
      font-size: 14px;
    }

    &__notes {
      min-height: 50px !important;
      height: 50px !important;
      resize: vertical;

      @include mobile() {
        width: 100% !important;
      }
    }
  }

  &__value {
    &--missing {
      color: #C43232;
    }
  }
}