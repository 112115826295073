@import "./../../../../sass/variables.module.scss";
@import "./../../../../sass/mixins.scss";

.xgs-reports__scheduled {
  &__send-button {
    min-width: 181px; 
  }

  &__button {
    @include up-to(540px) {      
      width: 100%;
    } 
  }

  &__form {
    width: 100%;
    max-width: 560px;
  }

  &__block {
    border-radius: 4px;
    border: 1px solid $gray_24;
    background-color: $gray_4;
    padding: 16px;
    margin-top: 16px;
  }  

  &__field-group {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    @include up-to(540px) {      
      flex-direction: column;
    }

    &__field {
      flex-grow: 1;
    }
  }  

  &__field {
    max-width: 230px;
    margin-bottom: 16px;

    &--long {
      max-width: 100%;
    }

    @include up-to(540px) {
      max-width: none;
      width: 100%;
      margin-right: 0;
    }    
  }

  &__fields {
    &__label {
      font-size: 14px;
      line-height: 16px;
      color: $gray0;
    }

    &__columns {
      display: flex;
      margin-top: $margin7;

      @include up-to(540px) {
        flex-wrap: wrap;
      }
    }

    &__column {
      &:first-child {
        margin-right: $margin6;

        @include up-to(540px) {
          margin-right: 0;
          margin-bottom: $margin7;
        }
      }

      &__header {
        font-size: 14px;
      }
    }
  }

  &__section {
    font-size: 16px;
    line-height: 19px;
    color: $gray3;
    margin: 24px 0 $margin7 0;
  }

  &__notes {
    color: $gray2;
    border-radius: $border-radius;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 16px;
  }

  &__form-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
    margin: 8px 0 72px 0;

    .xgs-btn {
      flex: 1 0 auto;

      @include up-to(540px) {
        width: 100%;
      }
    }
  }
}
