@import "../../../../sass/variables.module.scss";
@import "../../../../sass/mixins.scss";

.picklist-details {

  &__loading-task {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 20px;

      &--line {
        flex: 1;
        display: inline-block;
        height: 1px;
        background-color: #E0E0E0;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;
      margin: 12px 0 24px;
      padding: 0;

      .picklist-details__loading-task__collapsible {
        border: 1px solid #4F5F7252;
        background: #4F5F720A;

        &--status {
          &_loaded {
            border-color: #14870A52;
            background: #5DC75429;
          }

          &_in-progress {
            border-color: #2F80ED52;
            background: #2F80ED29;
          }

          &_pending {
            border-color: #4F5F7252;
            background: #4F5F720A;
          }
        }
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__identifiers {
        display: flex;
        flex-direction: row;
        gap: 20px;
        font-size: 18px;
        margin-bottom: 10px;
        flex-wrap: wrap;

        &__item {
          display: flex;
          flex-direction: row;
          gap: 8px;

          .probill-number {
            color: $blue1;
            text-decoration: none;
          }

          &__status {
            text-transform: uppercase;
            white-space: nowrap;
            color: #828282;
            background-color: rgba(130, 130, 130, 0.2);

            &__loaded {
              color: #14870A;
              background-color: rgba(20, 135, 10, 0.2);
            }

            &__in-progress {
              color: #2F80ED;
              background-color: #2F80ED29;
            }

            &__pending {
              color: #828282;
              background-color: rgba(130, 130, 130, 0.2);
            }

            @include up-to(480px) {
              font-size: 10px;
              padding: 3px 6px;
            }
          }

        }
      }

      &__info-group {
        display: flex;
        flex-direction: row;
        gap: 8px;

        &__item {

          &--text-light {
            color: #4F5F72B8;
          }

          &--col1 {
            width: 40%;
          }

          &--col2 {
            width: 60%;
          }
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &__list-item {
      position: relative;
      display: inline-flex;
      align-items: center;
      width: 100%;
      gap: 24px;
      padding-top: 16px;
      border-top: 1px solid #E0E0E0;

      @include up-to(420px) {
        flex-wrap: wrap;
        gap: 8px;
      }

      >span {
        color: #828282;

        >b {
          color: #000000
        }
      }
    }

    &__picklist-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &--text-light {
        color: #4F5F72B8;
      }

      &__title {
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-size: 18px;

        &__seq-no {
          width: 25px;
          height: 25px;
          border-radius: 4px;
          border: 1px #BDBDBD solid;
          text-align: center;
          background-color: #ececec;
        }
      }

      &__base-properties {
        display: flex;
        flex-direction: row;
        gap: 15px;
        flex-wrap: wrap;

        &__item {
          display: flex;
          flex-direction: row;
          gap: 5px;
        }
      }

      &__is-show-details {
        color: $blue1;
      }

      &__additional-details {
        margin-left: -40px;

        &__ele {
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin-bottom: 20px;

          &__title {
            color: #4F5F72B8;

            &__icon {
              font-size: 10px;
              margin-right: 5px;
            }
          }

          &__content {
            display: flex;
            flex-direction: row;
            gap: 20px;
            flex-wrap: wrap;

            &__pair {
              display: flex;
              flex-direction: row;
              gap: 10px;
              flex-wrap: wrap;
              min-width: 20px;
            }
          }
        }
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin: 10px 0px;
      gap: 10px;

      &__download-pdf-button {
        width: fit-content;
      }

      &__edit-button {
        width: fit-content;
      }
    }

    &__group {
      display: flex;
      flex-direction: column;

      &--1 {
        width: 30%;
      }

      &--2 {
        width: 70%;
      }
    }

    &__info {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      gap: 24px;
      margin: 16px 0;
      padding: 16px;
      text-decoration: none;
      border-radius: 8px;
      border: 1px solid #E0E0E0;

      @include mobile() {
        flex-direction: column;
        align-items: start;
        gap: 8px;
        margin: 0;
      }

      .header-info-item {
        display: flex;
        align-items: center;
        padding: 8px 0;
        font-family: Roboto, sans-serif;
        font-size: 14px;

        &__title {
          color: #4F5F72B8;
          padding-right: 8px;
        }

        &__progress-bar {
          width: 60%;
        }

        .--late-loading {
          color: $red;
        }

        &--progress {
          width: 60%;
          padding-right: 0px;
        }
      }
    }
  }
}