@import "./../../../../sass/variables.module.scss";
@import "./../../../../sass/mixins.scss";

.xgs-invoice-records {
  width: 100%;
  max-width: 895px;
  min-height: 80px;

  &__loading {
    padding: 24px;
    text-align: center;
  }

  &__error {
    color: $red;
    font-size: 14px;
    margin: $margin7 $margin6 $margin6 $margin6;
    text-align: center;
  }

  &__no-items {
    color: $gray3;
    font-size: 14px;
    padding: 32px !important;
    line-height: 1;
    text-align: center;
  }

  &__items {
    margin-top: $margin8;
    margin-left: $margin6;
    color: $gray2;
    font-size: 14px;
  }

  &__item {
    position: relative;
    margin-bottom: $margin6;

    &:not(:last-child) {
      border-bottom: 1px solid $gray4;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;  

      &__header {
        width: 140px;
        font-weight: bold;

        @include up-to(450px) {
          width: 100%;
        }
      }

      &__value {
        word-break: break-word;
        width: calc(100% - 140px);

        @include up-to(450px) {
          width: 100%;
        }
      }
    }

    &__photos {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;      
      width: 100%;
      max-width: 600px;
      margin-bottom: 16px;
    }
  }

  &__photo {
    display: flex;
    border-radius: $border-radius;
    margin-bottom: 8px;

    @include up-to(800px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    &:not(:nth-child(4n+4)) {
      margin-right: 8px;

      @include up-to(660px) {
        margin-right: 0;
      }
    }

    &:not(:nth-child(3n+3)) {
      @include up-to(660px) {
        margin-right: 8px;
      }

      @include up-to(604px) {
        margin-right: 0;
      }
    }

    &:not(:nth-child(even)) {
      @include up-to(604px) {
        margin-right: 8px;
      }
    }

    &__preview {
      position: relative;
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      border: 1px solid $gray5;
      border-radius: $border-radius;
      background-color: white;
      width: 129px;
      height: 129px;
      cursor: pointer;

      @include up-to(604px) {
        width: 160px;
        height: 160px;
      }

      @include up-to(560px) {
        width: 142px;
        height: 142px;
      }

      @include up-to(500px) {
        width: 124px;
        height: 124px;
      }

      @include up-to(440px) {
        width: 120px;
        height: 120px;
      }

      @include up-to(374px) {
        width: 180px;
        height: 180px;
      }

      &__image {
        display: block;
        max-width: 100%;
        height: auto;
        flex-grow: 0;
        flex-shrink: 0;
      }

      &__grid-comments {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        background-color: $gray7;
        border-bottom-left-radius: $border-radius;
        padding: 4px 6px;
        width: 16px !important;
        height: 16px !important;
        box-sizing: content-box;

        &__icon {
          font-size: 16px;
          color: $orange;
        }
      }
    }

    &__meta {
      @include up-to(800px) {
        width: 100%;
        margin-top: 8px;
      }
    }

    &__comments {
      word-break: break-word;
    }

    &__internal-only {
      display: inline;
      padding: 4px 6px;
      background-color: $gray5;
      border-radius: $border-radius;
      line-height: 1;
      font-size: 14px;
    }

    &__internal-only + .xgs-invoice-records__photo__comments {
      margin-top: 8px;
    }
  }
}