@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

$breakpoint: 1465px;

.xgs-dock-loading {
  @include responsiveTable($breakpoint);

  margin-bottom: 24px;

  &__toolbar {
    @include up-to(710px) {
      flex-wrap: wrap;
      gap: 16px;
    }

    .xgs-site__content-container__toolbar__buttons {
      margin-left: 24px;

      @include up-to(710px) {
        margin-left: 0;
      }


      @include up-to(640px) {
        flex-wrap: wrap;
      }
    }

    .xgs-btn {
      min-width: fit-content;

      &__spinner {
        width: 20px;
        height: 20px;
      }

      &:disabled {
        img {
          filter: grayscale(1);
          opacity: 0.5;
        }
      }
    }
  }

  .xgs-summary__tile__icon {
    border-radius: 4px;
  }

  .tr {
    align-items: center;
  }

  .xgs-list__controls__search {
    & > form {
      @include up-to($breakpoint) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }

      @include mobile {
        grid-template-columns: 1fr;
      }
    }

    .xgs-item-form__field {
      flex: 1 1 150px;

      @include up-to($breakpoint) {
        max-width: 100%;
      }
    }

    .xgs-list__controls__buttons-group {
      display: flex;
      gap: 16px;
    }

    .xgs-btn {
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 24px;

      @media mobile {
        margin-top: 0;
      }

      &:not(.xgs-list__controls__clear-button) {
        width: 100%;

        @media (min-width: 768px) {
          width: 140px;
        }
      }
    }
  }

  &__update-notice {
    color: rgba(79, 95, 114, 0.56);
    font-size: 14px;
    line-height: 20px;
  }

  &__summary {
    margin-bottom: 24px;
    padding-right: 40px;
    max-width: none;
    width: 100%;

    @include mobile {
      padding-right: 0;
    }

    .xgs-summary {
      overflow-x: auto;
    }
  }

  &__list {
    width: 100%;
    line-height: 20px;
  }

  &__distributor-number {
    color: rgba(79, 95, 114, 0.72);
  }

  &__at-risk {
    color: #E82A2B;
    font-weight: 600;

    &--zero {
      color: #777777;
    }
  }

  &__header {
    font-size: 18px;
    line-height: 24px;
  }

  &__door-details {
    .xgs-dock-loading__header {
      margin-bottom: 12px;

      &--bold {
        font-weight: 600;
      }
    }

    &__summary {
      padding: 16px;
      margin: 24px 0;
      background: white;
      border-radius: 4px;
      border: 1px solid rgba(79, 95, 114, 0.32);

      .xgs-dock-loading__door-details__values-line {
        gap: 8px 16px;
        margin-bottom: 8px;

        img {
          margin-right: 8px;
        }
      }
    }

    &__collapsible {
      border: none;
      padding: 0;
      width: 100%;

      &--drop {
        margin-bottom: 16px;
      }

      &--dispatched.collapsible {
        > .collapsible__header {
          border: 1px solid rgba(20, 135, 10, 0.32);
          background: rgba(93, 199, 84, 0.08);

          .collapsible__button-icon path {
            fill: #14870A;
          }
        }
      }

      &--loaded.collapsible {
        > .collapsible__header {
          border: 1px solid rgba(151, 71, 255, 0.32);
          background: rgba(151, 71, 255, 0.08);

          .collapsible__button-icon path {
            fill: #7936D0;
          }
        }
      }

      &--inprogress.collapsible {
        > .collapsible__header {
          border: 1px solid rgba(47, 128, 237, 0.32);
          background: rgba(47, 128, 237, 0.08);

          .collapsible__button-icon path {
            fill: #2F80ED;
          }
        }
      }

      &--probill.collapsible {
        > .collapsible__header {
          border: 1px solid rgba(79, 95, 114, 0.32);
          background: rgba(79, 95, 114, 0.04);
        }
      }

      .collapsible__header {
        padding: 16px 14px 16px 16px;
        border: 1px solid #E0E0E0;
        border-radius: $border-radius;
      }

      .collapsible__button {
        margin-left: 12px;
      }

      &.collapsible--open > .collapsible__content {
        border-left: 4px solid rgba(79, 95, 114, 0.16);
        padding-left: 8px;
        margin-top: 10px;
        max-width: 100%;
        flex-wrap: wrap;
        gap: 8px;
      }
    }

    &__probill {
      &__header-line {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        border-bottom: 1px solid rgba(79, 95, 114, 0.32);
        padding-bottom: 12px;
        margin-bottom: 12px;

        .xgs-dock-loading__door-details__header-line {
          margin-bottom: 0;
        }
      }

      &__values {
        &__line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          line-height: 20px;
          font-size: 14px;

          div:first-child {
            color: rgba(79, 95, 114, 0.72);

            @include up-to(640px) {
              width: 100%;
              font-size: 12px;
            }
          }

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }

    &__header-line {
      display: flex;
      gap: 16px;
      margin-bottom: 8px;
      line-height: 20px;
    }

    &__values-line {
      display: flex;
      flex-wrap: wrap;
      gap: 4px 16px;
      line-height: 20px;
      font-size: 14px;

      span {
        color: rgba(79, 95, 114, 0.72);
      }

      img {
        vertical-align: middle;
      }
    }

    &__items {
      border-radius: $border-radius;
      border: 1px solid rgba(79, 95, 114, 0.32);
      background: white;
      max-width: 100%;
      word-break: break-word;
      padding: 0 16px;

      &__details {
        padding: 16px 0;

        &:not(:first-child) {
          border-top: 1px solid rgba(79, 95, 114, 0.32);
        }

        &__show-more-link {
          margin-top: 8px;
          color: $blue1;
          font-size: 16px;
          line-height: 20px;
          width: fit-content;
          cursor: pointer;
        }

        &__more {
          &__header {
            color: rgba(79, 95, 114, 0.72);
            margin-bottom: 4px;
            font-size: 14px;
          }

          ul {
            padding-left: 16px;
          }

          li {
            line-height: 20px;
            margin-bottom: 8px;

            &::marker {
              color: rgba(79, 95, 114, 0.72);
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  &__involved-users {
    .xgs-dock-loading__header {
      margin-bottom: 24px;
    }
  }
}

.xgs-list--full-width .xgs-dock-loading .xgs-list__controls {
  margin-bottom: 16px;
}
