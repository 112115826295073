@import "../../../../sass/mixins.scss";


.create-late-reasons {
  .selected-probills-summary {
    display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: center;
    gap: 24px;
    border-radius: 4px;
    background: var(--White, #FFF);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
    margin-bottom: 10px;
    transition: margin 0.5s ease-in-out;
    position: fixed;
    z-index: 1;
    bottom: 10px;
    width: 80%;

    &__titile {
      color: var(--Gray, #828282);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .weather-clear-date {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;

    &__input {
      width: 40%;
    }

    &__tip {
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px;
      width: 60%;
      color: #B89311;
      background-color: #FAF6DE;

      &__icon {
        margin-right: 5px;
      }
    }
  }

  .submit-button {
    margin-top: 10px;
    text-align: center;
  }
}