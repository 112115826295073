@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-popup {
  overflow-y: auto;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-family: "Roboto-Bold";
    font-size: 20px;
  }

  &__close {
    width: 24px;
    height: 24px;
    color: $gray4;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      color: $gray3;
    }
  }

  &__content {
    padding: $margin6;

    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.xgs-popup--theme_new {
  .xgs-popup__header {
    padding: 24px 24px 0;
    position: relative;
  }

  .xgs-popup__close {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .xgs-popup__title {
    font-family: "Roboto-Regular";
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .xgs-popup__content {
    padding: 0 24px 24px;
    max-width: 400px;
  }
}