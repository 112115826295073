@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-table__selected-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;  
  width: calc(100% - $side-menu-width-desktop);
  left: $side-menu-width-desktop;
  bottom: 0;
  pointer-events: none;
  
  @include tablet {
    width: 100%;
    left: 0;
  }

  @include mobile {
    width: 100%;
    left: 0;
  }

  &__bar {
    width: 100%;
    max-width: 960px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 24px;
    transition: margin-bottom 0.5s ease-in-out;
    display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: center;
    gap: 24px;
    border-radius: 4px;
    background: var(--White, #FFF);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
    pointer-events: auto;

    @include mobile {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      padding-bottom: 24px;
    }
  }
}