@import "./../../../sass/mixins.scss";

.xgs-plan-probills{
  &__table{
    margin-bottom: 116px;  
  }
}
.xgs-plan-probills__terminal-selction {
  max-width: 320px;
  margin-bottom: 20px;
  margin-top: -25px;
}

.xgs-plan-probills__terminal-selction-message {
  color: red;
}

.plan-probills-tags-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.plan-probills-tag-conatiner {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
}
.plan-probills-tag {
  display: flex;
  gap: 4px
}
