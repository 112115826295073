@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-standing-appointment {
  margin-bottom: 140px;
  &__autofill {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    background-color: $gray7;
    width: 367px;
    font-size: 14px;

    @include up-to(550px) {
      width: 300px;
    }

    button {
      height: 24px;
      font-size: 14px;
    }
  }
  &__heading {
    font-size: 16px;
    margin-bottom: $margin8;
  }

  &__notes {
    color: $gray3;
    font-size: 14px;
    margin-bottom: $margin8;
  }

  &__submit-buttons {
    display: flex;
    gap: $margin8;
    flex-wrap: wrap;
    margin-bottom: $margin6;
    margin-top: $margin6;

    button {
      @include up-to(450px) {
        width: 100%;
      }
    }
  }

  &__day-block {
    display: flex;
    justify-content: flex-start;
    max-width: 450px;
    gap: 16px;
    margin-bottom: $margin7;
    margin-top: $margin7;
    align-items: center;
    align-self: stretch;
    font-size: 14px;

    @include up-to(550px) {
      gap: 8px;
    }

    &__time-container {
      display: flex;
      gap: 10px;
    }

    &__time-input {
      width: 100px;

      .xgs-select {
        &__menu {
          width: 110px;
        }
      }
    }

    &__switch-container {
      width: 32px;
      svg {
        width: 32px;
      }
    }

    &__service-day {
      font-size: 18px;
      line-height: 32px;
    }

    &--closed {
      color: $gray4;
    }

    &__seperator {
      width: 11px;
      height: 1.5px;
      background-color: blue;
      border: none;
      background-color: black;
      margin-top: 15px;

      @include up-to(550px) {
        display: none;
      }
    }

    &--non-editable {
      margin-bottom: 22px;
      align-items: center;
      align-self: stretch;
      max-width: 365px;
      
      .xgs-standing-appointment__day-block__seperator {
        margin-top: 0px;
      }
      
      .xgs-standing-appointment__day-block__time {
        background-color: $gray7;
        display: flex;
        height: 32px;
        padding: 6px 16px;
        justify-content: center;
        align-items: center;
        gap: 114px;
        flex: 1 0 0;
        font-weight: bold;
      }

      .xgs-standing-appointment__day-block__day-label {
        margin-top: 0;
        input {
          display: none;
        }
      }

      .xgs-standing-appointment__day-block__service-day {
        width: 20px;
      }
    }

    &__day-label {
      display: flex;
      justify-content: space-between;

      &--large {
        width: 80px;
        @media (max-width: 550px) {
          display: none
        }
      }

      &--small {
        width: 35px;
        @media (min-width: 550px) {
          display: none
        }
      }
      
      &__no-service {
        line-height: 20px;
        margin-right: 5px;
      }
    }

    &__closed-text {
      display: flex;
      height: 32px;
      padding: 6px 16px;
      justify-content: center;
      align-items: center;
      gap: 114px;
      flex: 1 0 0;
      background-color: $gray7;
      font-weight: bold;
      color: $gray3;
    }
  }

  &__audit {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    label {
      margin-right: 5px;
      font-weight: bold;
    }
  }
}
