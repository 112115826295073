@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-terminal-selector {
  margin-left: 40px;

  @include up-to(600px) {
    margin-left: 0;
  }

  &__control {
    display: flex;
    align-items: center;
    z-index: 3;

    &__content {
      display: flex;
      align-items: center;
      margin-right: $margin7;
      gap: 8px;
      max-width: 260px;
      max-height: $header-height;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        background: rgba(47, 128, 237, 0.20);
      }

      &__header {
        color: #828282;
        font-size: 12px;
        line-height: 16px;
      }

      &__terminal {
        color: #000;
        font-size: 14px;
        line-height: 20px;

        @include mobile {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 140px;
        }
      }

      @include tablet {
        max-width: 220px;
      }

      @include mobile {
        max-width: 180px;
        margin-right: $margin8;
      }      
    }

    &__arrow {
      font-size: 14px;
      color: $gray1;
      line-height: 17px;

      @include mobile {
        font-size: 12px;
      }
    }
  }

  &__list {
    &__header {
      color: #828282;
      font-size: 14px;
      line-height: 20px;
      padding: 0 20px;
      margin-bottom: 6px;
    }

    &__filter {
      padding: 6px 16px 4px 16px;
    }

    &__items {
      display: flex;
      flex-direction: column;
      max-height: calc(50vh - 70px);
      overflow-y: auto;
    }

    &__item {
      display: flex;
      align-items: center;

      padding: 6px 20px;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        background: #F8F8F8;
      }

      &--selected {
        background: #F2F2F2;
      }

      &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include mobile {
          flex-grow: 1;
        }
      }

      &__name {
        display: block;
        max-width: 230px;
        word-break: break-word;
        padding-right: 8px;

        @include mobile {
          max-width: calc(100% - 42px);
        }
      }

      &__id {
        display: block;
        color: #828282;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.xgs-terminal-selector__list-content.popup-content {
  width: 274px !important;
  max-height: 50vh;
  padding: 18px 0px 12px 0px !important;

  @include up-to(375px) {
    width: 100% !important;
  }
}
