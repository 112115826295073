@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-shipment-details__claims-documents {
  width: 100%;
  max-width: 895px;
  font-size: 14px;
  padding: 8px 16px;

  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
      padding-bottom: 8px;
      border-bottom: 2px solid $gray5;
    }
  }

  &__claim-number {
    margin-bottom: 8px;
    color: $gray2;
  }

  &__documents {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;

    .xgs-claims__details__documents__item {
      margin-bottom: 8px;
    }
  }
}
