@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-bol {
  &__list {
    &__feedback {
      display: inline-flex;
      align-items: center;
      border-radius: $border-radius;
      background-color: $gray7;
      padding: 8px 16px;

      @include up-to(810px) {
        margin-bottom: 16px;
      }

      @include up-to(440px) {
        font-size: 14px;
      }

      &__icon {
        font-size: 24px;
        color: $gray5;
        margin-right: 16px;
      }
    }

    &__top {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: -24px;
      margin-bottom: $margin7;
      max-width: 1415px;

      @include up-to(540px) {
        margin-top: -8px;
      }

      &__filter {
        min-width: 300px;

        @include up-to(540px) {
          min-width: 0;
          width: 100%;
        }
      }

      &__buttons {
        margin-right: 16px;

        @include up-to(810px) {
          width: 100%;
          margin-bottom: 16px;
          margin-right: 0;
        }

        .xgs-btn {
          width: 180px;

          @include up-to(540px) {
            width: 100%;
          }
        }

        .xgs-btn + .xgs-btn {
          margin-left: 16px;

          @include up-to(540px) {
            margin-left: 0;
            margin-top: 8px;
          }
        }
      }
    }

    &__download-csv {
      position: absolute;
      top: 60px;
      left: 1358px;

      @include up-to(1680px) {
        left: auto;
        right: 24px;
      }

      @include up-to(769px) {
        right: 24px;
      }

      @include up-to(400px) {
        position: relative;
        top: auto;
        right: auto;
        margin-bottom: 24px;
        text-align: center;
      }

      &__spinner {
        margin-left: -4px !important;
      }
    }
  }

  &__details {
    &__block {
      border-radius: $border-radius;
      border: 1px solid $gray5;
      padding: 16px;
      margin-bottom: 24px;

      &__header {
        display: flex;
        align-items: center;

        &__icon {
          margin-right: 8px;
        }

        &__text {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }

    &__probill,
    &__pickup-number {
      font-size: 14px;
      font-weight: 400;
      color: $gray3;
      line-height: 20px;
    }

    &__pickup-number {
      margin-left: 4px;
    }

    &__pickup-date {
      font-size: 14px;
      line-height: 20px;
    }

    &__pickup-canceled {
      border-radius: $border-radius;
      border: 1px solid #C43232;
      background: rgba(221, 63, 63, 0.16);
      padding: 16px;
      color: #C43232;
      font-size: 14px;
      line-height: 20px;
      margin-top: 16px;
      display: flex;
      align-items: center;

      &__icon {
        margin-right: 16px;

        img {
          display: block;
        }
      }
    }

    &__controls {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      align-items: center;      

      .xgs-btn {
        width: fit-content;

        @include up-to(500px) {
          width: 100%;
        }
      }
    }
  }

  &__create-wizard {
    width: 100%;
    max-width: 410px;
    margin-bottom: $margin6;

    &--return {
      display: flex;
      align-items: flex-start;
      margin-bottom: $margin3;
      max-width: none;

      @include up-to(620px) {
        flex-wrap: wrap;

        .xgs-steps-milestones {
          margin-top: 24px;
        }
      }

      .xgs-form__field__notes {
        &--contact {
          margin-top: -16px;
        }

        &--mobile {
          display: none;

          @include up-to(700px) {
            display: block;
          }
        }

        &--desktop {
          @include up-to(700px) {
            display: none;
          }
        }
      }
    }

    .xgs-steps-milestones__item__data span {
      font-weight: bold;
      letter-spacing: 0.2px;
    }
  }

  &__consignee__warning {
    display: flex;
    align-items: center;
    font-size: 15px;

    &__icon {
      font-size: 48px;
      margin-right: 24px;
    }
  }

  &__section {
    font-size: 16px;
    line-height: 19px;
    color: $gray3;
    margin-bottom: $margin7;
  }

  &__field {
    position: relative;
    margin-bottom: 30px;
    max-width: 410px;

    &--min-width-200 {
      min-width: 200px;
    }

    &--gray-bg {
      padding: 8px;
      background-color: $gray7;
      border-radius: $border-radius;
    }

    &--short {
      width: 200px;

      @include up-to(540px) {
        width: 100%;
      }
    }

    &--zip {
      width: 126px;

      @include up-to(700px) {
        width: 100%;
      }
    }
  }

  &__fields-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 410px;
    justify-content: space-between;

    &__fixed-item {
      flex-grow: 0;
    }

    &--halves {
      flex-wrap: nowrap;

      @include up-to(700px) {
        flex-wrap: wrap;
      }

      .xgs-bol__field {
        flex-grow: 1;
        flex-shrink: 1;

        @include up-to(700px) {
          width: 100%;
        }
      }

      .xgs-bol__field + .xgs-bol__field {
        margin-left: 16px;

        @include up-to(700px) {
          margin-left: 0;
        }
      }
    }

    &__state {
      min-width: 140px;
      flex-grow: 1;
      margin-right: 16px;

      @include up-to(700px) {
        margin-right: 0;
        width: 100%;
      }
    }
  }

  &__checkbox {
    &--field {
      margin-bottom: 24px;
      font-size: 14px;

      div {
        margin-left: 8px;
      }

      span {
        color: $red;
      }
    }
  }

  &__shipper {
    margin-bottom: 48px;

    .xgs-exclamation {
      width: 580px;
    }

    .xgs-bol__return__availability__controls__delimiter {
      margin: 8px 4px 0 4px;
    }

    .xgs-pickup__date {
      min-width: 166px;
      width: 166px;
      flex-shrink: 0;
    }

    .xgs-pickup__time {
      @include up-to(600px) {
        margin: 0 0 16px 0;
      }
    }

    .xgs-notification-block {
      margin-top: -12px;
      margin-bottom: 16px;
    }

    .xgs-bol__return__availability {
      margin-bottom: 0;
    }
  }

  &__consignee {
    max-width: 410px;
  }

  &__consignee,
  &__payor {
    .xgs-form__field__notes {
      margin: -22px 0 0 0;
    }
  }

  &__no-pickup {
    margin: -24px 0 24px 0;
    padding: 8px 11px;
    background-color: $gray6;
    border-radius: $border-radius;
    display: flex;
    align-items: center;

    &__icon {
      font-size: 22px;
      margin-right: 12px;
      color: $gray3;
    }

    &__text {
      font-size: 14px;
      color: $gray2;
    }
  }

  &__pickup-request {
    &--loading {
      font-size: 14px;
    }
  }

  &__buttons {
    margin-top: 40px;
  }

  &__items {
    .xgs-bol__section {
      padding-bottom: 5px;
    }

    &__item {
      width: 418px;
      padding: 14px 18px 14px 14px;
      margin-top: 24px;
      background-color: $gray6;
      font-size: 14px;
      line-height: 16px;

      @include up-to(540px) {
        width: 100%;
      }

      &__columns {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__description {
        max-width: 304px;
        margin-bottom: 7px;
      }

      &__property {
        color: $gray3;
        word-break: break-all;
        padding-right: 8px;

        & + .xgs-bol__items__item__property {
          margin-top: 7px;
        }
      }

      &__action {
        line-height: 20px;
      }
    }

    &__add {
      margin-top: 24px;
      font-size: 14px;
      line-height: 16px;
    }

    &__link {
      color: $blue1;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }      
    }

    &__limitation {
      margin-top: 40px;
      font-size: 14px;
      color: $gray3;
    }
  }

  &__item-form {
    box-sizing: border-box;
    max-width: 418px;
    width: 100%;
    height: 100%;
    border: 1px solid $gray4;
    padding: 16px 29px 16px 16px;
    margin-top: 24px;

    &__button {
      & + .xgs-bol__item-form__button {
        margin-left: $margin7;

        @include up-to(540px) {
          margin-left: 0;
          margin-top: $margin7;
        }
      }

      @include up-to(540px) {
        width: 100%;
      }
    }

    &__row {
      display: flex;

      &--spaced {
        justify-content: space-between;
      }

      .xgs-labeled-input {
        min-height: 88px;
      }

      @include up-to(460px) {
        flex-wrap: wrap;
      }
    }

    &__field {
      width: 100%;
     
      &--small {
        width: 112px;

        @include up-to(460px) {
          width: 100%;
        }      
      }
    }

    &__checkbox {
      margin-bottom: 30px;         

      &__label {
        margin: 5px 0 5px 8px;
      }
    }

    &__misc-text {
      font-size: 14px;
      line-height: 1.5;    
      margin-bottom: 16px;
      color: $gray1;

      ul {
        margin-top: 0;
        padding-left: 16px;
      }

      li + li {
        margin-bottom: 8px;
      }
    }

    &__freight-notes {
      font-size: 12px;
      margin: -24px 0 16px 8px;
      color: $gray3;
    }
  }

  &__nav-button {
    width: 160px;

    & + .xgs-bol__nav-button {
      margin-left: $margin7;

      @include up-to(540px) {
        margin-left: 0;
        margin-top: $margin7;
      }
    }

    @include up-to(540px) {
      width: 100%;
    }
  }

  &__address-info {
    position: relative;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 1.2;

    @media (max-width: 510px) {
      margin-left: 0;
      width: 100%;
    }

    .xgs-bol__field {
      margin-bottom: 16px;
    }
  }  

  &__return {
    &__field {
      margin-bottom: 30px;

      &--short {
        width: 197px;

        @include up-to(540px) {
          width: 100%;
        }
      }
    }

    &__checks {
      &__role {
        &__row {
          font-size: 14px;

          label {
            display: block;
            margin: 0 0 8px 0;
          }
        }
      }
    }

    &__shipper {
      &__description {
        line-height: 1.5;
        margin-bottom: 8px;
        font-size: 14px;
        max-width: 600px;
      }
    }

    &__step {
      max-width: 410px;
      flex-grow: 1;

      &--wide {
        max-width: 800px;
      }

      .xgs-form__address-block .xgs-bol__return__field-notes {
        margin-top: 0;
      }

      .xgs-bol__address-info .xgs-bol__return__field-notes {
        padding-right: 36px;
      }
    }

    &__field-notes {
      font-size: 12px;
      margin: -24px 0 16px 0;
      color: $gray3;

      &--list {
        margin: -16px 0 24px 0;

        ul {
          padding-left: 16px;
          margin: 0;
        }
      }

      &--desktop {
        display: block;

        @include up-to(850px) {
          display: none;
        }
      }

      &--mobile {
        display: none;

        @include up-to(850px) {
          display: block;
        }
      }
    }

    &__reference {
      &__file-uploading {
        font-size: 14px;
        padding-top: 4px;
        height: 34px;

        span {
          margin-left: 8px;
        }
      }

      &__file {
        display: flex;
        padding: 7px 8px 6px 8px;
        border: 1px solid $gray4;
        border-radius: $border-radius;
        background-color: $gray7;
        color: $gray1;
        justify-content: space-between;
        width: 410px;
        min-height: 34px;

        @include up-to(540px) {
          width: 100%;
        }

        &__name {
          margin-right: 16px;
          word-break: break-word;
          font-size: 14px;
          line-height: 18px;
        }

        &__delete {
          cursor: pointer;
          color: $red;
          font-size: 16px;
        }
      }
    }

    &__modal {
      &__buttons {
        .xgs-modal__button {

          &:not(:last-child) {
            margin-right: 16px;

            @include up-to(540px) {
              margin-right: 0;
            }
          }
        }
      }

      &__button--wide {
        width: 210px;

        @include up-to(540px) {
          width: 100%;
        }
      }
    }

    &__pickup-row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 24px;

      @include up-to(850px) {
        margin-bottom: 4px;
      }

      &__date {
        width: 120px;
        flex-grow: 1;
        margin-right: 16px;

        @include up-to(850px) {
          width: 160px;
          flex-grow: 0;
          margin-right: 0px;
          margin-bottom: 8px;
        }

        @include up-to(700px) {
          width: 100%;
        }
      }

      .xgs-bol__return__field-notes--mobile {
        width: 100%;
        margin: 8px 0 28px 0;
      }
    }

    &__availability {
      flex-shrink: 0;
      margin-bottom: 8px;

      &__label {
        font-size: 14px;
        line-height: 24px;

        span {
          color: $red;
        }
      }

      &__controls {
        display: flex;

        &__delimiter {
          margin: 8px 8px 0 8px;
        }

        .xgs-labeled-input {
          width: 108px;

          @include up-to(1050px) {
            font-size: 14px;
            width: 95px;
          }

          @include up-to(850px) {
            font-size: 16px;
            width: 120px;
          }
        }
      }
    }

    &__rates {
      &__result {
        margin: 32px 0;
        font-size: 14px;
        min-width: 420px;

        @include up-to(450px) {
          min-width: 0;
        }

        span {
          color: $gray2;
          font-weight: bold;
          font-size: 16px;
        }

        &__notes {
          margin-top: 16px;
          color: $gray3;
        }
      }
    }

    &__services {
      position: relative;

      label {
        display: flex;
        margin-bottom: 12px;
      }

      &__help {
        position: absolute;
        top: -28px;
        right: 10px;
        color: #828282;
        cursor: pointer;
      }

      &__name {
        margin-left: 16px;
        font-size: 14px;
        line-height: 16px;
        max-width: 254px;
        margin-top: 4px;
      }

      &__description {
        margin-top: 5px;
        font-size: 14px;
        line-height: 16px;
        color: $gray3;        
      }
    }

    &__request-error__buttons {
      .xgs-btn.xgs-bol__nav-button {
        @media only screen and (min-width: 540px) and (max-width: 560px) {
          width: 140px;
        }
      }
    }

    &__completed {
      line-height: 1.4;

      &__text {
        font-size: 18px;

        @include up-to(450px) {
          font-size: 16px;
        }
      }

      .xgs-bol__list__feedback {
        max-width: 430px;
        color: $gray2;
        margin-top: 16px;

        &__text {
          padding-right: 48px;

          @include up-to(470px) {
            padding-right: 0;
          }
        }
      }

      &__share {
        font-size: 14px;
        color: $gray2;
        max-width: 420px;
        margin-top: 32px;
        word-break: break-word;
      }
    }

    &__share {
      margin-top: 16px;

      &__row {
        display: flex;
        align-items: flex-start;

        .xgs-bol__field {
          width: 100%;
          max-width: none !important;
          flex-shrink: 1 !important;
          margin-bottom: 0;
        }

        @include up-to(450px) {
          flex-wrap: wrap;
        }
      }

      .xgs-labeled-input.row > div {
        width: 100%;
      }

      .xgs-bol__field {
        width: 100%;
        max-width: 240px;
        flex-shrink: 0;

        @include up-to(450px) {
          max-width: none;
          margin-bottom: 16px;
        }
      }

      .xgs-btn {
        margin: 0 0 0 16px;
        min-width: 100px;

        @include up-to(450px) {
          width: 100%;
          margin: 0 0 16px 0;
        }
      }
    }
  }

  &__contact-row {
    display: flex;

    @include up-to(700px) {
      flex-wrap: wrap;
    }

    &__phone {
      width: 120px;
      margin-right: 16px;
      flex-shrink: 0;

      @include up-to(700px) {
        width: 100%;
      }
    }

    &__email {
      flex-grow: 1;

      @include up-to(700px) {
        width: 100%;
      }
    }
  }
}
