@import "./../../../sass/variables.module.scss";

.xgs-labled-information {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 21px;
    color: $gray0;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    color: $gray3;
  }
}