@import "../../../sass/variables.module.scss";
@import "../../../sass/mixins.scss";

.xgs-account-general-settings {
  margin-bottom: 100px;
  &__logs {
    &__key-cell {
      font-weight: bold;
    }
    &__diff-cell {
      white-space: pre-wrap;
      font-family: monospace;
      overflow: hidden;
      font-size: 13px;
      svg {
        font-size: 16px;
      }
      &__lhs {
        text-decoration: line-through;
        color: $gray3;
      }
    }

    &__button {
      display: block;
      float: right;
      margin-top: -10px;
      width: 90px;
    }
  }

  &__form {
    max-width: 400px;

    &__section {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: $margin7;
    }

    &__contact {
      &__field {
        margin-bottom: $margin8;
        width: 300px;
      }
      margin-bottom: $margin5;
    }

    &__info {
      margin-top: -8px;
      color: $gray3;
      font-size: 14px;
      margin-bottom: $margin8;
    }

    &__buttons {
      display: flex;
      gap: 20px;
      align-items: center;

      a {
        font-size: 16px;
        line-height: 26px;
        color: $blue1;
      }
    }
    
    &__services {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
      row-gap: $margin7;
      background-color: $gray6;
      padding: $margin7 $margin8 $margin7 $margin8;
      margin-bottom: $margin7;
      margin-top: $margin7;
      position: relative;

      @media (max-width: 460px) {
        margin-left: 0;
        grid-template-columns: 1fr;
        width: 100%;
      }

      &__help {
        position: absolute;
        top: 6px;
        right: 6px;
        color: #828282;
        cursor: pointer;
      }
    }
  }
}