@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-sc-status {
  cursor: pointer;

  @include up-to(500px) {
    width: 100%;
    max-width: calc(100vw - 48px);
  }

  &__loading {
    color: $gray3;

    &__spinner {
      margin-right: 8px;
    }
  }

  &__content {
    display: flex;    
    flex-wrap: wrap;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    min-height: 90px;
    width: 100%;
    max-width: 100%;
    color: $gray1;

    @include up-to(500px) {
      width: auto;
    }

    div {
      width: 100%;
    }

    ul {
      margin: 8px 0 0 14px;
      padding-left: 0;
      list-style-type: square;
      list-style-position: outside;
    }

    ul + div {
      margin-top: 16px;
    }

    li {
      break-inside: avoid-column;
      word-break: break-word;
      color: $gray3;
      font-size: 14px;
    }

    li + li {
      margin-top: 4px;
    }

    &__one-line-center {
      padding-top: 37px;
    }
  }
}
