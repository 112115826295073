@import "./variables.module.scss";
@import "./mixins.scss";

.xgs-modal {
  &__header {
    font-size: 18px;
    line-height: 21px;
    color: $gray1;
    margin-bottom: 20px;
  }

  &__content {
    font-size: 16px;
    line-height: 19px;
    color: $gray2;
    margin-bottom: 16px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 16px;

    @include up-to(540px) {
      flex-wrap: wrap;
    }
  }

  &__button {
    min-width: 120px;
    width: 50%;

    @include up-to(540px) {
      width: 100%;
    }
  }
}