@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-fit-to-window-container {
  overflow: auto;
  padding-right: 24px;

  @include mobile {
    padding-right: 16px;
  }
}