@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-rates {
  &__request-buttons-area {
    display: flex;
    justify-content: flex-start;

    @include up-to(540px) {
      flex-wrap: wrap;
    }
  }

  &__request-buttons {
    flex-grow: 0;
    width: 190px;
    margin-right: 16px;

    @include up-to(540px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__request-button {
    width: 190px;
    margin-bottom: $margin7;

    @include up-to(540px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__request-buttons-help {
    position: relative;
    flex-grow: 0;
    margin-top: -16px;
    color: $gray2;
    background-color: $gray7;
    line-height: 1.2;
    padding: 16px;
    border-radius: $border-radius;
    font-size: 14px;
    width: calc(900px - 206px);

    &:before {
      position: absolute;
      top: calc(50% - 8px);
      left: -12px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 13.9px 8px 0;
      border-color: transparent $gray7 transparent transparent;

      @include up-to(540px) {
        top: -16px;
        left: calc(50% - 18px);
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;        
        border-bottom: 18px solid $gray7;
      }
    }

    &__item {
      min-height: 42px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &__keyword {
        @include up-to(540px) {
          font-weight: bold;
        }
      }
    }
  }

  &__notifications-help {
    margin: 8px 0 16px 0;
    font-size: 14px;
    color: $gray3;

    @include up-to(540px) {
      padding: 0 16px;
    }
  }

  &__details {
    &__toolbar {
      max-width: 910px;

      .xgs-site__content-container-header__bar {
        flex-grow: 1;
      }

      .xgs-site__content-container-header__bar__float-buttons {
        right: 0;

        @include mobile {
          right: 8px;
        }
      }
    }

    &__create-bol {
      margin: 8px 0 36px 0;
      width: 180px;

      @include up-to(540px) {
        width: 100%;
      }
    }    
  }
}
