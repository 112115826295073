@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.payment-popup-content {
  width: 850px !important;
  max-height: 90vh;
  max-width: 90vw;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .payment-receipt {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

    &.success {
      color: $green1;
    }

    &.failed {
      color: $red;
    }

    &__icon {
      font-size: 48px;
      margin-bottom: 15px;
    }

    &__message {
      font-size: 24px;
      line-height: 28px;
      color: $gray1;
      margin-bottom: 5px;

      @include mobile {
        text-align: center;
      }
    }

    &__transaction {
      font-size: 14px;
      line-height: 16px;
      color: $gray4;
      margin-bottom: 41px;
    }

    &__details {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 85px;

      @include mobile {
        margin-bottom: 32px;
      }

      &__item {
        width: 158px;

        &:not(:last-child) {
          margin-right: 40px;

          @include mobile {
            margin-right: 0;
          }
        }

        @include mobile {
          align-items: center !important;
          text-align: center;
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }

    &__amount {
      margin-bottom: 80px;
    }

    &__bottom {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      line-height: 16px;
      color: $gray2;

      @include mobile {
        text-align: center;
      }
    }
  }
}

#iframe-authorize-net {
  width: 100%;
  height: 580px;
}
