@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-profile {
  &__columns {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 24px;

    &__item {
      margin-right: 48px;

      @include up-to(604px) {
        margin-right: 0;
      }
    }
  }

  &__gray-area {
    background-color: $gray7;
    padding: 24px;
    border-radius: $border-radius;
  }

  &__form {
    width: 230px;

    .form-items .xgs-labeled-input:first-child {
      margin-top: 0;
    }

    @include up-to(604px) {
      width: 100%;
    }

    .xgs-labeled-input {
      width: 100%;
    }    

    &__additional {
      position: relative;
      display: inline-block;
      width: 100%;
      padding-top: 24px;

      &__info {
        position: relative;
        margin: 14px 0 16px 0;
        padding: 8px;
        font-size: 14px;
        background-color: $gray6;
        width: 100%;
        border-radius: $border-radius;

        &:before {
          position: absolute;
          top: -10px;
          left: 32px;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 13.9px 8px 0;
          border-color: transparent $gray6 transparent transparent;
          transform: rotate(-150deg);
          -webkit-transform: rotate(-150deg);

          @include up-to(500px) {
            display: none;
          }
        }

        @include up-to(500px) {
          margin: 8px 0 0 0;
        }

        &__email-block {
          margin-bottom: 8px;

          strong {
            word-break: break-all;
          }
        }

        &__cancel {
          margin-top: 8px;
        }
      }
    }

    .xgs-form__field__notes {
      margin: 8px 0 0 0;
    }
  }

  .xgs-btn {
    padding: 6px 24px;
    width: auto;

    @include up-to(604px) {
      width: 100%;
    }
  }

  .react-tabs__tab {
    @include up-to(560px) {
      width: 100%;
      border: 1px solid #2F80ED !important;
      border-radius: 0;

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
      }
    }
  }

  &__notes {
    font-size: 14px;
    line-height: 18px;
    color: $gray3;
    margin-top: 16px;
  }
}