@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-summary {
  &__widgets {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__column {
  	width: 100%;
  }

  &__warning {
    padding: 32px 16px;
    margin-bottom: 24px;
    background-color: $gray6;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      margin-right: 16px;
      font-size: 36px;
      color: $orange;
    }

    &__text {
      color: $gray1;
      line-height: 1.5;
      font-weight: 400;
      letter-spacing: 0.5px;

      @include up-to(400px) {
        font-size: 14px;
      }

      &__br {
        @include up-to(740px) {
          display: none;
        }
      }
    }
  }
}
