@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-invoice-details {
  height: calc(100vh - #{$header-height});
  overflow: auto;
  padding-right: $margin6;

  &__table {
  	max-width: 895px;
    margin-bottom: 32px;

  	// no other way to override style for specific row in the table header
    .rdt_TableHeadRow .rdt_TableCol:nth-child(8) {
      text-align: right;
    }

    &__link {
      text-decoration: none;
      color: $blue1;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__header {
    .xgs-site__content-container-header__bar {
      @include up-to(1212px) {
        width: 100%;
        margin-top: 16px;
      }
    }

    .xgs-site__content-container-header__column {
      @include up-to(400px) {
        width: 100%;
      }
    }

    &__status {
      display: flex;
      justify-content: space-between;

      @include up-to(400px) {
        flex-wrap: wrap;
      }

      .xgs-container-status-header {
        @include up-to(400px) {
          width: 100%;
          margin-bottom: 16px;
        }
      }

      .xgs-btn {
        @include up-to(400px) {
          width: 100%;
        }
      }
    }
  }
}