@import "../../sass/variables.module.scss";
@import "../../sass/mixins.scss";

.card {
  padding: 16px;
  max-width: 406px;
  width: 100%;
  border: 1px solid $gray5;
  border-radius: 4px;
  margin-bottom: $margin7;
  overflow: hidden;    

  &__radio {
    margin-top: -15px;
  }

  &__header {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    width: 100%;
    height: 36px;
    gap: 12px;
    margin-bottom: 16px;

    &__icon {
      font-size: 36px;
      color: $blue1;
      display: block;
    }

    &__content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__title {
    font-family: Roboto-medium;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__subtitle {
    margin-top: -4px;
    color: $gray3;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__buttons {
    gap: 12px;
    display: flex;

    &__edit {
      color: $blue1;
      display: flex;
      gap: 8px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: $blue2;
      }
    }

    &--mobile-hidden {
      @include up-to(540px) {
        display: none;
      }
    }
  }

  &__content {
    overflow: hidden;   
  }

  &__info {
    margin-top: 16px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    &__item {
      white-space: nowrap;
      font-size: 14px;
      display: flex;
      gap: 8px;
      align-items: center;
      overflow: hidden;

      svg {
        color: $gray4;
      }

      &--secondary {
        padding-left: 16px;
      }
    }
  }
}

.card--clickable {
  cursor: pointer;
  
  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(79, 95, 114, 0.08);
  }
}