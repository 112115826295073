@import "./variables.module.scss";
@import "./mixins.scss";

.xgs-items {
  &__item {
    width: 408px;
    margin-top: 16px;
    padding: 16px 16px 0 16px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;

    @include up-to(540px) {
      width: 100%;
    }

    &__flex-center-area {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 24px;
      background: rgba(47, 128, 237, 0.08);
    }

    &__type {
      font-weight: 600;
      line-height: 20px;
    }

    &__class {
      width: 150px;
      flex-basis: 100%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;

      @include up-to(420px) {
        width: 120px;
      }

      @include up-to(340px) {
        width: 100px;
      }
    }

    &__columns {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      margin-bottom: 16px;

      &--space-between {
        justify-content: space-between;
      }

      &--allow-wrap {
        flex-wrap: wrap;
      }

      &--no-margin {
        margin-bottom: 0;
      }

      &--stretch {
        align-items: stretch;
      }

      &--actions {
        @include up-to(410px) {
          flex-wrap: wrap;
        }
      }
    }

    &__property {
      color: $gray3;

      &:not(.xgs-items__item__class) {
        font-size: 14px;
      }

      span {
        color: $gray0;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #2F80ED;
      font-size: 16px;
      line-height: 20px;

      &--disabled {
        color: $gray4;
        cursor: inherit;
      }

      &:hover:not(.xgs-items__item__action--disabled) {
        color: #125BBE;
      }

      img, svg {
        margin-right: 8px;
      }

      &--remove {
        color: $red-button;

        &:hover {
          color: #C43232;
        }
      }
    }
  }

  &__add {
    margin-top: 16px;
  }
}

.xgs-notification-block {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-radius: $border-radius;
  background: #F2F2F2;

  img {
    display: block;
  }

  &__text {
    color: #828282;
    font-size: 12px;
    line-height: 16px;
  }
}
