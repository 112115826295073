@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-pickup-log {
  &__date {
    display: flex;
    flex-wrap: wrap;

    .xgs-pickup-assignment__details__log__gray-value {
      margin-left: 8px;
    }
  }

  &__additional-info {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  &__gray-value {
    color: $gray3;
  }

  &__error {
    width: fit-content;
    max-width: calc(100% - 32px);
  }

  .xgs-list__controls {
    margin-bottom: 6px;

    &__search {
    align-items: flex-start;
    min-height: 74px;

      .xgs-list__controls__button {
      margin-top: 23px;
      }
    }
  }
}
