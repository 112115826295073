@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-company__users__details {
  &__impersonate {
    position: absolute;
    top: 24px;
    right: 32px;
    font-size: 14px;

    @include up-to(640px) {
      position: static;
    }

    &__icon {
      color: $purple1;
    }
  }

  &__warning {
    margin: 16px 0 24px 0;
    padding: 8px 11px;
    background-color: $gray6;
    border-radius: $border-radius;
    display: flex;
    align-items: center;

    &__icon {
      font-size: 22px;
      margin-right: 12px;
      color: $red;
    }

    &__text {
      font-size: 14px;
      color: $red;
    }
  }

  &__loading {
    position: absolute;
    right: 48px;
    top: 96px;
    font-size: 16px;
    color: $gray3;
  }

  &__form {
    display: flex;
    align-items: center;

    &__dropdown {
      flex-shrink: 0;
      margin: 8px 0;

      @include up-to(500px) {
        flex-shrink: 1;
      }

      &.xgs-labeled-input.row label {
        width: 160px;

        @include up-to(500px) {
          width: 100%;
          margin-bottom: 6px;
        }
      }

      &.xgs-labeled-input.row > div {
        @include up-to(500px) {
          width: calc(100% - 48px);
        }
      }
    }

    &__button {
      &:not(:last-child) {
        margin-right: $margin8;
      }

      @include up-to(500px) {
        margin-top: 20px;
      }
    }

    &__spinner {
      @include up-to(500px) {
        margin-top: 16px;
      }
    }
  }

  &__status {
    &__circle {
      flex-shrink: 0;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin-right: 8px;
      margin-top: 8px;
    }

    &__name {
      margin-right: $margin5;
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      
      .xgs-company__link:not(:last-child) {
        margin-right: 18px;
      }
    }
  }

  &__invited-on {
    color: $gray3;
  }

  &__permissions-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
    word-break: break-word;

    &__name {
      margin-right: 6px;
    }

    &__tag {
      display: inline-block;
      background-color: $gray6;
      border-radius: $border-radius;
      padding: 2px 4px;
      font-size: 11px;
      letter-spacing: 0.2px;
      line-height: 1;
      color: $gray1;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }

  &__no-permissions {
    color: $gray4;
    font-style: oblique;
  }

  &__instructions {
    color: $gray3;
  }

  &__additional__info {
    margin: 4px 0 16px 0;
    padding: 8px;
    font-size: 14px;
    background-color: $gray6;
    max-width: 320px;
    border-radius: $border-radius;
    line-height: 1.2;

    @include up-to(540px) {
      max-width: 278px;
    }

    @include up-to(499px) {
      max-width: none;
      width: 100%;
    }

    &__email-block {
      margin-bottom: 8px;

      strong {
        word-break: break-all;
      }
    }
  }

  &__permissions {
    &__spinner {
      position: absolute;
    }

    &__add-account {
      position: absolute;
      right: 32px;
      top: 122px;

      @include up-to(500px) {
        position: relative;
        right: 0;
        top: 0;
        margin-bottom: 24px;
      }

      @include up-to(440px) {
        .xgs-btn {
          width: 100%;
        }
      }
    }
  }

  .xgs-form__field__notes {
    margin-top: 8px;
    line-height: 1.2;
    max-width: 310px;
  }
}

.xgs-customers-users .react-tabs__tab {
  &-list {
    display: flex;

    @include up-to(480px) {
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      margin-bottom: 24px;
    }
  }

  max-width: 127px;
  padding: 4px;
  white-space: nowrap;

  @include up-to(480px) {
    max-width: unset;
    width: 100%;
    border: 1px solid #2F80ED;
    border-radius: 0;

    &:first-child { border-radius: 4px 4px 0 0; }
    &:last-child { border-radius: 0 0 4px 4px; }
  }
}

@include up-to(480px) {
  .xgs-sidebar__details__name .xgs-inline-switch__content {
    font-size: 18px;
  }

  .xgs-sidebar__details__title .xgs-sidebar__details__title {
    font-size: 12px;
  }
}

.update-password-btn {
  min-width: 140px;
  width: auto;
  margin-top: 38px;

  @include mobile() {
    width: 100%;
  }
}

.xgs-caption {
  font-size: 12px;
  line-height: 12px;
  font-variant: small-caps;
}
