@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-announcement {
  &__modal {
    &__header {
      font-weight: bold;
      word-wrap: break-word;
    }

    &__content {
      margin-bottom: 24px;
      word-wrap: break-word;

      a {
        color: #2F80ED;
        text-decoration: none;
      }

      .ql-editor {
        padding: 0;
      }
    }
  }
}
