@import "./../../../sass/variables.module.scss";

.xgs-labeled-input {
  display: flex;
  flex-direction: row;

  &.xgs-labeled-input-failed {
    input {
      border-color: $form-validation-error-color;
    }

    select {
      border-color: $form-validation-error-color;
    }
  }

  &.column {
    flex-direction: column;
  }

  &.row {
    $labelWidth: 190px;
    align-items: center;
    flex-wrap: wrap;

    label {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-right: 18px;
      width: $labelWidth;
      line-height: 1;
    }

    & > div {
      width: calc(100% - #{$labelWidth});
    }

    input {
      width: 100%;
    }

    .xgs-labeled-input__validation-error {
      display: block;
      width: 100%;
      margin-left: $labelWidth;
    }
  }

  label {
    font-size: 14px;
    line-height: 24px;

    span {
      color: $form-validation-error-color;
    }
  }

  &__validation-error {
    color: $form-validation-error-color;
    font-size: 12px;
    margin-left: 8px;
    margin-top: 4px;
  }
}