@import "./../../sass/variables.module.scss";

.xgs-block-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-grow: 1;
  font-size: 14px;
  color: $gray3;
  min-height: 50px;
}
