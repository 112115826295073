@import "../../sass/variables.module.scss";
@import "../../sass/mixins.scss";

.xgs-lmi {
  &__lanes {
    word-wrap: break-word;
    div[role="row"] {
      > * {
        display: flex;
        align-items: center;
        font-size: 12px;
      }

      .th {
        font-size: 14px;
      }
    }
  }

  &__info {
    box-sizing: border-box;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 16px;

    width: 100%;
    height: 64px;
    min-height: 64px;

    background: rgba(47, 128, 237, 0.08);

    border: 1px solid #2F80ED;
    border-radius: 4px;

    padding: 12px;
    color: #2F80ED;
    margin-bottom: $margin6;
  }

  &__trips {
    div[role="row"] {
      > * {
        display: flex;
        align-items: center;
        font-size: 12px;
      }
    }
    
    .th {
      font-size: 14px !important;
    }

    &__driver-cell {
      line-height: 14px;
      font-size: 12px;
      span {
        color: $gray3;
      }
    }

    &__address-cell {
      font-size: 12px;
      line-height: 12px;
      span {
        color: $gray3;
      }
    }

    &__date-cell {
      span {
        font-size: 14px;
      }
    }
  }

  &__title {
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: $margin6;
    align-items: center;
    flex-wrap: wrap;
  
    &__status {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $gray3;
    }
  }
  
  &__controls {
    max-width: 1070px;
    margin-bottom: $margin6;
    display: flex;
    gap: 10px;
    row-gap: 16px;
    flex-wrap: wrap;

    &__item {
      width: 160px;
    }
    @include mobile {
      margin-top: -16px;
    }

    &__button {
      display: flex;
      align-items: flex-end;
      margin-bottom: 5px;
      margin-left: auto;

      @include up-to(700px) {
        margin-left: 0px;
      }
    }
  }

  &__details {
    margin-bottom: $margin6;

    &__header {
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;

      span {
        color: $gray3;
      }

      label {
        color: $gray3;
        font-size: 12px;
      }

      p {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__table-button {
      width: 100%;
      max-width: 730px;
      padding: 8px 8px 8px 16px;
      height: auto;
      border-radius: 0;
      background-color: rgba(47, 128, 237, 0.08);
      color: #2F80ED;

      img {
        margin-right: 10px;
      }

      &:hover {
        background-color: rgba(47, 128, 237, 0.08) !important;
      }
    }

    &__block {
      margin-bottom: $margin6;
      width: 100%;

      div[role="row"] {
        > * {
          font-size: 12px;
        }
        .th {
          font-size: 14px;
        }
      }
    }

    &__block-title {
      font-size: 16px;
      color: $gray3;
      margin-bottom: $margin7;
    }
    
    &__driver {
      display: flex;
      gap: 12px;
      align-items: center;
      width: fit-content;
      border-radius: 4px;
      border: 1px solid $gray5;
      padding: 16px;
      font-size: 14px;
      @include up-to(500px) {
        width: 100%;
      }
      &__info {
        position: relative;
        min-width: 150px;
        height: fit-content;
        label {
          color: $gray3;
          line-height: 20px;
        }

        &__button {
          &--small {
            display: none;
            @include up-to(450px) {
              display: block;
            }
          }

          &--large {
            @include up-to(450px) {
              display: none;
            }
          }
        }

        &__name {
          font-weight: bold;
          line-height: 20px;
          span {
            color: $gray3;
            font-weight: normal;
          }
        }

        
      }
      
      &__edit {
        position: relative;
        top: -9px;
        width: fit-content;
        height: fit-content;
      }
      
    }

    &__assign-lane__table {
      
      button {
        margin-bottom: $margin6;
      }

      div[role="row"] {
        > * {
          display: flex;
          align-items: center;
          font-size: 12px;
        }
      }

      .th {
        font-size: 14px !important;
      }
    }
    
    .xgs-table__wrapper {
      input {
        border-radius: 10px;
        width: 15px;
        height: 15px;
      }
      width: 100%;
    }

    div[role="rowgroup"] {
      > * {
        height: 60px;
        align-items: center;
      }
      
      > :nth-child(even) {
        background-color: $gray7;
      }
    }
  }

  &__form {
    margin-bottom: $margin6;
    > * {
      margin-bottom: 16px;
    }   

    &__buttons {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      margin-top: $margin6;
      margin-bottom: 120px;
      button {
        width: 100%;
      }
    }
  }

  &__driver-select {
    &__buttons {
      display: flex;
      gap: 12px;
      margin-top: $margin6;
      width: 100%;
      .xgs-btn {
        width: 100%;
      }
    }
  }

  &__activity {
    margin-left: auto;
    button {
      color: $blue1;
      width: 150px;
    }
  }
}

.hide-table-content {
  .xgs-table__content {
    display: none;
  }
}

