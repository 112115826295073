@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";
$breakpoint: 900px;

.xgs-documents__pod-recognition {
  @include responsiveTable($table-breakpoint);

  .tr {
    align-items: center;
  }

  .blue-link:hover {
    text-decoration: underline;
  }

  &__masterbill {
    &__header {
      color: rgba(79, 95, 114, 0.72);
      font-size: 12px;
      line-height: 16px;
    }

    &__number {
      color: black;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .xgs-list__controls {
    align-items: center;
    gap: 16px;

    @include mobile() {
      flex-direction: column;
    }
  }

  .xgs-list__controls__search {
    & > form {
      @include up-to($breakpoint) {
        flex-wrap: wrap;
      }
    }

    .xgs-labeled-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 200px;
      max-width: 300px;

      @media (max-width: $breakpoint) {
        max-width: 250px;
      }

      @media (max-width: 768px) {
        max-width: none;
      }
    }

    .xgs-item-form__field_lg.xgs-labeled-input {
      max-width: 400px;

      @media (max-width: 768px) {
        max-width: none;
      }
    }

    .xgs-btn {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;

      @media (min-width: 768px) {
        width: 140px;
        margin-top: 24px;
      }
    }
  }

  &__popup {
    &__header {
      font-weight: 600;
      color: black;
      margin-bottom: 16px;
    }

    &__content {
      color: black;
      margin-bottom: 24px;
    }

    &__buttons {
      .xgs-btn {
        width: 100%;
      }
    }
  }
}
