@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-site__menu-wrapper {
  width: $side-menu-width-desktop;
  flex-shrink: 0;

  @include tablet {
    width: $side-menu-width-tablet;
  }  

  @include mobile {
    width: 100%;
  }

  &--opened {
    margin-left: 0;
    
    @include tablet {
      transition: margin 0.2s ease-in-out;
    }
  }

  &:not(.xgs-site__menu-wrapper--opened) {
    @include tablet {
      margin-left: -$side-menu-width-tablet;
      transition: margin 0.2s ease-in-out;
    }

    @include mobile {
      margin-left: -$side-menu-width-mobile;
    }    
  }

  .xgs-menu {
    display: flex;
    width: $side-menu-width-desktop;
    min-height: $height-without-header;
    height: $height-without-header;
    position: fixed;

    @media only screen and (max-height: 600px) {
      position: relative;
      height: 100%;
    }

    @include mobile {
      display: none;
    }

    &__top-level {
      width: 40px;
      height: 100%;
      background-color: black;
      padding: 24px 0;

      &__item {
        display: block;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 16px;
          height: auto;
        }

        &:hover,
        &--hover {
          background-color: #828282;
        }

        &--active {
          background-color: #2F80ED;
        }
      }
    }

    &__second-level {
      position: relative;
      background-color: #F2F2F2;
      padding: 24px 0;
      width: 176px;

      &__header {
        padding: 12px 11px 16px 16px;
        font-size: 14px;
        transition: all 0.5s ease-out;
      }

      &__item {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 8px 11px 8px 16px;
        color: rgba(79, 95, 114, 0.72);
        font-size: 14px;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.5s ease-out;

        & > div:first-child {
          display: flex;
          align-items: center;
        }

        &__arrow {
          display: none;
        }

        &:hover {
          color: black;
        }

        &--active {
          color: #2F80ED;

          &:hover {
            color: #125BBE;

            .xgs-menu__second-level__item__arrow {
              path {
                fill: #125BBE;
              }
            }
          }

          .xgs-menu__second-level__item__arrow {
            display: block;
          }
        }
      }

      &__bottom-block {
        bottom: 0;
        left: 0;
        position: absolute;
        margin: 16px;
        width: calc(100% - 32px);
      }
    }

    &__item {
      display: flex;      
      width: 100%;
      margin-bottom: 16px;
      text-decoration: none;
      color: $side-menu-item-color;
      font-size: 15px;
      background-color: $side-menu-item-back;

      &__sub {
        position: relative;
      }

      &--link:hover,
      &--link:hover .xgs-menu__icon,
      &__sub:hover {
        color: $blue1;
      }

      &__selected,
      &__selected .xgs-menu__icon {
        color: $side-menu-item-selected-color;
      }

      &:not(.xgs-menu__item__selected) .xgs-menu__item__sub {
        color: $gray3;
      }

      &--desktop-hide {
        @media only screen and (min-width: $tablet-width + 1) {
          display: none;
        }
      }

      @include mobile {
        font-size: 18px;
        margin-bottom: 24px;
      }
    }

    &__icon-container {
      display: flex;
      justify-content: flex-end;
      width: 24px;
    }

    &__icon {
      margin: 0 $margin8 0 0;
      color: $gray1;
    }

    &__selector {
      display: none;
      margin-bottom: 24px;

      @include up-to(600px) {
        display: block;
      }

      .popup-content {
        left: 0 !important;
        right: 0 !important;
        width: 100% !important;
      }
    }
  }

  .xgs-menu__knowledge-base {
    &__block {
      width: 100%;
      border-radius: 4px;
      border: 1px solid rgba(79, 95, 114, 0.32);
      background: white;
      padding: 8px 12px 8px 12px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: black;
      line-height: 20px;

      @include mobile {
        min-width: 160px;
        margin-left: -36px;
        margin-bottom: 48px;
      }

      &:hover {
        cursor: pointer;
        color: $blue1;
      }
    }

    &__link {
      text-decoration: none;
    }

    &__icon {
      margin-right: 12px;
    }
  }

  .xgs-mobile-menu {
    display: none;
    padding: $margin2 $margin5;
    margin: 0 auto;
    width: 100vw;
    max-height: calc(100vh - $header-height);
    background-color: #e0e0e0;
    overflow-y: auto;

    @include mobile {
      display: block;
    }

    &__selector {
      display: none;
      margin: 0 auto 24px auto;
      padding-right: 24px;
      width: fit-content;

      @include up-to(600px) {
        display: block;
      }

      .popup-content {
        left: 0 !important;
        right: 0 !important;
        width: 100% !important;
      }
    }

    &__items {
      margin: 0 auto 16px auto;
      width: fit-content;

      &__top-level-item {
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 18px;
        color: #333;
        margin-bottom: 24px;

        img {
          display: block;
          margin: 0 8px 0 -24px;
          vertical-align: middle;
          width: 16px;
          -webkit-filter: invert(1);
        }
      }

      &__second-level-item {
        display: block;
        width: fit-content;
        color: #828282;
        font-size: 18px;
        text-decoration: none;
        margin-bottom: 24px;

        &--active {
          color: #2f80ed;
        }
      }
    }

    .xgs-stage-banner {
      max-width: 320px;
      margin: -24px auto 24px auto;
    }
  }
}

.xgs-red-dot {
  &__wrapper {
    display: inline-flex;
    align-items: flex-start;
    height: 100%;
    margin-left: 2px;
  }

  &__dot {
    width: 6px;
    height: 6px;
    background-color: $red;
    border-radius: 6px;
  }
}