@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-slide-out-sidebar {
  $sidebarMaxWidth: 820px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60vw;
  max-width: $sidebarMaxWidth;
  background-color: white;
  transition: margin 0.5s ease-in-out;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;

  @include up-to(1324px) {
    width: 65vw;
  }

  @include up-to(1024px) {
    width: 78vw;
  }

  @include mobile {
    $sidebarMaxWidth: 100vw;
    width: 100vw;
    max-width: $sidebarMaxWidth;
  }

  &--extrasmall {
    $sidebarMaxWidth: 380px;
    width: 100%;
    max-width: $sidebarMaxWidth;
  }

  &--small {
    $sidebarMaxWidth: 460px;
    width: 100%;
    max-width: $sidebarMaxWidth;
  }

  &--small-605 {
    $sidebarMaxWidth: 605px;
    width: 100%;
    max-width: $sidebarMaxWidth;
  }

  &--medium {
    $sidebarMaxWidth: 820px;
    width: 60vw;
    max-width: $sidebarMaxWidth;
  }

  &--large {
    $sidebarMaxWidth: 1260px;
    width: 75vw;
    max-width: $sidebarMaxWidth;
  }

  @include mobile {
    &--extrasmall, &--small, &--medium, &--large {
      $sidebarMaxWidth: 100vw;
      width: 100vw;
      max-width: $sidebarMaxWidth;
    }
  }

  &--opened {
    margin-right: 0;
  }

  &--closed {
    margin-right: -$sidebarMaxWidth;
  }

  &__header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 54px;
    background-color: $gray6;
    padding: 18px 26px;
  }

  &__spinner {
    display: inline-block;
  }

  &__close {
    color: $gray4;
    cursor: pointer;
  }

  &__content {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    max-height: calc(100vh - 54px);
    overflow-y: auto;
    padding: 26px 32px 26px 28px;

    @include up-to(420px) {
      padding: 16px 24px;
    }
  }
}
