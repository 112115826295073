@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";
$breakpoint: 900px;

.xgs-customer-pickups-list {
  @include responsiveTable($table-breakpoint);

  .xgs-list__controls {
    align-items: center;
    gap: 16px;

    @include mobile() {
      flex-direction: column;
    }
  }

  .xgs-list__controls__search {
    & > form {      
      @include up-to($breakpoint) {
        flex-wrap: wrap;
      }      
    }

    .xgs-labeled-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 200px;
      max-width: 300px;       

      @media (max-width: $breakpoint) {
        max-width: 250px;
      }

      @media (max-width: 768px) {
        max-width: none;
      }
    }

    .xgs-item-form__field_lg.xgs-labeled-input {
      max-width: 400px;

      @media (max-width: 768px) {
        max-width: none;
      }
    }

    .xgs-btn {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;      

      @media (min-width: 768px) {
        width: 140px;
        margin-top: 24px;
      }      
    }    
  }  
}