@import "./../../sass/variables.module.scss";

.xgs-select {
  &__control {
    cursor: text;
    font-size: 14px !important;
    font-family: "Roboto-Regular" !important;
    outline: none;
    height: auto !important;

    &--is-disabled {
      border: 1px solid rgba($gray8, 0.32) !important;
      background-color: rgba($gray8, 0.04) !important;

      .xgs-select__placeholder {
        color: rgba($gray8, 0.32) !important;
        font-size: 14px !important;
      }
    }


    .xgs-labeled-input-failed & {
      border-color: #EB5757;
    }

    &--is-focused {
      box-shadow: 0 0 0 2pt $supporting_blue_40 !important;
      border: 1px solid $gray4 !important;
    }

    .xgs-tracking-filter__input--multi & {
      height: auto;
    }
  }

  &__value-container {
    cursor: text;
  }

  &__placeholder {
    cursor: text;
  }

  &__indicator {
    padding: 6px 2px !important;
  }

  &__group {
    position: relative;
  
    &:not(:first-child) {
      .xgs-select__group-label::before {
        content: "";
        width: calc(100% - 20px);
        height: 1px;
        background-color: $gray5;
        position: absolute;
        top: 0px; 
        left: 10px;     
      }
    }
  }

  &__group-label {
    text-transform: none;
    color: $gray3;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;    
  }

  &__add-item-button {
    padding: 8px 8px 16px 10px;

    .xgs-btn {
      text-decoration: underline;
    }   
  }
}
