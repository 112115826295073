@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-tracking {
  width: 100%;
  max-width: 1500px;
  z-index: 10;
  background-color: white;

  @include up-to(400px) {
    padding-top: 0;
  }

  &__values-delimiter {
    width: 24px;
    height: 1px;
    background-color: $gray5;
    margin: 2px 0;
  }

  &__cells {
    &__warning {
      color: #C43232;
      font-weight: bold;
      width: fit-content;
      background-color: rgba(231, 76, 22, 0.4);
      margin-bottom: 4px;
      border-radius: 2px;
      padding: 4px;
      font-size: 12px;
      svg {
        float: left;
        display: block;
        font-size: 25px;
        padding: 5px;
      }
    }
  
    &__ok {
      color: #14870A;
      background-color: rgba(20, 135, 10, 0.2);
      margin-bottom: 4px;
      border-radius: 2px;
      padding: 2px;
      font-size: 12px;
      svg {
        float: left;
        display: block;
        font-size: 25px;
        padding: 5px;
      }
    }
  }

  &__ai-banner {
    max-width: 1110px;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
  
    &__content {
      flex: 1 1 410px;
    }
  }
}
