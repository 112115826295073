@import "./../../../sass/variables.module.scss";

.stop-marker-info {
  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__label {
    padding-right: 3px;
    color: $gray3;
  }
}