@import "./../../../../sass/mixins.scss";

.xgs-unplannable-probill-details {
  &__header {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 20px;
  }

  .planning-details {

    &__title {
      font-size: 600;
      margin-bottom: 10px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;

      &__properties {
        display: flex;
        flex-direction: row;

        &__type {
          margin-right: 5px;
        }

        &__value {
          font-weight: 600;
        }
      }
    }

    &__issues {

      &__title {
        font-weight: 600;
        margin-bottom: 10px;
      }

      &__content__item__desc {
        margin-bottom: 8px;

        .--text-light {
          color: #777777;
          margin-right: 2px;
        }
      }
    }
  }
}