@import "./../../../sass/variables.module.scss";

.xgs-forgot-password {
  &__text {
    font-size: 14px;
    line-height: 20px;
    color: $gray3;
    margin-top: 36px;
  }
}
