.shipper-card {
  &__icon {
    border-radius: 24px;
    background: rgba(47, 128, 237, .2);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}