@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-manifest-documents {
  &__manifest {
    max-width: 240px;
    margin-bottom: $margin7;
  }

  &__radio-group {
    margin-bottom: $margin6;
    display: flex;
    align-items: center;
    label {
      font-size: 16px;
    }
    input {
      margin-right: 20px;
      margin-top: -2px;
      width: 17px;
      height: 17px;
    }
  }  

  &__checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 16px;    
  }

  &__error {
    margin-bottom: 16px;
    font-size: 14px;
    color: $red;
  }

  &__buttons {
    display: flex;
    gap: 10px;
  }
}