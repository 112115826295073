@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-breadcrumbs {
  font-size: 14px;
  display: flex;
  padding-left: $page-content-margin;

  @include mobile {    
    padding-left: $page-content-margin-sm;
  }

  &__item {
    color: $link-color;
    text-decoration: none;
    padding: 0 2px;

    &:hover {
      color: $blue1;
    }
  }

  &__separator {
    margin: 0 4px;
  }
}