.stops-legend {
  &__item {
    display: flex;
    align-items: center;
    width: 144px;
    gap: 12px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__marker {
    position: relative;
    width: 28px;
    height: 40px;
    display: flex;

    svg {
      filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
    }
  }

  &__label {
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%); 
    font-weight: 400;
  }

  &__description {
    color: #000;
    font-size: 14px;
  }
}