@import "../../../sass/variables.module.scss";
@import "../../../sass/mixins.scss";

.xgs-account-contact {
  margin-bottom: 140px;
  &__heading {
    font-size: 16px;
    margin-bottom: $margin8;
  }
  &__sub-heading {
    margin-bottom: $margin7;
  }
  &__new-contact {
    width: 233px;
  }
  &__no-contacts {
    margin-bottom: $margin7;
    font-size: 14px;
  }
  &__unavailable-switch {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: $margin6;
    font-size: 14px;
  }
}

.xgs-contact-card {
  padding: 16px;
  max-width: 406px;
  width: 100%;
  border: 1px solid $gray5;
  border-radius: 4px;
  margin-bottom: $margin7;
  overflow: hidden;

  &__user {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    width: 100%; 
    height: 36px;
    gap: 12px;
    align-items: flex-start;

    &__icon {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2F80ED14;
      font-size: 36px;
      color: $blue1;

    }
    
    &__info {
      overflow: hidden;

      &__name {
        font-family: Roboto-medium;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &__title {
        margin-top: -4px;
        color: $gray3;
        font-size: 14px;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &__buttons {
      gap: 12px;
      display: flex;
      
      &__edit {
        color: $blue1;
        display: flex;
        gap: 8px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: $blue2;
        }
      }
      
      &--mobile-hidden {
        @include up-to(540px) {
          display: none;
        }
      }
    }
    height: 36px;
  }

  &__info {
    margin-top: 16px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    &__item {
      white-space: nowrap;
      font-size: 14px;
      display: flex;
      gap: 8px;
      align-items: center;
      overflow: hidden;

      svg {
        color: $gray4;
      }
    }
  }

  @include up-to(770px) {
    max-width: 100%;
  }
}

.xgs-contact-form {
  grid-template-columns: 1fr 1fr;
  border-radius: 4px;
  display: grid;
  border: 1px solid $gray5;
  padding: $margin7;
  max-width: 406px;
  gap: 12px;
  row-gap: $margin7;
  margin-bottom: $margin7;
  
  @include up-to(450px) {
    width: 100%;
  }

  &__span-2 {
    grid-column: span 2;
  }

  &__buttons {
    grid-column: span 2;
    display: flex;
    gap: 12px;

    button {
      width: 100%;
    }

    @include up-to(410px) {
      flex-direction: column;
    }
  }
}