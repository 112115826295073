@import "../../../../sass/mixins.scss";

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.list-item {
  padding: 0.3rem;
  white-space: nowrap;
  cursor: pointer;
  height: auto;
  background-color: white;
}

.floating {
  position: fixed;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  background-color: white;
}

.list-item.drop-zone {
  background-color: #ccc;
  transition-property: height padding;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}

.list-item.drop-zone.hidden {
  height: 0px;
  padding: 0px
}

.route-add-stop {
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 5px;
  align-items: center;
  color: #276FCF;
  background-color: white;
  border: 1px solid #276FCF;
  border-radius: 10px;  
  cursor: pointer;
  margin-bottom: 20px;
}

.route-add-stop-title {
  align-self: center;
  align-content: center;
  margin: auto;
}

.route-add-stop-icon {
  margin-left: 2px;
  cursor: pointer;
}
