@import "./../../../../../sass/variables.module.scss";
@import "./../../../../../sass/mixins.scss";

.xgs-appointment-stats {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 537px;
  height: 90px;
  justify-content: center;
  gap: 16px;

  @include up-to(1279px) {
    display: none;
  }

  &__block {
    display: flex;
    align-items: center;
    width: 50%;
    border-radius: 4px;
    background: $gray_4;
    padding: 8px 12px;
  }

  &__value {
    font-size: 40px;
    font-weight: 600;
    line-height: 32px;
    margin-right: 6px;
  }

  &__text {
    font-size: 12px;
    color: $gray_72;
    line-height: 16px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    padding: 9px;
    height: 32px;
    width: 32px;
    font-size: 24px;
    font-weight: bold;
    margin-right: 12px;
    cursor: pointer;

    &--blue {
      background-color: #2F80ED33;
      color: $blue1;
    }

    &--red {
      background-color: #DD3F3F33;
      color: #C43232;

      path {
        fill: #E82A2B;
      }
    }

    @include up-to(594px) {
      font-size: 22px;
    }
  
    @include up-to(400px) {
      font-size: 20px;
    }
  }
}