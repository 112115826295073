@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-contact-us {
  &__popup {
    &-content {
      width: 900px !important;
      max-height: 90vh;
      max-width: 90vw;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      a {
        outline: none;
      }
    }
  }

  &__representative {
    padding: 0;
    margin: 0;
  }

  &__columns {
    display: flex;

    @include up-to(650px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    box-sizing: border-box;
    width: 50%;
    padding-right: $margin7;

    @include up-to(650px) {
      width: 100%;
      padding-right: 0;
    }
  }

  &__header {
    font-size: 14px;
    color: $gray2;
    margin-top: $margin6;
    margin-bottom: 4px;
  }

  &__text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__link {
    font-size: 15px;
    color: $blue1;
  }

  &__feedback {
    margin: 32px 0 8px 0;
    line-height: 1.2;
    font-size: 14px;
  }
}
