@import "./../../sass/variables.module.scss";

.xgs-invite-user {
  &__popup {
    &-content {
      width: 581px !important;
      max-height: 90vh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    &__header {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 26px;
    }

    &__field {
      width: 230px;
      height: 90px;
    }

    &__checkbox {
      padding: 0px 0 36px 0;      
    }

    &__button {
      min-width: 169px;
    }
  }
}
