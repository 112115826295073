@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-reports__scheduled {
  margin-bottom: 36px;

  &__list-spinner {    
    margin-bottom: 30px;
    margin-top: 30px;
  }

  &__no-reports {
    font-size: 16px;
    line-height: 19px;
    color: $gray3;
    min-height: 38px;
    margin-bottom: 30px;
  }

  &__report {
    width: 480px;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 16px;
    background-color: $gray7;

    @include up-to(560px) {
      width: 100%;
      word-wrap: break-word;
    }

    &:hover {
      background: $lightblue;
    }
  }

  &__add-new-button {
    margin-top: 24px;    

    @include up-to(540px) {
      width: 100%;
    }
  }

  &__reports {
    width: 100%;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);    

    @include up-to(1920px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include up-to(1512px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include up-to(920px) {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    cursor: pointer;
    margin-bottom: 0;
    max-width: none;
    
    &__icon {
      display: flex;
      width: 32px;
      height: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: $supporting_blue_16;
    }

    &__row {
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;
      min-width: 0;
      font-size: 14px;

      @include up-to(540px) {
       flex-wrap: wrap;
      }

      &:not(&:last-child) {
        margin-bottom: 8px;
      }
    }

    &__row-item {
      display: flex;
      flex-wrap: nowrap;
      gap: 4px;
      min-width: 0;

      &--no-shrink {
        flex-shrink: 0;
      }
    }

    &__label {
      color: $gray_72;
      flex-shrink: 0;
    }    

    &__row-truncated {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}