@import "../../../sass/variables.module.scss";
@import "../../../sass/mixins.scss";

.xgs-teams__add-account {
  &__popup {
    &-content {
      width: 581px !important;
      max-height: 90vh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      z-index: 1000;
    }

    &__header {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 26px;
    }

    &__field {
      width: 240px;
      height: 90px;

      @include mobile {
        width: 100%;
      }
    }

    &__button {
      min-width: 169px;

      @include mobile {
        width: 100%;
      }
    }
  }

  &__account {
    &__area {
      margin-bottom: $margin7;
      width: 240px;

      @include mobile {
        width: 100%;
      }
    }

    &__title {
      font-size: 14px;
      line-height: 24px;
    }

    &__data {
      background-color: $gray7;
      padding: 10px;
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 1.5;
    }

    &__error {
      margin-left: 10px;
      font-size: 12px;
      color: $red;
    }
  }
}
