@import "./../../../sass/variables.module.scss";

.xgs-aging-item {
  display: flex;
  flex-direction: column;
  min-width: 136px;
  height: $margin4;
  text-decoration: none;

  &__title {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    display: table;
    align-items: center;
    color: $gray3;

    &:not(.xgs-aging-item__title_selected)::before {
      display: inline-block;
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 10px;
      margin-right: 3px;
      vertical-align: 4%;
    }

    &_TOTAL {
      &::before {
        background-color: $red;
      }
    }

    &_CURRENT {
      &::before {
        background-color: $orange;
      }
    }

    &_1_30 {
      &::before {
        background-color: $green1;
      }
    }

    &_31_60 {
      &::before {
        background-color: $purple1;
      }
    }

    &_61_90 {
      &::before {
        background-color: $blue3;
      }
    }

    &_91_120 {
      &::before {
        background-color: $yellow;
      }
    }

    &_120_PLUS {
      &::before {
        background-color: $green3;
      }
    }

  }

  &__value {
    font-size: 24px;
    line-height: 24px;
    color: $gray1;
  }
}