@import "../../../../sass/variables.module.scss";
@import "../../../../sass/mixins.scss";

$green: #14870A;
$bgGreen: #14870A33;

.picklist-porgress-bar {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 16px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    color: #777777;
  }

  &__bar {
    width: 100%;
    height: 4px;
    margin-top: 4px;
    border-radius: 4px;
    background-color: #2F80ED14;

    >div {
      height: 4px;
      border-radius: 4px;
      background-color: #2F80ED;
    }

    &.--completed {
      background-color: $bgGreen;

      >div {
        background-color: $green;
      }
    }
  }
}