@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-header-subaccounts {
  margin-left: 40px;

  @include up-to(600px) {
    margin-left: 0;
  }

  &__control {
    display: flex;
    align-items: center;
    z-index: 3;

    &__title {
      font-size: 18px;
      margin-right: $margin7;
      max-width: 260px;
      max-height: $header-height;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include tablet {
        max-width: 220px;
        font-size: 16px;
      }

      @include mobile {
        max-width: 180px;
        font-size: 12px;
        margin-right: $margin8;
      }      
    }

    &__location {
      text-overflow: ellipsis;
      overflow: hidden;      
      font-size: 14px;
      color: $gray3;

      @include tablet {
        font-size: 12px;
      }

      @include mobile {
        font-size: 12px;
      }
    }

    &__arrow {
      font-size: 14px;
      color: $gray1;
      line-height: 17px;

      @include mobile {
        font-size: 12px;
      }
    }
  }

  span.title {
    font-size: 18px;
    line-height: 21px;
  }

  &__filter {
    margin-top: 22px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    margin-top: $margin7;
    max-height: calc(50vh - 70px);
    overflow-y: auto;
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 1px 8px 1px 3px;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;

    @include mobile {
      padding-right: 18px;
      margin-bottom: 16px;
    }

    &:hover {
      color: $blue1;

      .xgs-header-subaccounts__item__number {
        color: $blue1;
      }
    }

    &__block {
      display: flex;

      @include mobile {
        flex-grow: 1;
      }
    }

    &__name {
      max-width: 235px;
      line-height: 14px;
      word-break: break-word;

      @include mobile {
        max-width: calc(100% - 42px);
      }
    }

    &__city {
      text-transform: capitalize;
    }

    &__number {
      color: $gray3;
    }

    &__icons {
      width: 18px;
      margin-right: 10px;
      text-align: right;
    }

    &__icon {
      color: $orange;
      font-size: 9px;

      &:not(:last-child) {
        margin-right: 3px;
      }
    }
  }
}

.xgs-header-subaccounts__list-content.popup-content {
  width: 375px !important;
  max-height: 50vh;
  padding: 16px 19px 16px 24px !important;

  @include up-to(600px) {
    left: 0 !important;
    right: 0;
    margin-inline: auto;
  }

  @include up-to(375px) {
    width: 100% !important;
  }
}
