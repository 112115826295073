
.xgs-toggle {
  display: flex;
  align-items: center;
  overflow: hidden;

  .react-switch-handle {
    height: 16px !important;
    width: 16px !important;
    top: 2px !important;
  }

  &:not(.xgs-toggle--checked) {
    .react-switch-handle {
      transform: translateX(2px) !important;
      filter: drop-shadow(0px 1px 2px rgba(130, 130, 130, 0.80));
    }
  }

  &--checked {
    .react-switch-handle {
      transform: translateX(14px) !important;
      filter: drop-shadow(0px 1px 2px rgba(18, 91, 190, 0.80));
    }
  }

  &__label {
    cursor: pointer;
    margin-left: 8px;
    font-size: 14px;
    line-height: 20px;
    color: black;

    &--disabled {
      cursor: inherit;
    }
  }
}
