@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-pending-requests {
  &__table-wrapper {
    max-width: 1200px;
    height: calc(100vh - 242px);

    @include mobile {
      height: calc(100vh - 264px);
    }

    @include up-to(642px) {
      height: calc(100vh - 308px);
    }
  }

  &__table {
    height: 100%;

    &__info-column {
      &__row {
        display: flex;
        align-items: center;
      }

      &__name {
        display: inline-block;
        color: $gray3;
        margin-right: 4px;
      }

      &__value {
        display: inline-block;
        max-width: 300px;
        max-height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: normal;
      }
    }
  }

  &__details {
    &__controls {
      margin: 36px 0 48px 0;

      .xgs-btn {
        &:not(:last-child) {
          margin-right: 16px;

          @include up-to(540px) {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }

        @include up-to(540px) {
          width: 100%;
        }
      }
    }

    &__header {
      font-weight: bold;
      margin: 24px 0 8px 0;
    }

    &__modal {
      .xgs-modal__content {
        color: $gray0;
        min-height: 90px;
        padding-top: 16px;
      }

      &__team {
        margin-bottom: 24px;

        &__label{
          font-size: 14px;
          margin-bottom: 2px;
        }

        &__new {
          font-size: 14px;
          background-color: $gray6;
          padding: 8px;

          .xgs-input__field {
            max-width: 420px;
          }

          &__label {
            margin: 16px 0 4px 0;
          }

          &__loading {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 160px;
          }

          &__account {
            display: inline-block;
            padding: 8px;
            border: 1px solid $gray4;
            border-radius: $border-radius;
            background-color: $gray7;
            color: $gray3;

            &__name {              
              margin-bottom: 4px;
              color: $gray0;
            }
          }
        }
      }

      &__search {
        display: flex;
        align-items: flex-end;
        margin-bottom: 16px;

        @include up-to(540px) {
          flex-wrap: wrap;
        }

        &__input {
          width: 280px;
          margin-right: 16px;
          flex-shrink: 0;

          @include up-to(540px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 8px;
          }
        }

        &__button {
          @include up-to(540px) {
            width: 100%;
          }
        }
      }

      &__table {
        &__wrapper-low {
          height: 140px;
        }

        &__wrapper-high {
          height: 200px;
        }

        &__highlighted-row {
          background-color: $gray5;

          & > div:first-child:before {
            font-size: 16px;
            content: "\2713";
            color: $green1;
            padding: 8px 4px 0 0;
          }
        }
      }

      &__checkbox {
        margin-bottom: 16px;
      }

      &__textarea {
        margin-top: 16px;
      }

      &__step {
        &__header {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 16px;
        }

        &__content {
          margin: 0 -16px 24px -16px;
          padding: 16px;
          background-color: $gray7;
        }
      }

      &__permissions {
        &__administrator {
          font-size: 14px;
          text-align: center;
          padding: 24px;
          max-width: 340px;
          margin: 0 auto;
          color: $gray1;
        }

        &__rows {
          min-height: 86px;
          max-height: 240px;
          overflow-y: auto;
          max-width: 100%;
          padding: 0 0 4px 4px;
          margin-left: -2px;
        }

        &__row {
          position: relative;
          display: flex;
          align-items: flex-start;
          width: 100%;
          margin-top: 8px;

          @include up-to(520px) {
            flex-wrap: wrap;
            margin-bottom: 24px;
          }

          .xgs-select__menu-list {
            max-height: 150px !important;
          }
        }

        &__account {
          margin-right: 8px;
          width: 40%;
          flex-shrink: 0;

          @include up-to(520px) {
            width: calc(100% - 60px);
            margin-right: 12px;
            margin-bottom: 8px;
          }

          &__static {
            padding-right: 8px;

            @include up-to(520px) {
              padding-right: 0;
            }

            &__label {
              font-size: 14px;
              margin-bottom: 10px;
            }
          }
        }

        &__select {
          width: 60%;
          margin-right: 12px;

          @include up-to(520px) {
            width: calc(100% - 60px);
            margin-bottom: 0;
          }
        }

        &__remove {
          width: 48px;
          margin-top: 8px;
          color: $gray3;
          cursor: pointer;

          @include up-to(520px) {
            position: absolute;
            top: 0;
            right: 0;
          }

          &--first {
            margin-top: 32px;
          }
        }

        &__add-account {
          margin-top: 16px;
          margin-bottom: 24px;

          .xgs-btn {
            width: 206px !important;

            @include up-to(540px) {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
