@import "../../sass/variables.module.scss";

.announcement-tooltip {
  position: relative;

  &__popup-trigger {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 0px;
  }

  &__popup-content {    
    padding: 24px;
    background-color: $blue1;
    border-color: $blue1;
    width: 200px !important;
    border-width: 0;
    border-radius: 4px;
    box-shadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.20)",
  }

  &__popup-arrow {
    color: $blue1 !important;
    stroke: $blue1 !important;
  }

  &__content {
    color: white;
    margin-bottom: 24px;
    font-size: 14px;
  }
}