@import "./../../sass/variables.module.scss";

.xgs-checkbox {
  label {
    display: flex;
    align-items: center;
  }

  input {
    width: 20px;
    height: 20px;
    border: 1px solid rgba($gray8, 0.32);
    border-radius: $border-radius;
    flex-shrink: 0;
  }

  &__label {
    font-size: 14px;
    margin: 5px 0 5px 8px;
  }

  &--reverse {
    label {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .xgs-checkbox__label {      
      margin: 5px 8px 5px 0;
    }
  }
}