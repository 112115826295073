@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-site__content-container {
  padding: 8px $page-content-margin-sm 0 $page-content-margin;

  @include mobile {
    padding-right: $page-content-margin-sm;
    padding-left: $page-content-margin-sm;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  &__title-container {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__page-menu {
    @include mobile() {
      display: none;
    }

    @include tablet() {
      display: none;
    }
  }

  .xgs-loading {
    margin-left: $margin7;
  }

  .xgs-btn--spinner .xgs-loading {
    margin-left: 0;
  }

  &__component {
    display: flex;
    flex-direction: column;
    margin: 24px 0 0 0;

    @include mobile {
      margin: $margin7 0 0 0;
    }
  }

  &--full-width {
    padding-top: 8px;
    padding-right: 0;
    max-width: 100%;

    .xgs-site__content-container__component {
      margin-left: 0;
      margin-top: 24px;
    }

    .xgs-site__content-container__toolbar {
      padding-right: $page-content-margin;
      width: 100%;
      max-width: none;
      line-height: 24px;
      flex-wrap: wrap;
      gap: 24px;
    }
  }

  &--screen-height {
    height: calc(100vh - #{$header-height} - 41px);
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    overflow-y: hidden;

    .xgs-site__content-container__component {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: hidden;
    }

    .xgs-site__content-container__body {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: hidden;
    }
  }

  &--mobile-no-padding {
    @include mobile {
      padding-left: 0;
      padding-right: 0;

      .xgs-site__content-container__title {
        padding-left: $page-content-margin-sm;
      }
    }
  }
}