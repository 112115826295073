@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-subscriptions {
  margin-bottom: 24px;

  &__header {
    color: $gray2;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__loading {
    height: 28px;
  }

  &__remove-button {
    cursor: pointer;
    text-align: center;
    padding: 2px 8px !important;
    max-width: 38px;

    &:hover {
      color: $blue1;
    }
  }

  &__table {
    .tr {
      align-items: center;
    }

    .xgs-btn__spinner {
      margin-right: 0;
    }
  }

  &__no-data {
    margin: 0 16px;
    color: $gray3;
    font-size: 14px;
  }
}
