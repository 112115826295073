@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-shipments-documents {
  max-width: 900px;
  margin-bottom: 72px;

  &__manifest {
    width: 240px;
  }

  &__notes {
    font-size: 14px;
    margin-bottom: 16px;
  }

  &__limit-reached {
    margin-top: 8px;
    font-size: 14px;
    color: $red;
  }

  .xgs-textarea {
    max-width: 240px;

    @include up-to(500px) {
      max-width: none;
      width: 100%;
    }

    textarea {
      height: 205px;
    }
  }

  &__proceed-button {
    margin-top: 16px;

    @include up-to(500px) {
      width: 100%;
    }
  }

  &__start-over-button {
    margin-left: 16px;

    @include up-to(500px) {
      width: 100%;
      margin: 16px 0 0 0;
    }    
  }

  &__list-container {
    @include up-to(500px) {
      text-align: center;
    }
  }

  &__list {
    display: inline-block;
    margin: 32px 0 0 8px;
    font-size: 14px;

    &__scrollable-area {
      max-height: 240px;
      overflow-y: auto;
    }

    &__row {
      display: flex;
      margin-bottom: 8px;
      align-items: center;
    }

    &__header {
      color: $gray2;
      background-color: $gray6;
      padding: 8px;
      margin-left: -8px;
    }

    &__types {
      &__common {
        text-align: center;
        margin-bottom: 4px;
        margin-right: 60px;

        @include up-to(420px) {
          margin-right: 24px;
        }
      }

      &__parts {
        display: flex;
      }

      &__part {
        flex-shrink: 0;

        &--desktop {
          display: block;

          @include up-to(750px) {
            display: none;
          }
        }

        &--mobile {
          display: none;

          @include up-to(750px) {
            display: block;
          }          
        }
      }
    }

    &__probill {
      width: 90px;
      margin-right: 8px;
      line-height: 22px;
      flex-shrink: 0;

      @include up-to(750px) {
        width: 70px;
      }

      @include up-to(420px) {
        width: 60px;
      }

      &:not(.xgs-shipments-documents__list__probill--header) {
        @include up-to(420px) {
          font-size: 13px;
        }
      }

      &--select-all {
        color: $gray3;
      }
    }

    &__auth {
      width: 105px;
      margin-right: 8px;
      text-align: center;
      flex-shrink: 0;

      @include up-to(750px) {
        width: 40px;
      }

      @include up-to(420px) {
        margin-right: 4px;
      }

      @include up-to(360px) {
        width: 32px;
      }      
    }

    &__bol {
      width: 105px;
      margin-right: 8px;
      text-align: center;
      flex-shrink: 0;

      @include up-to(750px) {
        width: 40px;
      }

      @include up-to(420px) {
        margin-right: 4px;
      }

      @include up-to(360px) {
        width: 32px;
      }

      &--customer {
        @include up-to(750px) {
          width: 64px;
        }

        @include up-to(360px) {
          width: 54px;
        }
      }
    }

    &__misc {
      width: 110px;
      margin-right: 8px;
      text-align: center;
      flex-shrink: 0;

      @include up-to(750px) {
        width: 40px;
      }

      @include up-to(420px) {
        margin-right: 4px;
      }

      @include up-to(360px) {
        width: 32px;
      }      
    }

    &__pod {
      width: 130px;
      margin-right: 8px;
      text-align: center;
      flex-shrink: 0;

      @include up-to(750px) {
        width: 40px;
      }

      @include up-to(420px) {
        margin-right: 4px;
      }

      @include up-to(360px) {
        width: 32px;
      }

      &--customer {
        @include up-to(750px) {
          width: 64px;
        }

        @include up-to(360px) {
          width: 54px;
        }
      }
    }

    &__inv {
      width: 70px;
      margin-right: 8px;
      text-align: center;
      flex-shrink: 0;

      @include up-to(750px) {
        width: 40px;
      }

      @include up-to(420px) {
        margin-right: 4px;
      }

      @include up-to(360px) {
        width: 32px;
      }
    }

    &__actions {
      width: 60px;
      text-align: center;
      font-size: 18px;

      @include up-to(420px) {
        width: 24px;
      }

      @include up-to(360px) {
        width: 18px;
      }

      &__delete {
        color: $red;
        cursor: pointer;
      }
    }
  }

  &__select-all {
    &__checkbox {
      &:indeterminate {
        opacity: 0.5;
      }
    }
  }

  &__success {
    margin: 32px auto;
    text-align: center;
    line-height: 1.5;

    &__start-over-button {
      margin-top: 16px;

      @include up-to(420px) {
        width: 100%;
      }
    }
  }
}