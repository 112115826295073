@import "./variables.module.scss";

/* responsiveness */
@mixin up-to($width) {
  @media only screen and (max-width: $width - 1) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: $mobile-width + 1px) and (max-width: $tablet-width) {
    @content;
  }
}

@mixin responsiveTable($breakpoint) {
  .xgs-table {
    min-width: $breakpoint - $side-menu-width-desktop - $page-content-margin * 2 !important;

    &__wrapper {
      padding-right: $page-content-margin;
  
      @include up-to($breakpoint) {
        padding-right: 0px;
      }
  
      &--loading {
        min-width: unset;
        width: 100%;
      }
    }
  }
}
/* /responsiveness */
