@import "../../sass/mixins.scss";
@import "../../sass/variables.module.scss";

.xgs-success-block {
  text-align: center;

  &__content {
    margin: 64px auto;
    max-width: 520px;

    @include up-to(768px) {
      margin: 32px 16px;
      max-width: none;
    }
  }
}
