@import "../../../sass/variables.module.scss";
@import "../../../sass/mixins.scss";

$red: #E82A2B;
$green: #14870A;
$bgGreen: #14870A33;
$breakpoint: 1400px;

.xgs-picklists {
  padding-bottom: 24px;

  .xgs-header {
    font-size: 1.4rem;
    line-height: 0.9;
    margin: 0;
    padding: 0;
  }

  .xgs-list__summary {
    align-items: center;
    gap: 16px;
    margin-bottom: 25px;
    padding-right: 40px;
    
    @include mobile() {
      flex-direction: column;
    }
  }

  .xgs-list__table-wrapper {
    @include responsiveTable($table-breakpoint);

    .xgs-table {
      min-height: 420px;
    }

    .xgs-table__headers {
      overflow: visible;
    }

    .th {
      padding-left: 8px;
      padding-right: 8px;
    }

    .tr {
      padding: 0 8px;
    }

    .td {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 65px;
      padding: 8px;
    }

    .cell__driver span {
      margin-left: 4px;
    }

    .cell__picklist-identifiers {
      display: flex;
      flex-direction: column;

      .picklist-number {
        font-weight: 600;
        color: $blue1;
      }
    }

    .cell__last-loaded {
        display: flex;
        flex-direction: column;

        .--late-loading {
          color: $red;
        }
    }

    .cell__picklist-creation-details {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__username{
        text-transform: uppercase;
      }
    }

    .cell__progress {
      &-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 16px;
      }

      &-info {
        display: flex;
        justify-content: space-between;
        color: #777777;
      }

      &-bar {
        width: 100%;
        height: 4px;
        margin-top: 4px;
        border-radius: 4px;
        background-color: #2F80ED14;

        >div {
          height: 4px;
          border-radius: 4px;
          background-color: #2F80ED;
        }


        &.--completed {
          background-color: $bgGreen;

          >div {
            background-color: $green;
          }
        }
      }
    }
  }
}