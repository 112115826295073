@import "../../sass/variables.module.scss";
@import "../../sass/mixins.scss";

.xgs-teams {
  &__deleted-account {
    color: $gray3;
  }

  &__details {

    &__delete {
      margin-left: 10px;
      margin-top: -1px;
      min-width: 170px;

      &__small {
        display: none
      }
  
      @include up-to(600px) {
        min-width: 0px;
        width: 98px;
        &__small {
          display: block;
        }
        &__large {
          display: none;
        }
      }
    }

    &__header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: $margin6;
    }

    &__header {
      margin-bottom: $margin8;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      padding: 4px 6px;
      margin-left: -6px;
      word-wrap: break-word;
      width: calc(100% - 130px);

      &:hover {
        background-color: $gray5;
      }

      @include up-to(600px) {
        width: calc(100% - 110px);
      }

      &--full-width {
        width: calc(100% - 32px);
      }
    }

    &__table-wrapper {
      height: calc(100vh - 242px);

      @include mobile {
        height: calc(100vh - 264px);
      }

      @include up-to(642px) {
        height: calc(100vh - 308px);
      }
    }

    &__table {
      height: 100%;
    }

    &__add-button {
      position: absolute;
      right: $margin3;
      top: 92px;

      &__icon { margin-right: 6px }

      @include up-to(720px) {
        position: relative;
        top: auto;
        right: auto;
        margin-bottom: $margin6;
      }

      button {
        @include up-to(720px) {
          width: 100%;
        }
      }

      &--disabled {
        color: $gray4;
        cursor: auto;
      }
    }

    &__payor-account {
      color: $orange;
      font-size: 11px;
      margin: 0 6px 0 -13px;
    }

    .react-tabs__tab {
      &-list {
        display: flex;

        @include up-to(720px) {
          flex-direction: column;
        }
      }

      @include up-to(720px) {
        width: 100%;
        border: 1px solid #2F80ED;
        border-radius: 0;

        &:first-child { border-radius: 4px 4px 0 0 }
        &:last-child { border-radius: 0 0 4px 4px }
      }
    }

    .xgs-list__controls {
      margin-top: -16px;
      max-width: none;

      @include up-to(720px) {
        margin-top: 0px;
      }

      &__search__input {
        @include up-to(840px) {
          width: auto;
        }

        @include up-to(768px) {
          width: 300px;
        }

        @include up-to(680px) {
          width: 200px;
        }

        @include up-to(611px) {
          width: 100%;
        }
      }
      
      &__button {
        @include up-to(611px) {
          flex-grow: 1;
          margin-top: 8px;
        }

        @include up-to(500px) {
          width: 100%;
        }

        &:not(:last-child) {
          @include up-to(500px) {
            margin-right: 0px;
          }
        }
      }
    }
  }

  &__modal {
    &__header {
      font-size: 18px;
      line-height: 21px;
      color: $gray1;
      margin-bottom: 20px;
    }

    &__content {
      font-size: 16px;
      line-height: 19px;
      color: $gray2;
      margin-bottom: 37px;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;

      @include up-to(540px) {
        flex-wrap: wrap;
      }
    }

    &__button {
      width: 159px;

      &:not(:last-child) {
        margin-right: 40px;

        @include up-to(540px) {
          margin-right: 0;
        }
      }

      @include up-to(540px) {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }

  &__accounts {
    .xgs-teams__details__table-wrapper {
      height: calc(100vh - 272px);
    }    
  }

  &__pending-email {
    color: $gray3;
  }
}

.--team-update-modal {
  display: inline-block;
  width: 100%;
  text-align: center;
}
