.planning-tips-box {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    width: 60%;
    margin-top: 20px;
}

.planning-tip-icon {
    margin-right: 5px;
}

.planning-tip-type-attention {
    border: 1px solid #B89311;
    color: #B89311;
    background-color: #FAF6DE;
}