@import "../../../../../sass/mixins.scss";

.add-stops-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  z-index: 1000;
  overflow-y: auto;
}

.add-stops-modal {
  position: absolute;
  background-color: #FFF;
  padding: 15px;
  z-index: 1000;
  width: 90%;
  border-radius: .5em;
}

.add-stops-modal-header{
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.add-stops-modal-header-close{
  align-content: center;
  position: absolute;
  right: 0;
  margin-right: 20px;
  cursor: pointer;
}

.add-stops-modal-header-titile{
  margin-bottom: 5px;
}

.add-stops-error-msg{
  color: red;
}

.add-stops-modal-table{
  margin-bottom: 116px;
}