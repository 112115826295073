@import "./../../../sass/variables.module.scss";

.xgs-map-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  color: $gray3;  

  &__legend-trigger {    
    cursor: pointer;
  }
}