@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-company__shippers {
  &__controls.xgs-list__controls {
    margin-top: -8px;
  }

  &__add-button {
    width: 140px;

    @include up-to(875px) {
      margin-bottom: 16px;
    }

    @include up-to(450px) {
      width: 100%;
    }
  }  
}

.add-shipper-popup {
  &__buttons {
    margin-top: 24px;
  }
}