@import "./../../../../sass/variables.module.scss";
@import "./../../../../sass/mixins.scss";

.xgs-tracking-filter {
  max-width: 1108px;

  @include mobile {
    margin-top: -16px;
  }

  &__input {
    width: 100%;
  }

  &__controls {
    display: flex;
    flex-direction: row;
    max-width: 1124px;
    flex-wrap: wrap;

    @include up-to(1024px) {
      max-width: calc(100vw - 32px);
    }

    &__item {
      position: relative;
      width: 200px;
      flex-shrink: 0;
      margin-right: 20px;
      margin-bottom: 16px;

      @include up-to(920px) {
        width: calc(50% - 20px);
      }

      @include up-to(470px) {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }

      &--last {
        margin-right: 0;
        flex-grow: 1;

        @include up-to(1360px) {
          flex-grow: 0;
        }
      }

      &__switch {
        width: 220px;
        align-self: center;
        label {
          width: 150px !important;
        }
      }
    }

    &__double-item {
      display: flex;
      flex-shrink: 0;
      width: 420px;
      margin-right: 20px;
      margin-bottom: 16px;

      &__unit {
        width: 200px;

        &:not(:last-child) {
          margin-right: 20px;
        }

        @include up-to(680px) {
          width: calc(50% - 10px);
        }
      }

      @include up-to(920px) {
        width: calc(50% - 20px);
      }

      @include up-to(470px) {
        margin-right: 0;
        width: 100%;
      }
    }
  }

  &__search-level__additional {
    min-height: 72px;
    margin-top: -16px;
  }

  &__account-numbers {
    margin: 4px 0 5px 0;
    font-weight: bold;
    font-size: 14px;
  }

  &__all-filters-button {
    @include up-to(556px) {
      width: 100%;
      order: 1;
    }

    @include up-to(400px) {
      width: 100%;
    }

    .xgs-btn {
      @include up-to(556px) {
        width: calc(100% - 20px);
      }

      @include up-to(470px) {
        width: 100%;
      }
    }
  }

  &__all-filters {
    &__help {
      position: absolute;
      left: 416px;
      top: 24px;
      color: $gray3;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: $blue1;
      }

      @include up-to(460px) {
        left: auto;
        right: 24px;
      }

      @include up-to(400px) {
        position: relative;
        top: -8px;
        right: -8px;
        width: 100%;
        text-align: right;
      }
    }
    
    .xgs-tracking-filter__controls {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;

      &:first-child {
        margin: 16px auto 0 auto;
      }

      &__item {
        width: 100%;

        .xgs-tracking-filter__input {
          max-width: 320px;
        }
      }

      &__double-item {
        flex-wrap: wrap;
        width: 100%;

        &__unit {
          width: 100%;

          &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }

    .xgs-tracking-filter__buttons {
      width: 100%;
      max-width: 300px;
      margin: 24px auto 120px auto;

      .xgs-btn {
        width: calc(50% - 10px);
      }
    }

    .xgs-form__field__notes {
      margin: 4px 0 0 0;
    }
  }

  &__buttons {
    width: 440px;

    @include up-to(920px) {
      width: 50%;
    }

    @include up-to(660px) {
      width: 100%;
    }

    @include up-to(556px) {
      order: 2;
    }

    @include up-to(400px) {
      margin-bottom: 16px;
    }

    .xgs-btn {
      width: calc(50% - 20px);

      &:not(:last-child) {
        margin-right: 20px;
      }

      @include up-to(470px) {
        width: calc(50% - 10px);
      }

      @include up-to(400px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  &__bottom-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 24px 0;
    gap: 16px;
  }

  &__base-filters {
    display: flex;
    align-items: center;
    margin-right: 48px;
    padding: 8px 10px;
    color: $gray2;
    background-color: $gray7;
    border-radius: $border-radius;
    font-size: 14px;
    letter-spacing: 0.4px;
    word-break: break-word;
    max-width: 260px;

    @include up-to(990px) {
      & + .xgs-tracking-filter__all-filters-button {
        width: 100%;
      }
    }

    @include up-to(594px) {
      margin: 0 auto;
    }

    @include up-to(400px) {
      margin-top: -16px;
    }

    &__icon {
      color: $orange;
      margin-right: 8px;
      width: 20px !important;
      height: auto;
    }

    &__text {
      width: calc(100% - 28px);
    }
  }

  &__additional-group {
    display: inline-block;
    background-color: $gray7;
    padding: 8px 10px;
    border-radius: $border-radius;
    margin-bottom: 8px;
    font-size: 14px;
    word-break: break-word;

    @include up-to(450px) {
      text-align: center;
    }

    &__name {
      font-weight: bold;
      text-transform: uppercase;
    }

    &__link-desktop-part {
      @include up-to(356px) {
        display: none;
      }
    }
  }

  &__download-csv {
    position: absolute;
    top: 50px;
    left: 980px;

    @include up-to(1360px) {
      left: auto;
      right: 64px;
    }

    @include up-to(768px) {
      right: 80px;
    }

    @include up-to(400px) {
      position: relative;
      top: auto;
      right: auto;
      margin-bottom: 24px;
      text-align: center;
    }

    &__spinner {
      margin-left: -4px !important;
    }
  }

  &__account-types {
    display: flex;
    gap: 16px;
    font-size: 14px;
    margin-bottom: 16px;

    &__warning {
      font-size: 14px;
      color: $red;
      margin: -8px 0 16px 0;
      padding: 8px 11px;
      background-color: $gray6;
      border-radius: $border-radius;
      display: flex;
      align-items: center;

      &__icon {
        font-size: 22px;
        margin-right: 12px;
        color: $red;
      }

      &__text {
        font-size: 14px;
        color: $gray1;
      }
    }
  }

  .xgs-form__field__notes {
    display: none;
  }
}