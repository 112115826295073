@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-announcement {
  &__form {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;

    @include up-to(800px) {
      flex-wrap: wrap;
    }

    &__controls {
      max-width: 480px;
    }

    &__preview {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F2F2F2;

      &__area {
        min-width: 288px;
        max-width: 520px;
        background-color: white;
        max-height: 540px;
        overflow-y: auto;
        border-radius: 4px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
        padding: 24px;

        @include up-to(800px) {
          margin-top: 32px;
        }

        @include up-to(540px) {
          min-width: 0;
          max-width: 100%;
          max-height: 540px;
        }
      }

      &__header {
        width: 100%;
        min-width: 328px;
        font-size: 18px;
        font-weight: bold;
        line-height: 20px;
        word-wrap: break-word;

        @include up-to(540px) {
          width: 100%;
          min-width: 0;
        }
      }

      &__body {
        width: 100%;
        min-width: 328px;
        word-wrap: break-word;
        font-size: 16px;
        margin: 24px 0;
        color: $gray2;

        @include up-to(540px) {
          width: auto;
          min-width: 0;
        }

        a {
          color: #2F80ED;
          text-decoration: none;
        }

        &.ql-editor {
          padding: 0;
        }
      }

      &__buttons {
        display: flex;
        justify-content: center;
        gap: 16px;
        width: 100%;

        @include up-to(500px) {
          flex-wrap: wrap;


        }
      }
    }

    &__block {
      position: relative;
      border-radius: 4px;
      border: 1px solid $gray5;
      padding: 24px;
      width: 50%;

      @include up-to(800px) {
        width: 100%;
      }

      &__help {
        position: absolute;
        top: 12px;
        right: 12px;
      }

      &__status {
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }

    &__value-pair {
      display: flex;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 16px 0;

      &__label {
        color: $gray3;
      }

      &__value {
        color: black;
      }
    }

    &__section {
      position: relative;

      @include up-to(600px) {
        width: 100%;
        margin: 0 0 24px 0;
      }
    }

    &__field {
      margin-top: 0 !important;
      
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &--flex {
        display: flex;

        @include up-to(600px) {
          flex-wrap: wrap;
        }
      }

      &__text {
        background-color: #f7f7f7;
        color: #6D6D6D;
        min-height: 34px;
        border: 1px solid #bdbdbd;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 8px;
        font-size: 14px;
      }

      &__label {
        font-size: 14px;
        width: 170px;
        margin-right: 8px;
      }

      &__value {
        font-size: 14px;
      }
    }

    &__error {
      max-width: 100%;
      margin-top: 24px;

      @include up-to(600px) {
        width: 100%;
      }
    }

    &__notes {
      font-size: 12px;
      line-height: 16px;

      &--red {
        color: #E82A2B;
      }
    }

    &__buttons {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      &--main {
        margin-top: 48px;
      }
    }

    &__button {
      flex-grow: 1;

      &--save {
        width: 160px;
      }

      &--delete {
        width: 106px;
      }

      &--50 {
        max-width: 50%;

        @include up-to(600px) {
          max-width: none;
          width: 100%;
        }
      }

      @include up-to(600px) {
        width: 100%;
        margin-right: 0;
      }      
    }
  }
}
