@import "./../../../../sass/variables.module.scss";
@import "./../../../../sass/mixins.scss";

.xgs-chat {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  overflow-y: hidden;

  &__controls {
    display: flex;
    gap: 16px;
    padding-bottom: 2px;
    align-items: flex-end;

    @include mobile {
      padding-left: 24px;
      padding-right: 24px;
    }

    .xgs-growing-textarea {
      width: 100%;
    }

    .xgs-btn {
      width: 62px;
    }
  }

  &__messages {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
    border-radius: $border-radius;
    border: 1px solid $gray_32;
    background: $gray_8;
    overflow-y: auto;

    &> :first-child {
      margin-top: auto !important;
    }

    @include mobile {
      border-radius: 0;
      border-left: none;
      border-right: none;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__message {
    max-width: 440px;
    display: flex;
    gap: 8px;
    margin-top: 8px;

    &__text {
      background-color: white;
      padding: 10px 16px;
      border-radius: 2px;
      box-shadow: 0px 4px 8px 0px $gray_8;
    }

    &--incoming {
      align-self: flex-start;
    }

    &--incoming:first-child,
    &--outcoming+&--incoming {
      margin-top: 16px;

      .xgs-chat__message__text {
        border-top-right-radius: 8px;
      }

      .xgs-chat__message__user {
        display: flex;
        color: $blue1;
      }
    }

    &--incoming:last-child,
    &--incoming:has(+ .xgs-chat__message--outcoming) {
      .xgs-chat__message__text {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    &--outcoming {
      align-self: flex-end;
      flex-flow: row-reverse;
    }

    &--outcoming:first-child,
    &--incoming+&--outcoming {
      margin-top: 16px;

      .xgs-chat__message__text {
        border-top-left-radius: 8px;
      }

      .xgs-chat__message__user {
        display: flex;
        color: $gray_72;
      }
    }

    &--outcoming:last-child,
    &--outcoming:has(+ .xgs-chat__message--incoming) {
      .xgs-chat__message__text {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    &__user-wrapper {
      min-width: 32px;
      height: 32px;
      display: flex;
    }

    &__user {
      display: none;
      flex: 1;
      min-width: 32px;
      height: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: 0px 4px 8px 0px $gray_8;
      border-radius: 4px;
      font-size: 14px;
      font-family: "Roboto-Bold";
    }

    &__prompts {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    &__loader {
      position: relative;
      margin: 6px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: $blue1;
      color: $blue1;
      animation: dot-flashing 1s infinite linear alternate;
      animation-delay: 0.5s;

      &::before,
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
      }

      &::before {
        left: -9px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: $blue1;
        color: $blue1;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
      }

      &::after {
        left: 9px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: $blue1;
        color: $blue1;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
      }
    }
  }

  &__divider {
    flex: 0 0 auto;    
    height: 1px;
    background-color: $gray_32;
    margin: 24px 0 16px;
  }

  &--animated {
    .xgs-chat__message:last-child {
      transform: scale(0);
      animation: message 0.15s ease-out 0s forwards;
    }
  }
}

@keyframes dot-flashing {
  0% {
    background-color: $blue1;
  }

  50%,
  100% {
    background-color: rgba($blue1, 0.2);
  }
}

@keyframes message {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}