@import "./variables.module.scss";
@import "./mixins.scss";

$form-mobile: 510px;

.xgs-public-form {
  padding-bottom: 90px;

  @include up-to($form-mobile) {
    padding-left: 24px;
    padding-right: 24px;
  }

  &__title {
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 54px;
    text-align: center;
  }

  &__form {
    width: 420px;
    margin: 42px auto 0 auto;

    &--wide {
      width: 480px; 
    }

    @include up-to($form-mobile) {
      width: 100%;
    }
  
    &__section {
      margin-bottom: 54px;

      &__header {
        font-size: 16px;
        line-height: 18px;
        color: #828282;
        text-align: center;
      }
    }

    &__submit {
      text-align: center;

      .xgs-btn {
        min-width: 160px;
      }
    }
  }

  &__error {
    margin: $margin5 auto;
  }

  &__success {
    text-align: center;
    margin: 0 auto;
    max-width: 420px;
    line-height: 1.4;
    padding-bottom: $margin4;
    margin-top: 100px;
  }

  .xgs-site__box__link {
    margin-top: $margin4;
    text-align: center;
  }

  .xgs-labeled-input {
    @include up-to($form-mobile) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .xgs-labeled-input.row {
    input,
    & > div {
      @include up-to($form-mobile) {
        width: 100%;
      }
    }
  }

  .xgs-labeled-input.row .xgs-labeled-input__validation-error {
    @include up-to($form-mobile) {
      margin-left: 0;
    }
  }

  .xgs-btn {
    @include up-to($form-mobile) {
      width: 100%;
    }
  }
}

.xgs-item-form {
  box-sizing: border-box;
  max-width: 408px;
  width: 100%;
  height: 100%;
  border: 1px solid #CFD2DE;
  border-radius: $border-radius;
  padding: 16px;
  margin-top: 16px;

  &__row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    @include up-to(360px) {
      flex-wrap: wrap;
    }

    &--buttons {
      @include up-to(540px) {
        flex-wrap: wrap;
      }
    }
  }

  &__field {
    @include up-to(360px) {
      width: 100%;
    }

    &--full-width {
      width: 100%;
    }
  }

  &__button {
    @include up-to(540px) {
      width: 100%;
    }
  }

  &__guidelines-content {
    .xgs-popup__title {
      font-family: "Roboto-Bold";
      margin-bottom: 8px;
    }
  
    .xgs-popup__content {
      max-width: 430px;
      font-size: 14px;
  
      img {
        margin: 16px 0 24px;
      }
    }
  }

  &__radio-group {
    position: relative;
    width: 200px;
    flex-shrink: 0;
    margin-right: 20px;
    margin-bottom: 16px;

    @include up-to(920px) {
      width: calc(50% - 20px);
    }

    @include up-to(470px) {
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.xgs-visual-editor {
  label {
    font-size: 14px;
    line-height: 24px;

    span {
      color: $red;
    }
  }

  .ql-toolbar {
    background-color: $gray7;
    border-radius: 4px 4px 0 0;
  }

  .ql-container {
    background-color: white;
    border-radius: 0 0 4px 4px;
  }

  .ql-editor {
    height: 134px;
  }

  &.xgs-labeled-input-failed {
    .ql-toolbar {
      border-top-color: $red;
      border-right-color: $red;
      border-left-color: $red;
    }

    .ql-container {
      border-right-color: $red;
      border-bottom-color: $red;
      border-left-color: $red;
    }
  }
}
