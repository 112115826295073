@import "../../../../../sass/variables.module.scss";
@import "../../../../../sass/mixins.scss";


$green: #14870A;
$bgGreen: #14870A33;

.create-reasons {

  margin-top: 30px;

  .create-reasons-status{
    margin-top: 10px;
    text-align: center;
    
    &__loaded{

      &__icon{
        margin-right: 5px;
        color: $green1;
      }
    }

    &__failed{
    
      &__icon{
        margin-right: 5px;
        color: $xgs-red;
      }
      &__probills-list{
        text-align: left;
      }
    }
  }

  .create-reasons-porgress-bar {
    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 16px;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      color: #777777;
    }

    &__bar {
      width: 100%;
      height: 4px;
      margin-top: 4px;
      border-radius: 4px;
      background-color: #2F80ED14;

      >div {
        height: 4px;
        border-radius: 4px;
        background-color: #2F80ED;
      }

      &.--completed {
        background-color: $bgGreen;

        >div {
          background-color: $green;
        }
      }
    }
  }
}