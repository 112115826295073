@import "./../../../../sass/variables.module.scss";

.xgs-table-link-cell {
  text-decoration: none;
  color: $blue1;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}