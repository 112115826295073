@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-create-appt {
  &__audit {
    display: block;

    &__heading {
      font-weight: bold;
    }
    &__info {
      margin-top: -6px;
    }
  }
  &__details {
    font-size: 14px;
    line-height: 20px;

    &__probill {
      font-size: 18px;
      margin-bottom: 12px;
    }

    &__number {
      margin-bottom: $margin6;
      font-size: 14px;
      color: $gray4;
    }
  }

  &__wizard-container {
    padding: 24px;
    border: 1px solid $gray5;
    margin-bottom: 100px;

    @include up-to(640px) {
      padding: 16px;
    }
  }

  &__wizard {
    text-align: center;

    &__heading {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: $margin8;
    }

    &__check {
      margin: auto;
      width: 32px;
      height: 32px;
      background-color: #5DC75433;
      border-radius: 100%;
      display: flex;
      margin-bottom: $margin7;
      align-items: center;
      justify-content: center;

      &__tick {
        align-items: center;
        color: #14870A;
      }
    }
    &__desc {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: $margin7;
    }

    &__step-indicator {
      display: flex;
      justify-content: center;
      gap: 5px;
      align-items: center;
      margin: auto;
      margin-bottom: $margin7;
      &__dot {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #BDBDBD;

        &--completed {
          background-color: $blue1;
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  &__form {
    &__item {
      margin-bottom: $margin7;
    }

    &__time-block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      
      input {
        width: 100% !important;
      }

      &__seperator {
        width: 11px;
        height: 1.5px;
        background-color: blue;
        border: none;
        background-color: black;
        margin-top: 15px;
  
        @include up-to(550px) {
          display: none;
        }
      }
    }

    &__submit {
      display: grid;
      grid-template-columns: 1.5fr 3fr;
      gap: 12px;
      margin-top: 24px;
      button {
        width: 100%;
      }
    }
  }

  &__confirm-tile {
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid $gray5;
    border-radius: 5px;
    margin-bottom: $margin7;
    display: flex;
    flex-shrink: none;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;

    svg {
      margin-top: 3px;
    }
    div {
      display: flex;
      gap: 8px;
      align-items: flex-start;
    }

    @include up-to(640px) {
      padding: 10px;
    }
  }

  &__button {
    width: 100%;
  }

  &__single-submit-button {
    width: 100%;
    margin-top: 24px;
  }

  &__important {
    gap: 10px !important;

    svg {
      margin-left: 2px;
      color: $blue1;
      width: 14px;
      height: 14px;
    }
  }

  .xgs-contact-form {
    margin-bottom: 0;
  }
}