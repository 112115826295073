@import "../../../../sass/mixins.scss";

.route-summary-container {
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  gap: 24px;
  border-radius: 4px;
  background: var(--White, #FFF);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
  margin-bottom: 10px;
  transition: margin 0.5s ease-in-out;
  position: fixed;
  z-index: 1;
  bottom: 10px;
  width: 80%;
}

.route-summary-titile {
  color: var(--Gray, #828282);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.plan-probills-header-create-route-button {
  margin-top: 10px;
}

.create-route-error {
  color: var(--Red, #E82A2B);
}

.create-route-input-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.create-route-input {
  width: 40%;
}