@import "./../../../sass/variables.module.scss";

.xgs-aging {
  width: 100%;
  max-width: 786px;
  border: 1px solid $gray5;
  border-radius: $border-radius;
  margin-top: 16px;
  margin-bottom: 32px;

  .xgs-loading {
    margin-left: 15px;
  }

  &__header {
    border-bottom: 1px solid $gray5;
    height: 66px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    color: $gray2;

    .chart-icon {
      margin-right: 11px;
    }

    &__invoice-link {
      color: $gray3;
      text-decoration: none;
      font-size: 14px;
    }
  }

  &__content {
    padding: 25px;
  }

  &__actions {
    margin-top: 20px;
    .xgs-btn {
      min-width: 120px;
    }
  }
}
