
.xgs-map {
  width: 100%;
  height: 100%;

  /* old version */
  &__container {
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__marker {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  /* /old version */

  &__marker-tooltip {
    overflow-wrap: break-word;
  }

  @media (hover: hover) {
    .gm-style-iw button {
      display: none !important;
    }
  }  
}

