@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-delivery-records {
  &--driver {
    max-width: 900px;
    margin: 0 auto;
  }

  &__new-button {
    width: 180px;
    margin-bottom: 24px;

    @include up-to(450px) {
      width: 100%;
    }
  }

  &__list__filters {
    &__input {
      width: 120px;

      @include up-to(600px) {
        width: calc(50% - 8px);
        margin-bottom: 8px;

        &:nth-child(2) {
          margin-right: 0;
        }
      }
    }

    &__dates {
      max-width: 320px;

      @include up-to(600px) {
        width: 100%;
        max-width: none;
        margin-bottom: 16px;
      }
    }

    &__button {
      &--last {
        @include up-to(600px) {
          margin-top: 16px;
        }
      }

      @include up-to(600px) {
        width: 100%;
      }
    }
  }

  &__probill-data {
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__details {
    font-size: 14px;

    @include up-to(500px) {
      margin-top: 8px;
    }

    &__combo-edit {
      &__label {
        margin-top: 5px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      &__value {
        margin-top: 5px;
        min-width: 80px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      &__field {
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }

    &__probill-info {
      display: inline-block;
      width: auto;
      background-color: $gray7;
      padding: 4px 8px;
      margin: 8px 0 8px -8px;
      border-radius: $border-radius;
      min-width: 60%;
    }

    &__account-number {
      color: $gray4;
      font-weight: bold;
    }

    &__content {
      margin-bottom: 48px;
    }

    &__probill-field {
      min-width: 170px;

      @include up-to(500px) {
        min-width: 132px;
      }
    }

    &__delete {
      position: absolute;
      top: 8px;
      right: 26px;
      z-index: 2;
      font-size: 14px;
      color: $red;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 4px 6px;

      @include up-to(640px) {
        top: 58px;
        right: 16px;
      }
    }

    &__editable-textarea {
      width: calc(100% - 52px) !important;
      min-width: 200px;

      &__field {
        width: 100%;
      }

      .xgs-labeled-input__validation-error {
        margin-left: 0;
      }
    }

    &__editable {
      font-weight: 500;
      padding: 4px 6px;
      margin: -4px 0 0 -6px;
      word-wrap: break-word;

      &:hover {
        background-color: $gray5;
      }

      @include up-to(500px) {
        margin: -2px 0 0 -6px;
      }
    }

    .xgs-sidebar__details__rows {
      line-height: 1.3;
    }

    .xgs-sidebar__details__row {
      flex-wrap: nowrap;

      &:not(.xgs-sidebar__details__row--fixed) {
        @include up-to(500px) {
          flex-wrap: wrap;
        }
      }
    }

    .xgs-sidebar__details__label {
      width: 120px;

      &--fixed {
        @include up-to(500px) {
          width: 65px;
        }
      }
    }

    .xgs-sidebar__details__value {
      max-width: calc(100% - 200px);

      @include up-to(500px) {
        max-width: none;
      }

      &:not(.xgs-sidebar__details__value--fixed) {
        @include up-to(500px) {
          width: 100%;
        }
      }
    }

    &__row {
      &--extra-margin.xgs-sidebar__details__row {
        margin-bottom: 12px;
      }
    }

    &__add-photos-button {
      @include up-to(500px) {
        width: 100%;
      }
    }

    &__add-photos-modal {
      .xgs-delivery-records__form__photos {
        margin-top: 16px;
      }

      .xgs-delivery-records__form__photos__item__fields__textarea {
        width: 100%;
      }

      .xgs-delivery-records__form__upload__area {
        max-width: 582px;

        .xgs-delivery-records__form__upload__notes {
          width: 100%;
        }
      }

      .xgs-delivery-records__form__photos {
        max-height: calc(90vh - 320px);
        max-width: none;
        overflow-y: auto;
        padding-right: 16px;

        @include up-to(440px) {
          padding-right: 10px;
        }
      }
    }
  }

  &__form {
    &__field {
      max-width: 240px;
      margin-bottom: 16px;

      &--long {
        max-width: 540px;
      }
    }

    &__label {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 3px;      
    }

    &__upload {
      margin-bottom: 16px;

      &__header {
        font-size: 14px;
      }
    }

    &__photos {
      max-width: 540px;
      
      &__item {
        display: flex;
        margin-bottom: 16px;

        &--deleted {
          display: none;
        }

        &__image {
          position: relative;
          display: flex;
          width: 180px;
          height: 120px;
          overflow: hidden;
          justify-content: center;
          align-items: center;
          margin-right: 16px;

          img {
            display: block;
            max-width: 100%;
            height: auto;
            flex-grow: 0;
            flex-shrink: 0;
          }

          &__control {
            position: absolute;
            top: 4px;
            right: 4px;
            background-color: $gray4;
            padding: 0 4px;
            color: $red;
            cursor: pointer;
          }
        }

        &__fields {
          flex-grow: 1;

          &__textarea {
            height: 65px;
            min-height: 0 !important;
            resize: none;
          }

          .xgs-form__checkbox {
            margin-top: 8px;
            font-size: 14px;

            label {
              display: inline-flex;
            }

            input {
              margin-right: 8px;
            }
          }
        }
      }
    }

    &__buttons {
      margin: 24px 0 32px 0;

      .xgs-btn {
        @include up-to(450px) {
          width: 100%;
        }
      }
    }

    &__error {
      max-width: 400px;
    }
  }

  &__preview {
    position: relative;
    width: 100%;
    max-width: 544px !important;

    &--grid {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      @include up-to(500px) {
        justify-content: center;
      }

      .xgs-delivery-records__preview__switch {
        top: -40px;
      }
    }

    &--list {
      .xgs-delivery-records__preview__switch {
        top: -32px;
      }
    }

    &__switch {
      position: absolute;
      right: 0;
      display: flex;

      &__button {
        background-color: $gray6;
        border: 1px solid $gray5;
        border-radius: $border-radius;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        box-sizing: content-box;
        margin-left: 8px;
        cursor: pointer;
        color: $gray2;

        &:hover:not(.xgs-delivery-records__preview__switch__button--active) {
          color: $blue1;
          background-color: $gray7;
          border: 1px solid $gray6;
        }

        &--active {
          cursor: inherit;
          color: $gray4;
          background-color: $gray7;
          border: 1px solid $gray6;
        }
      }

      &__icon {
        font-size: 17px;
      }
    }
  }

  &__photo {
    width: 100%;
    max-width: 600px;
    margin-bottom: 16px;

    &__item {
      position: relative;
      display: flex;
      border-radius: $border-radius;
      margin-bottom: 8px;

      @include up-to(800px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      &:not(:nth-child(4n+4)) {
        margin-right: 8px;

        @include up-to(1114px) {
          margin-right: 0;
        }

        @include up-to(1024px) {
          margin-right: 8px;
        }

        @include up-to(604px) {
          margin-right: 0;
        }
      }

      &:not(:nth-child(3n+3)) {
        @include up-to(1114px) {
          margin-right: 8px;
        }

        @include up-to(440px) {
          margin-right: 0;
        }
      }

      &:not(:nth-child(even)) {
        @include up-to(604px) {
          margin-right: 8px;
        }

        @include up-to(440px) {
          margin-right: 8px;
        }
      }      

      &--list {
        align-items: flex-start;
        background-color: $gray7;
        width: 100%;
        padding: 4px;

        .xgs-delivery-records__photo__item__preview {
          margin-right: 16px;
          width: 180px;
          height: 140px;
        }
      }

      &--grid {
        .xgs-delivery-records__photo__item__preview {
          width: 129px;
          height: 129px;

          @include up-to(604px) {
            width: 160px;
            height: 160px;
          }

          @include up-to(560px) {
            width: 140px;
            height: 140px;
          }

          @include up-to(500px) {
            width: 120px;
            height: 120px;
          }

          @include up-to(440px) {
            width: 160px;
            height: 160px;
          }

          @include up-to(390px) {
            width: 124px;
            height: 124px;
          }
        }
      }

      &__preview {
        position: relative;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;        
        border: 1px solid $gray5;
        border-radius: $border-radius;
        background-color: white;

        &__image {
          display: block;
          max-width: 100%;
          height: auto;
          flex-grow: 0;
          flex-shrink: 0;
          cursor: pointer;
        }

        &__grid-internal-only {
          position: absolute;
          left: 0;
          top: 0;
          padding: 4px 6px;
          width: 16px !important;
          height: 16px !important;
          box-sizing: content-box;
          background-color: $gray7;
          border-bottom-right-radius: $border-radius;

          &__icon {
            color: $gray3;
          }
        }

        &__grid-comments {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
          background-color: $gray7;
          border-bottom-left-radius: $border-radius;
          padding: 4px 6px;
          width: 16px !important;
          height: 16px !important;
          box-sizing: content-box;

          &__icon {
            font-size: 16px;
            color: $orange;
          }
        }
      }

      &__meta {
        width: 100%;

        @include up-to(800px) {
          width: 100%;
          margin-top: 8px;
          padding: 4px;
        }

        &__delete {
          position: absolute;
          top: 6px;
          right: 8px;
          color: $gray3;
          cursor: pointer;

          &:hover {
            color: $blue1;
          }
        }

        &__row {
          margin-top: 8px;

          &--flex {
            display: flex;
            flex-wrap: wrap;
          }

          &__header {
            font-weight: bold;
            color: $gray2;
            margin-right: 4px;
          }

          .xgs-delivery-records__details__editable-textarea {
            max-width: 244px;
          }
        }
      }
    }
  }

  &__switch-checkbox {
    min-width: 200px;
  }
}
