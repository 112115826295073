@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

$mobile: 600px;

.xgs-pickup {
  display: flex;
  align-items: flex-start;

  @include up-to(768px) {
    flex-wrap: wrap;
    padding-bottom: 24px;
  }

  &__wizard {
    min-width: 406px;

    @include up-to(470px) {
      min-width: 0;
    }

    @include up-to($mobile) {
      width: 100%;
    }

    .xgs-gray-area {
      margin-left: 0;
      border: 1px solid $gray_24;
    }
  }

  &__popup {
    &__warning {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $gray0;
      line-height: 1.5;

      &__icon {
        font-size: 48px;
        margin-right: 24px;
        color: $red;
      }
    }
  }

  &__shipper {
    .xgs-gray-area {
      max-width: 406px;
    }

    .xgs-pickup__textarea + .xgs-gray-area {
      margin-top: 16px;
    }   

    &__address-block {
      display: flex;
      flex-wrap: wrap;
      gap: 4px 12px;      
      font-size: 14px;
      line-height: 20px;
      color: $gray3;      

      span {
        color: $gray0;
      }
    }
  }

  &__consignee {
    .xgs-gray-area {
      width: calc(100% + 32px);
    }

    .xgs-form__address-block {
      padding: 16px;
      margin: 0;

      .xgs-pickup__row {
        margin-bottom: 0;

        .xgs-labeled-input {
          margin-bottom: 0;
        }

        .xgs-labeled-input__validation-error--custom {
          margin-top: 2px;
        }
      }
    }

    .xgs-form__field__notes {
      max-width: 406px;
    }

    &__phone-field {
      width: 148px;
    }
  }

  &__step-header {
    display: inline-block;
    position: relative;
    font-size: 16px;
    line-height: 19px;
    color: $gray3;
    margin-bottom: $margin7;

    &__loader {
      position: absolute;
      right: -32px;
    }
  }  

  &__check-zip-loader {
    font-size: 14px;
    color: $gray3;
    margin-top: -24px;
    padding-bottom: 5px;
  }

  &__field {
    position: relative;
    margin-bottom: 16px;
    max-width: 406px;

    &--no-max-width {
      max-width: none;
    }

    &--state {
      flex-grow: 1;
    }

    &--zip {
      width: 126px;
    }
  }

  &__row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    max-width: 406px;

    @include up-to($mobile) {
      flex-wrap: wrap;
    }

    &--last {
      margin-bottom: 0;
    }

    &__item {
      flex-grow: 0;
      width: calc(50% - 8px);
    
      &--width_auto {
        width: auto;
      }
    
      &--width_flex {
        flex-grow: 1;
      }

      .xgs-labeled-input {
        width: 100%;
      }
    }
  }

  &__message-row {
    margin-bottom: 16px;
    max-width: 406px;

    &--top-16 {
      margin-top: -16px;
    }

    &--top-12 {
      margin-top: -12px;
    }
  }

  &__date {
    width: 170px;

    @include up-to($mobile) {
      width: 100%;
    }

    &__non-service-day:not(.react-datepicker__day--keyboard-selected):not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled) {
        color: rgba(79, 95, 114, 0.56);

      &:hover {
        border-radius: 4px;
        outline: 1px solid rgba(79, 95, 114, 0.32);
        background: rgba(79, 95, 114, 0.04);
      }
    }
  }

  &__time {
    width: 230px;

    @include up-to($mobile) {
      margin-top: 24px;
      width: 100%;
    }
  }

  &__textarea {
    width: 100%;

    textarea {
      resize: none;
      width: 100% !important;
      height: 100% !important;
    }

    .xgs-textarea {
      width: 100%;

      textarea {
        height: 76px !important;
        min-height: 32px;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 16px;
    margin: 40px 0;
    flex-wrap: wrap;

    .xgs-btn {
      padding: 6px 24px;
      width: auto;
      min-width: 140px;

      @include up-to(600px) {
        width: 100%;
      }
    }
  }

  &__mobile-phone-row {
    .xgs-checkbox {
      margin-top: 28px;
    }
  }

  &__success-step {
    .xgs-pickup__step-header {
      margin-bottom: 0;
    }
  }

  &__content-block {
    box-sizing: border-box;    
    width: 100%;
    height: 100%;
    border: 1px solid #cfd2de;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: $margin6;
  }

  &__details-item {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &--flex {
      display: flex;
    }

    & + & {
      margin-top: 16px;
    }
  }

  &__details-item-label {
    color: $gray3;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &__pickup-number {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-right: 10px; 
  }

  .xgs-form__checkbox {
    font-size: 14px;

    input {
      margin-right: 8px;
    }

    div {
      margin-top: 1px;
    }
  }

  .xgs-accordion {
    min-height: 0;
    max-width: 406px;
    margin-bottom: 16px;
  }

  .xgs-bol__return__availability__controls__delimiter {
    margin: 8px 4px;
  }

  .xgs-bol__return__availability__controls {
    align-items: flex-end;
    
    .xgs-labeled-input.column {
      flex-grow: 1;
    }
  }

  .xgs-bol__items__limitation {
    margin-top: 16px;
  }

  @include up-to($mobile) {
    &.xgs-bol__create-wizard {
      max-width: none;
    }

    .xgs-bol__consignee {
      max-width: none;
    }

    .xgs-bol__field {
      width: 100%;
      max-width: none;
    }

    .xgs-bol__fields-row {
      max-width: none;
    }

    .xgs-bol__item-form {
      max-width: none;
      border: none;
      margin-left: -24px;
      padding: 24px;
      width: calc(100% + 48px);
      background-color: $gray7;
    }

    .xgs-bol__field--gray-bg,
    .xgs-form__address-block {
      margin: 0;
      padding: 24px;
    }
  }
}