@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-public-shipment-details {
  width: calc(100% + 24px);
  min-height: calc(100vh - #{$header-height});
  padding-bottom: 16px;
  background-color: $gray7;
  margin: -24px;

  @include up-to(515px) {
    padding-top: 32px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 400px;
    height: calc(100vh - 186px);
    font-size: 24px;

    @include up-to(540px) {
      height: auto;
      min-height: calc(100vh - 186px);
    }

    @include up-to(515px) {
      height: auto;
      min-height: calc(100vh - 270px);
    }

    @media only screen and (max-height: 1000px) {
      height: auto;
    }
  }

  &__content {
    font-size: 16px;
    width: 640px;

    @include up-to(600px) {
      width: 100%;
    }
  }

  &__spinner {
    color: $gray3;
  }

  &__header {
    text-align: center;
    margin: 24px 0 48px 0;
    font-size: 32px;

    @include up-to(540px) {
      margin: 24px 0;
    }

    @include up-to(515px) {
      font-size: 24px;
      margin: 24px 16px;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    max-width: 100%;

    @include up-to(480px) {
      flex-wrap: wrap;
    }

    &__left {
      margin-right: 32px;

      @include up-to(500px) {
        margin-right: 16px;
      }

      @include up-to(480px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  &__property {
    display: flex;
    margin-bottom: 4px;

    &__label {
      width: 70px;
      text-align: right;
      margin-right: 8px;
      color: $gray3;
    }

    &__value {
      width: 140px;
      word-break: break-word;

      @include up-to(400px) {
        width: calc(100% - 78px);
      }

      &--eta-notes {
        width: 240px;

        @include up-to(600px) {
          width: calc(100% - 78px);
        }
      }
    }
  }

  &__status {
    font-size: 20px;
    text-align: center;
    margin-bottom: 24px;
    padding: 8px 16px;
    background-color: $gray6;
    border-radius: $border-radius;
    color: $gray2;

    &--terminal {
      color: #219653;
    }
  }

  &__map-link {
    text-align: center;
    margin: -16px 0 24px 0;
  }

  &__pod {
    margin-top: 24px;
  }

  &__progress {
    margin: 48px 0;

    @include up-to(768px) {
      margin: 32px 0 0 0;
      text-align: center;
    }

    .xgs-stepped-progress {
      @include up-to(768px) {
        display: inline-block;
      }
    }
  }

  &__locations {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 24px 0;

    @include up-to(540px) {
      flex-wrap: wrap;
    }

    &__arrow {
      &--right {
        margin: 72px 13px 0 8px;

        @include up-to(540px) {
          display: none;
        }
      }

      &--down {
        display: none;

        @include up-to(540px) {
          display: block;
        }
      }

      &__icon {
        color: $gray3;
      }
    }

    &__item {
      min-width: 240px;
      width: calc(50% - 33px);
      margin: 0 8px;

      @include up-to(540px) {
        width: 100%;
        margin: 16px 24px;
      }

      &__box {
        border-radius: $border-radius;
        background-color: $gray6;
        padding: 16px;
        font-size: 16px;
        text-align: center;
      }

      &__type {
        margin-bottom: 4px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: $gray3;
      }

      &__name {
        margin-bottom: 8px;
      }

      &__address {
        margin-bottom: 8px;
      }

      &__phone {
        color: $gray2;

        &__icon {
          margin-right: 8px;
        }
      }

      &__no {
        min-height: 92px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray3;
      }
    }
  }

  &__login-link {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 24px;
  }

  &__footer {
    color: $gray2;
    margin: 64px 0 16px 0;
    text-align: center;

    &__text {
      display: inline-block;

      @include up-to(515px) {
        display: block;
        margin-bottom: 16px;
        line-height: 1;
      }
    }

    &__delimiter {
      display: inline-block;
      color: $gray4;
      margin: 0 8px;

      @include up-to(515px) {
        display: none;
      }
    }
  }

  &__error {
    margin: 0 auto;
    max-width: 400px;
    line-height: 1.5;
    font-size: 18px;

    &__header {
      font-size: 18px;
      color: $red;
    }

    &__text {
      font-size: 14px;
      margin-top: 16px;
    }
  }

  .xgs-form__field__notes {
    margin-top: 8px;

    ul {
      padding: 0 0 0 16px;
      margin: 0;
    }

    li + li {
      margin-top: 4px;
    }
  }
}
