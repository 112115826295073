@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-transit-calc {
  margin-bottom: 48px;

  &__columns {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  &__form {
    width: 230px;
    margin-right: 72px;
    flex-shrink: 0;

    @include up-to(450px) {
      width: 100%;
      margin-bottom: 32px;
      margin-right: 0;
    }

    &__error {
      margin-top: 24px;
    }

    &__button {
      margin-top: 32px;
      width: 100%;
    }

    .xgs-labeled-input {
      margin-top: 16px;
    }

    .xgs-btn {
      padding: 6px 24px;
      width: 230px;

      @include up-to(450px) {
        width: 100%;
      }
    }
  }

  &__results {
    width: 418px;
    color: $gray2;

    @include up-to(454px) {
      width: 100%;
    }

    strong {
      color: $gray1;
    }

    &__error {
      padding: 8px 16px;
      margin: 24px 0 16px 0;
      color: $red;
      background-color: $gray6;
      border: 1px solid $gray5;
      border-radius: $border-radius;
      line-height: 1.5;
      width: 410px;

      @include up-to(454px) {
        width: 100%;
      }
    }

    &__eta {
      margin-top: 24px;

      &__value {
        font-size: 24px;
        font-weight: 600;
        margin: 0 0 16px 16px;
        color: $gray0;
      }

      &__additional {
        font-size: 14px;
        margin-bottom: 16px;
        max-width: 400px;

        @include up-to(450px) {
          width: 100%;
        }

        ul {
          padding: 0 0 0 16px;
        }

        li:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &__customer-service {
      margin: 24px 0 0 16px;
      font-size: 14px;
    }

    &__center {
      font-size: 14px;
      line-height: 1.2;
      padding: 16px;
      background-color: $gray6;
      border-radius: $border-radius;
      width: 100%;
      margin-bottom: 16px;

      &__header {
        font-weight: bold;
        margin-bottom: 16px;
      }

      &__columns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
      }

      &__contacts {
        flex-grow: 0;
        max-width: 185px;        
        padding-right: 16px;
        word-break: break-word;

        @include up-to(450px) {
          max-width: none;
        }
      }

      &__phone {
        margin-top: 16px;
      }

      &__days {
        flex-shrink: 0;

        @include up-to(450px) {
          margin-top: 16px;
          width: 100%;
        }

        .flexbox {
          flex-wrap: wrap;
        }

        &__label {
          margin-bottom: 4px;
        }
      }

      &__day {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 18px;
        text-align: center;
        border-radius: $border-radius;
        border: 1px solid $gray4;
        color: $gray4;
        font-size: 12px;

        &:not(:last-child) {
          margin-right: 4px;
        }

        &--open {
          border-color: $green1;
          color: $green1;
        }
      }
    }

    &__date-range {
      display: flex;
      margin-bottom: 16px;

      & > div {
        @include up-to(470px) {
          margin: 0 auto;
        }
      }

      .react-datepicker {
        height: 100%;
      }

      .react-datepicker__month-container {
        @include up-to(470px) {
          float: none;
        }
      }

      .react-datepicker__navigation {
        display: none;
      }

      .react-datepicker__day--range-start {
        background-color: $blue1;
        color: white;
      }

      .react-datepicker__day--range-end {
        background-color: $green1;
        color: white; 
      }

      .react-datepicker__day--in-range:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
        background-color: $gray4;
        color: white;
      }

      .react-datepicker__day--outside-month {
        visibility: hidden;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 1.5rem;
        line-height: 1.5rem;        
        margin: 0.12rem;
      }

      .react-datepicker__day,
      .react-datepicker__month-text,
      .react-datepicker__quarter-text,
      .react-datepicker__year-text {
        cursor: auto;
      }

      .react-datepicker__day:not(.react-datepicker__day--in-range):hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__quarter-text:hover,
      .react-datepicker__year-text:hover {
        background-color: inherit;
      }
    }
  }
}