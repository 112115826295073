.image-thumbnail {
  width: 48px;
  height: 64px;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 4px;
  color: white;
  cursor: pointer;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.24);
  }

  img {
    height: 100%;
    width: auto;
  }
}