@import "./../../sass/variables.module.scss";

.xgs-input {
  position: relative;

  &--w-prepend-text {
    display: flex;
    align-items: center;

    .xgs-input__field {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__prepend-text {
    padding: 0 4px 0 6px;
    white-space: nowrap;
    font-size: 14px;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    outline: 1px solid rgba($gray8, 0.32);
    background-color: $gray5;
    line-height: 32px;
    height: calc(100% - 2px);
  }

  &__field {
    width: 100%;

    height: 32px;
    border: 1px solid rgba($gray8, 0.32);
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 8px;

    font-size: 14px;

    outline: none;
    transition: all 100ms;

    &:focus {
      box-shadow: 0 0 0 2pt $supporting_blue_40;
    }

    &:disabled {
      background-color: rgba($gray8, 0.04);
      color: rgba($gray8, 0.32);
      font-family: "Roboto-Regular";
    }

    &--w-icon {
      padding-right: 32px;
    }

    &__spinner {
      position: absolute;
      right: 6px;
      top: 6px;
    }
  }

  &__append-button {
    position: absolute;
    bottom: 10px;
    right: 9px;
    color: $gray_72;
    font-size: 14px;
    cursor: pointer;

  }

  &__append-custom-icon {
    position: absolute;
    bottom: 9px;
    right: 8px;

    img {
      display: block;
    }
  }

  &__password-eye-icon {
    right: 10px;
  }

  input {
    &::placeholder {
      color: $placeholder-color;
      opacity: 1; /* Firefox */
    }
    
    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: $placeholder-color;
    }
  }
}
