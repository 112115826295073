@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-user-menu {
  &__trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-right: $margin6;
  }

  &__user {
    position: relative;
    margin-right: $margin6;
    max-width: 180px;
    max-height: $header-height;
    white-space: nowrap;

    &--normal {
      display: block;

      @include mobile {
        display: none;
      }
    }

    &--short {      
      display: none;
      
      @include mobile {
        display: block;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 8px; 
    } 

    &__info-item {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 160px;
      
      @include tablet {
        max-width: 130px;       
      }
    }

    &__team {
      margin-top: 4px;
      color: $gray4;
    }

    @include tablet {
      margin-right: $margin7;
      max-width: 160px;
      font-size: 14px;
    }

    @include mobile {
      margin-right: $margin8;
      max-width: 40px;
      font-size: 16px;
    }
  }

  &__arrow {
    color: $gray1;

    svg {
      font-size: 14px;

      @include tablet {
        font-size: 12px;
      }

      @include mobile {
        font-size: 11px;
      }
    }

    @include tablet {
      padding-bottom: 4px;
    }

    @include mobile {
      padding-bottom: 2px;
    }    
  }
}

#popup-root {
  .xgs-dropdown-menu-content {
    padding: 4px 0 !important;
    min-width: 138px !important;

    a {
      text-decoration: none;
      outline: none;
    }
  }

  .xgs-dropdown-menu__item {
    font-family: "Roboto-Regular";
    font-size: 14px;
    line-height: 20px;
    height: 32px;
    position: relative;
    outline: none;

    &:only-child:after,
    &:not(:only-child):not(:last-child):after {
      content: "";
      height: 1px;
      background-color: #F2F2F2;
      position: absolute;
      bottom: 0;
      right: 8px;
      left: 8px;
    }

    &:focus,
    &:fodus-visible {
      outline: none;
    }
  }

  .xgs-dropdown-menu__icon {
    color: $gray3;
  }
}

.xgs-userpick {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background-color: $gray_16;
  color: $gray8;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Roboto-Bold";
  line-height: 20px;
  flex: 0 0 auto;

  &--impersonated {
    color: $purple1;    
    background-color: $purple_16;
  }
}