@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.shipment-chat {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;

  &__container-toolbar {
    gap: 0 40px;
    margin-bottom: 0;

    .xgs-site__content-container__title-container {
      flex-shrink: 0;
    }
  }

  &__chat, &__container-toolbar.xgs-site__content-container__toolbar {
    max-width: 720px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 720px;
  }

  &__row-item {
    margin-bottom: 24px;
    padding: 2px 2px 0;

    @include mobile {
      padding: 2px 24px;
    }
  }

  &__form {
    display: flex;
    gap: 16px;

    .xgs-btn {
      width: 99px;
    }

    .xgs-labeled-input {
      flex: 1;
      width: 227px;
    }

    .xgs-input__field {
      box-shadow: none;
    }
  }

  &__desktop-element {
    display: block;

    @include up-to(650px) {
      display: none;
    }
  }

  &__mobile-element {
    display: none;

    @include up-to(650px) {
      display: block;
    }
  }
}