

@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-appointment-summary {
  &__appointment-cell {
    display: flex;
    align-items: center;
    line-height: 20px;
    gap: 8px;

    &__check {
      width: 22px;
      height: 22px;
      background-color: #5DC75433;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &__tick {
        align-items: center;
        color: #14870A;
      }
    }

    &--caution {
      svg {
        font-size: 17px;
      }
    }
  }

  .th {
    padding-left: 16px;
  }

  .td {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 0px 16px;

  }

  .xgs-table__spinner-row {
    .td {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}