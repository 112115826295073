@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.manifest-details__stop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8px;

    &--line {
      flex: 1;
      display: inline-block;
      height: 1px;
      background-color: #E0E0E0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    margin: 12px 0 24px;
    padding: 0;

    .manifest-details__stop__collapsible {
      border-left: 5px solid #BDBDBD;

      &--status {
        &_complete {
          border-left-color: #14870A66
        }

        &_in-progress {
          border-left-color: #E8D15A33
        }

        &_has-exceptions {
          border-color: #E82A2B66
        }
      }
    }
  }

  &__address {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    text-transform: uppercase;
    color: #828282;
    margin: 0;

    >b {
      color: #222222
    }

    @include mobile() {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }
  }

  &__status {
    svg {
      margin: 0px 0px 1px 4px
    }    
  }

  &__card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--status {
      &--success {
        border-left-color: #14870A66;
      }

      &--failed {
        border-left-color: #E82A2B66;
      }
    }
  }

  &__card-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    @include mobile() {
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }

    &--vertical {
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }
  }

  &__card-name {
    flex: 1;
    display: inline-block;
    margin: 0;
    color: #000000;
    font-weight: 400;
    text-transform: uppercase;
  }

  &__card-info {
    flex-basis: 55%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    &--for_probill {
      flex-basis: 100%;
      width: 100%;
      display: inline-flex;
      align-items: center;
      grid-gap: 24px;
      gap: 24px;
    }
  }

  &__card-info-item {
    padding: 4px 8px;
    border-radius: 4px;
    color: #828282;

    >b {
      color: #000000
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--has-exceptions,
    &--has-exceptions b {
      color: #E82A2B;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__list-item {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    gap: 24px;
    padding-top: 16px;
    border-top: 1px solid #E0E0E0;

    @include up-to(420px) {
      flex-wrap: wrap;
      gap: 8px;
    }

    >span {
      color: #828282;

      >b {
        color: #000000
      }
    }
  }
}

.manifest-details__label {
  padding-right: 8px;
  font-size: 14px;
  text-transform: capitalize;
  color: #777777;
}